import React, { useEffect, useState } from "react"
import '../Styles/ViewMachine.css'
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faTools, faRedoAlt, faShareAlt, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import axios from '../Functionalities/axios'
import { useStateValue } from "../Functionalities/StateProvider";
import { useHistory } from "react-router";
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Modal from 'react-modal'

function ViewMachine() {

    const [tickets, setTickets] = useState([])
    const [state, dispatch] = useStateValue()
    const history = useHistory()
    const [deleteMachine, setDeleteMachine] = useState(false)
    const [filterBy, setFilterBy] = useState(null)
    const [status, setStatus] = useState(null)
    const [priority, setPriority] = useState(null)
    const [ticketType, setTicketType] = useState(null)
    const [dateTime, setDateTime] = useState(null)
    const [search, setSearch] = useState(null)
    const [editMachine, setEditMachine] = useState(null)
    const [page, setPage] = useState(1) // For Pagination
    const [previousPageShow, setPreviousPageShow] = useState(false) // For showing Previous Page Button
    const [nextPageShow, setNextPageShow] = useState(false) // For showing Next Page Button
    const limit = 10 // For Pagination
    // Getting previously selected Machine for Data Persistent
    const selectedMachineDetails = JSON.parse(localStorage.getItem('single-machine'))
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')
    // Getting account of employees selected from employee list
    const [isChecked, setIsChecked] = useState([])
    useEffect(() => {
        setPreviousPageShow(false)
        setNextPageShow(false)
    }, [page])

    // Sort Type List
    // Sorting Type List
    const sortList = [
        {
            value: 1,
            label: 'All',
        },

        {
            value: 2,
            label: 'Requested By',
        },
        {
            value: 3,
            label: 'Assignee',
        },

    ]

    // Status List
    const statusList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Open',
            label: 'Open'
        },
        {
            value: 'In-Progress',
            label: 'In-Progress'
        },
        {
            value: 'Completed',
            label: 'Completed'
        }
    ]

    // Priority List
    const priorityList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Low',
            label: 'Low'
        },
        {
            value: 'Medium',
            label: 'Medium'
        },
        {
            value: 'High',
            label: 'High'
        }
    ]

    // Ticket Type List
    const ticketTypeList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Repair',
            label: 'Repair'
        },
        {
            value: 'Replace',
            label: 'Replace'
        },
        {
            value: 'General',
            label: 'General'
        }
    ]

    // Timeline List
    const timelineList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Today',
            label: 'Today'
        },
        {
            value: 'This Week',
            label: 'This Week'
        },
        {
            value: 'This Month',
            label: 'This Month'
        },
        {
            value: 'Last 3 Months',
            label: 'Last 3 Months'
        },
        {
            value: 'This Year',
            label: 'This Year'
        }
    ]

    const deleteMachines = async (id) => {
        console.log('id', id)
        await axios
            .delete(`machines/${id}`)
            .then(data => {
                console.log('data of delete machine', data)
                //    alert("Employee Deleted Successfully")
                history.goBack();

            }).catch(error => {
                alert(error)
            })

    }
    // Fetching Data
    useEffect(() => {
        setEditMachine(selectedMachineDetails)
        axios
            .get(`tickets/machine/${selectedMachineDetails.machineID}?limit=${limit}&page=${page}`)
            .then(response => {
                console.log('response of tickets machine', response)
                console.log('response of tickets machine', response.data.tickets)
                setTickets(response.data)
                if (response.data.next) setNextPageShow(true)
                if (response.data.previous) setPreviousPageShow(true)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }, [])
    // search function 
    const searchFunc = async () => {
        let haveFilterBy = filterBy;
        let haveStatus = status;
        let havePriority = priority;
        let haveTicketType = ticketType;
        let haveDateTime = dateTime;

        if (filterBy) {
            console.log('if', filterBy)
            if (filterBy.label === 'All') {
                haveFilterBy = null
            }
            else {

                haveFilterBy = filterBy.label
            }
        }
        if (status) {
            console.log('if', status)
            if (status.label === 'All') {
                haveStatus = null
            }
            else {

                haveStatus = status.label
            }
        }
        if (priority) {
            console.log('if', priority)
            if (priority.label === 'All') {
                havePriority = null
            }
            else {

                havePriority = priority.label
            }

        }
        if (ticketType) {
            console.log('if', ticketType)
            if (ticketType.label === 'All') {
                haveTicketType = null
            }
            else {

                haveTicketType = ticketType.label
            }
        }
        if (dateTime) {
            console.log('if', dateTime)
            if (dateTime.label === 'All') {
                haveDateTime = null
            }
            else {

                haveDateTime = dateTime.label
            }
        }
        console.log('search inpunt', search,
            'filterby', haveFilterBy, 'status', haveStatus,
            "havePriority", havePriority, "haveTicketType",
            haveTicketType, "haveDateTime", haveDateTime)

        await axios
            .get(`tickets/location/${selectedLocationDetails[0]}?page=${page}&limit=${limit}&employee_name=${search}&employee_type=${haveFilterBy}&status=${haveStatus}&priority=${havePriority}&ticket_type=${haveTicketType}&date_filter=${haveDateTime}`)
            .then(response => {
                console.log('data.get all ticxket search', response)
                setTickets(response.data)
                if (response.data.next) setNextPageShow(true)
                if (response.data.previous) setPreviousPageShow(true)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }

    return (
        <div className='viewMachine'>

            {/* Header */}
            <DashboardHeader />
            <div className="viewMachine__mainBody">
{console.log('machine detail =>',selectedMachineDetails)}
                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />
                <div className="viewMachine__container">

                    {/* Heading */}
                    <h1>machine info</h1>
                    <div className="viewMachine__buttons">
                        {state.employee.isAdmin && <div className="viewMachine__leftBtns">
                            <button
                                onClick={() => {
                                    console.log(editMachine, 'editMachine')
                                    history.push({
                                        pathname: '/updateMachine',
                                        state: { data: editMachine }
                                    })
                                }}
                            >
                                <FontAwesomeIcon className='viewMachine__icon' icon={faEdit} style={{ color: '#c31a1a' }} />
                                edit
                            </button>
                            <button onClick={() => {
                                setDeleteMachine(true)
                            }}>
                                <FontAwesomeIcon className='viewMachine__icon' icon={faTrashAlt} />
                                delete
                            </button>
                            {/* modal start here  */}
                            <Modal
                                className='delete__modal'
                                isOpen={deleteMachine}
                                closeTimeoutMS={20}
                                shouldCloseOnOverlayClick={true}
                            >
                                <div style={{ width: '100%' }}>
                                    <h4>do you want to delete this Machine?</h4>
                                    <div className="row rowWidth">
                                        <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={() => setDeleteMachine(false)}>Cancel</button></div>
                                        <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={() => {
                                            deleteMachines(selectedMachineDetails.machineID);
                                        }}>Delete</button></div>

                                    </div>
                                </div>

                            </Modal>
                        </div>}

                    </div>
                    {/* 5 boxes of Tickets Statistic */}
                    <div className="viewMachine__boxes">
                        <div className="row rowWidth">
                            <div className="col-md-3 col-sm-6 col-lg-2">
                                <div className="viewMachine__box1 viewMachine__box">
                                    <div className="viewMachine__infoDetails">
                                        <span>machine</span>
                                        <span>{selectedMachineDetails.machineName}</span>
                                    </div>
                                    <div className="viewMachine__infoDetails">
                                        <span>machine id</span>
                                        <span>{selectedMachineDetails.machineID}</span>
                                    </div>
                                    <div className="viewMachine__infoDetails">
                                        <span>location</span>
                                        <span>{selectedMachineDetails.locationName}</span>
                                    </div>
                                </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"> <div className="viewMachine__box2 viewMachine__box">
                                <div className="viewMachine__infoHeading">
                                    <h3>
                                        total tickets
                                    </h3>
                                    <h2>{selectedMachineDetails.totalTicketsCount}</h2>
                                </div>
                                <div className="viewMachine__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2">  <div className="viewMachine__box3 viewMachine__box">
                                <div className="viewMachine__infoHeading">
                                    <h3>
                                        open tickets
                                    </h3>
                                    <h2>{selectedMachineDetails.openTicketsCount}</h2>
                                </div>
                                <div className="viewMachine__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"><div className="viewMachine__box4 viewMachine__box">
                                <div className="viewMachine__infoHeading">
                                    <h3>
                                        total schedules
                                    </h3>
                                    <h2>{selectedMachineDetails.totalSchedulesCount}</h2>
                                </div>
                                <div className="viewMachine__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"><div className="viewMachine__box5 viewMachine__box">
                                <div className="viewMachine__infoHeading">
                                    <h3>
                                        open schedules
                                    </h3>
                                    <h2>{selectedMachineDetails.openSchedulesCount}</h2>
                                </div>
                                <div className="viewMachine__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>

                        </div>

                    </div>

                    {/* Heading */}
                    <h1>All Tickets</h1>

                    {/* Input Fields */}
                    <div className="viewMachine__inputFields">
                        <div className="row rowWidth">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <input className="marginTopCol"
                                    // size={35}
                                    style={{ width: '100%' }}
                                    className="heightOfInputBox"
                                    type="text"
                                    placeholder='Search'
                                    onChange={e => {
                                        setSearch(e.target.value)
                                    }
                                    }

                                />
                                <Select
                                    options={sortList}
                                    placeholder='Filter By'
                                    className='allTicket__select marginTopCol sortMarginTopAllTicket'
                                    onChange={e => {
                                        setFilterBy(e)
                                    }}

                                />
                            </div>
                            {/* <div className="col-md-1 col-sm-12 col-xs-12"> </div> */}
                            <div className="col-md-2 col-sm-12 col-xs-12"> <Select
                                options={statusList}
                                placeholder='Status'
                                className='allTicket__select marginTopCol'
                                onChange={e => {
                                    setStatus(e)
                                }}

                            /></div>
                            <div className="col-md-1 col-sm-12 col-xs-12">


                                <Select
                                    options={priorityList}
                                    onChange={e => {
                                        setPriority(e)
                                    }}
                                    placeholder='Priority'
                                    className='allTicket__select marginTopCol'
                                /></div>
                            <div className="col-md-2 col-sm-12 col-xs-12"> <Select
                                className="marginTopCol"
                                options={ticketTypeList}
                                placeholder='Ticket Type'
                                onChange={e => {
                                    setTicketType(e)
                                }}
                                className='allTicket__select'
                            /></div>
                            <div className="col-md-2 col-sm-12 col-xs-12">

                                <Select
                                    options={timelineList}
                                    placeholder='Date'
                                    className='allTicket__select marginTopCol'
                                    styles={{ marginBottom: 5 }}
                                    onChange={e => {
                                        setDateTime(e)
                                    }}

                                /></div>
                            <div className="col-md-1 col-sm-12 col-xs-12">

                                <button className="marginTopCol" style={{ borderRadius: 5 }} className="heightOfInputBox" onClick={() => searchFunc()}>Search</button></div>
                        </div>


                    </div>

                    {/* Buttons */}
                    <div className="viewMachine__buttons">
                        {state.employee.isAdmin && <div className="viewMachine__leftBtns">
                            <button>
                                <FontAwesomeIcon className='viewMachine__icon' icon={faEdit} style={{ color: '#c31a1a' }} />
                                edit
                            </button>
                            <button>
                                <FontAwesomeIcon className='viewMachine__icon' icon={faTrashAlt} />
                                delete
                            </button>
                        </div>}
                        <div className="viewMachine__rightBtns">
                            <button>export</button>
                        </div>
                    </div>

                    {/* Table */}
                    <div className='viewMachine__table'>

                        {/* Table Heading */}
                        <div className="viewMachine__tableHeading">
                            <span>
                                <input
                                    className="heightOfInputBox"
                                    type="checkbox"
                                    onChange={e => {
                                        setIsChecked(previousValue => {
                                            previousValue = new Array(tickets.length)
                                            previousValue.fill(e.target.checked)
                                            return previousValue
                                        })
                                    }}
                                />
                            </span>
                            <span>T</span>
                            <span>Ticket ID</span>
                            <span>Summary</span>
                            <span>Requested By</span>
                            <span>Assignee</span>
                            <span>Location</span>
                            <span>Status</span>
                            <span>Priority</span>
                            <span>Created</span>
                        </div>

                        {/* Table Data */}
                        {
                            tickets.tickets?.map((ticket, index) => (
                                <div
                                    key={ticket._id}
                                    className={`viewMachine__tableData ${isChecked[index] && 'selected'}`}
                                >
                                    <span>
                                        <input
                                            type="checkbox"
                                            className="heightOfInputBox"
                                            onChange={e => {
                                                setIsChecked(isChecked => {
                                                    let c = [...isChecked]
                                                    c[index] = e.target.checked
                                                    return c
                                                })
                                            }}
                                            checked={isChecked[index]}
                                        />
                                    </span>
                                    <FontAwesomeIcon
                                        icon={ticket.issueType.toLowerCase() === 'repair' ? faTools : ticket.issueType.toLowerCase() === 'replace' ? faRedoAlt : faShareAlt}
                                        className={`viewMachine__icon ${ticket.issueType.toLowerCase()}`}
                                    />
                                    <span>{ticket.ticketID}</span>
                                    <span
                                        onClick={e => {
                                            e.preventDefault()

                                            dispatch({
                                                type: 'SINGLE_TICKET',
                                                ticket: ticket
                                            })

                                            history.push('/viewTicket')
                                        }}
                                    >
                                        {ticket.faultDefinition}
                                    </span>
                                    <span>{ticket.requestedBy.employeeName}</span>
                                    <span>{ticket.assignTo.name}</span>
                                    <span>{ticket.location.locationName}</span>
                                    <span
                                        className={`viewMachine__status ${ticket.status.toLowerCase()}`}
                                    >
                                        {ticket.status}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={ticket.priority.toLowerCase() === 'low' ? faArrowDown : faArrowUp}
                                            className={`viewMachine__priority ${ticket.priority.toLowerCase()}`}
                                        />
                                        {ticket.priority}
                                    </span>
                                    <span>
                                        {new Date(ticket.createdAt).toLocaleDateString()}
                                    </span>
                                </div>
                            ))
                        }
                    </div>

                    {/* Pagination */}
                    <div className="viewMachine__pages">
                        {
                            previousPageShow && (
                                <>
                                    <button
                                        onClick={() => {
                                            if (page > 0) setPage(page - 1)
                                        }}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (page > 0) setPage(page - 1)
                                        }}
                                    >
                                        {page - 1}
                                    </button>
                                </>
                            )
                        }
                        <button className='select'>
                            {page}
                        </button>
                        {
                            nextPageShow && (
                                <>
                                    <button
                                        onClick={() => {
                                            setPage(page + 1)
                                        }}
                                    >
                                        {page + 1}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setPage(page + 1)
                                        }}
                                    >
                                        Next
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewMachine