import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useStateValue } from "./Functionalities/StateProvider";
import axios from "./Functionalities/axios";
import { parseJwt } from "./Functionalities/parseJwt";
import Login from "./Pages/Login";
import SelectFactory from "./Pages/SelectFactory";
import SelectLocation from "./Pages/SelectLocation";
import AddLocation from "./Pages/AddLocation";
import UpdateLocation from "./Pages/UpdateLocation";
import UpdateMachine from "./Pages/UpdateMachine";
import AllLocation from "./Pages/AllLocation";
import ViewLocation from "./Pages/ViewLocation";
import AllEmployee from "./Pages/AllEmployee";
import AddEmployeeInfo from "./Pages/AddEmployeeInfo";
import ViewEmployeeInfo from "./Pages/ViewEmployeeInfo";
import AddTicket from "./Pages/AddTicket";
import UpdateTicket from "./Pages/UpdateTicket";
import AllTicket from "./Pages/AllTicket";
import Profile from "./Pages/Profile";
import ViewTicket from "./Pages/ViewTicket";
import AllSchedule from "./Pages/AllSchedule";
import ViewSchedule from "./Pages/ViewSchedule";
import AddSchedule from "./Pages/AddSchedule";
import AddMachine from "./Pages/AddMachine";
import AllMachine from "./Pages/AllMachine";
import ViewMachine from "./Pages/ViewMachine";
import Dashboard from "./Pages/Dashboard";
import UpdateSchedule from "./Pages/UpdateSchedule";
import Loading from "./Components/Loading";
import ResetPassword from "./Pages/ResetPassword";

function App() {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [state.factory, state.location]);

  useEffect(() => {
    const localToken = localStorage.getItem("token");

    if (localToken !== null) {
      setIsLoading(true);
      const { employeeID } = parseJwt(localToken);

      axios
        .get(`/employees/${employeeID}`)
        .then((response) => {
          console.log("response data",response.data)
          const employee = response.data;
console.log("employee use set =>",employee)
          // Saving logged Employee details in Context API
          dispatch({
            type: "LOGGED_EMPLOYEE",
            user: {
              email: employee.email.emailID,
              employeeID: employee.employeeID,
              name: employee.name,
              factory: employee.factory,
              location: employee.location,
              isAdmin: employee.isAdmin,
              maintenanceType: employee.maintenanceType,
              status: employee.status,
              profilePic: employee.profilePic,
            },
          });

          if (employee.status === "pending" && employee.status === "Pending") {
            let employeeFactory = [];
            employee.factory.map((f) => {
              let singleFactory = [];
              singleFactory[0] = f.factoryID;
              singleFactory[1] = f.factoryName;
              employeeFactory.push(singleFactory);
            });

            dispatch({
              type: "SET_FACTORY",
              factory: employeeFactory,
            });

            dispatch({
              type: "SELECTED_FACTORY",
              factory: employeeFactory,
            });

            let employeeLocation = [];
            employee.location.map((l) => {
              let singleLocation = [];
              singleLocation[0] = l.locationID;
              singleLocation[1] = l.locationName;
              employeeLocation.push(singleLocation);
            });

            dispatch({
              type: "SET_LOCATION",
              location: employeeLocation,
            });

            dispatch({
              type: "SELECTED_LOCATION",
              location: employeeLocation,
            });
          } else {
            if (
              employee.factory.length === 1 &&
              employee.location.length === 1
            ) {
              let employeeFactory = [];
              employee.factory.map((f) => {
                let singleFactory = [];
                singleFactory[0] = f.factoryID;
                singleFactory[1] = f.factoryName;
                employeeFactory.push(singleFactory);
              });

              dispatch({
                type: "SET_FACTORY",
                factory: employeeFactory,
              });

              dispatch({
                type: "SELECTED_FACTORY",
                factory: employeeFactory,
              });

              let employeeLocation = [];
              employee.location.map((l) => {
                let singleLocation = [];
                singleLocation[0] = l.locationID;
                singleLocation[1] = l.locationName;
                employeeLocation.push(singleLocation);
              });

              dispatch({
                type: "SET_LOCATION",
                location: employeeLocation,
              });

              dispatch({
                type: "SELECTED_LOCATION",
                location: employeeLocation,
              });
            } else if (employee.factory.length > 1) {
              let employeeFactory = [];
              employee.factory.map((f) => {
                let singleFactory = [];
                singleFactory[0] = f.factoryID;
                singleFactory[1] = f.factoryName;
                employeeFactory.push(singleFactory);
              });

              dispatch({
                type: "SET_FACTORY",
                factory: employeeFactory,
              });
            } else if (employee.location.length > 1) {
              let employeeFactory = [];
              employee.factory.map((f) => {
                let singleFactory = [];
                singleFactory[0] = f.factoryID;
                singleFactory[1] = f.factoryName;
                employeeFactory.push(singleFactory);
              });

              dispatch({
                type: "SET_FACTORY",
                factory: employeeFactory,
              });

              dispatch({
                type: "SELECTED_FACTORY",
                factory: employeeFactory,
              });

              let employeeLocation = [];
              employee.location.map((l) => {
                let singleLocation = [];
                singleLocation[0] = l.locationID;
                singleLocation[1] = l.locationName;
                employeeLocation.push(singleLocation);
              });

              dispatch({
                type: "SET_LOCATION",
                location: employeeLocation,
              });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);

          if (error.response) {
            if (
              error.response.status === 500 &&
              error.response.data !== undefined &&
              error.response.data !== null
            ) {
              store.addNotification({
                title: "Error Occurred!",
                message: "Task exists already",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else if (
              error.response.status === 400 ||
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Internal Server Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          } else if (error.request) {
            store.addNotification({
              title: "Error Occurred!",
              message: "Network Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            if (error.message) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else if (error.data) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Something went wrong",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <Router>
      <div className="app">
        <ReactNotification />
        <Switch>
          {localStorage.getItem("token") !== null ? (
            <Switch>
              {state.employee.status === "pending" ||
              state.employee.status === "Pending" ? (
                <Route
                  exact
                  path="/"
                  render={() => {
                    let employeeFactory = [];
                    state.employee.factory.map((f) => {
                      let singleFactory = [];
                      singleFactory[0] = f.factoryID;
                      singleFactory[1] = f.factoryName;
                      employeeFactory.push(singleFactory);
                    });

                    dispatch({
                      type: "SET_FACTORY",
                      factory: employeeFactory,
                    });

                    dispatch({
                      type: "SELECTED_FACTORY",
                      factory: employeeFactory,
                    });

                    let employeeLocation = [];
                    state.employee.location.map((l) => {
                      let singleLocation = [];
                      singleLocation[0] = l.locationID;
                      singleLocation[1] = l.locationName;
                      employeeLocation.push(singleLocation);
                    });

                    dispatch({
                      type: "SET_LOCATION",
                      location: employeeLocation,
                    });

                    dispatch({
                      type: "SELECTED_LOCATION",
                      location: employeeLocation,
                    });

                    return <Redirect to="/profile" />;
                  }}
                />
              ) : state.factory.length === 1 &&
                state.location.length === 1 &&
                state.employee.status !== "pending" &&
                state.employee.status !== "Pending" ? (
                <Route
                  exact
                  path="/"
                  render={() => {
                    let employeeFactory = [];
                    state.employee.factory.map((f) => {
                      let singleFactory = [];
                      singleFactory[0] = f.factoryID;
                      singleFactory[1] = f.factoryName;
                      employeeFactory.push(singleFactory);
                    });

                    dispatch({
                      type: "SET_FACTORY",
                      factory: employeeFactory,
                    });

                    dispatch({
                      type: "SELECTED_FACTORY",
                      factory: employeeFactory,
                    });

                    let employeeLocation = [];
                    state.employee.location.map((l) => {
                      let singleLocation = [];
                      singleLocation[0] = l.locationID;
                      singleLocation[1] = l.locationName;
                      employeeLocation.push(singleLocation);
                    });

                    dispatch({
                      type: "SET_LOCATION",
                      location: employeeLocation,
                    });

                    dispatch({
                      type: "SELECTED_LOCATION",
                      location: employeeLocation,
                    });

                    return <Redirect to="/dashboard" />;
                  }}
                />
              ) : state.factory.length > 1 &&
                state.employee.status !== "pending" &&
                state.employee.status !== "Pending" ? (
                <Route
                  exact
                  path="/"
                  render={() => {
                    let employeeFactory = [];
                    state.employee.factory.map((f) => {
                      let singleFactory = [];
                      singleFactory[0] = f.factoryID;
                      singleFactory[1] = f.factoryName;
                      employeeFactory.push(singleFactory);
                    });

                    dispatch({
                      type: "SET_FACTORY",
                      factory: employeeFactory,
                    });

                    return <Redirect to="/factory" />;
                  }}
                />
              ) : state.location.length > 1 &&
                state.employee.status !== "pending" &&
                state.employee.status !== "Pending" ? (
                <Route
                  exact
                  path="/"
                  render={() => {
                    let employeeFactory = [];
                    state.employee.factory.map((f) => {
                      let singleFactory = [];
                      singleFactory[0] = f.factoryID;
                      singleFactory[1] = f.factoryName;
                      employeeFactory.push(singleFactory);
                    });

                    dispatch({
                      type: "SET_FACTORY",
                      factory: employeeFactory,
                    });

                    dispatch({
                      type: "SELECTED_FACTORY",
                      factory: employeeFactory,
                    });

                    let employeeLocation = [];
                    state.employee.location.map((l) => {
                      let singleLocation = [];
                      singleLocation[0] = l.locationID;
                      singleLocation[1] = l.locationName;
                      employeeLocation.push(singleLocation);
                    });

                    dispatch({
                      type: "SET_LOCATION",
                      location: employeeLocation,
                    });

                    return <Redirect to="/factory/location" />;
                  }}
                />
              ) : (
                // <Loading />
                <Login />
              )}
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <Route path="/viewMachine">
                <ViewMachine />
              </Route>
              <Route path="/allMachine">
                <AllMachine />
              </Route>
              <Route path="/addMachine">
                <AddMachine />
              </Route>
              <Route path="/addSchedule">
                <AddSchedule />
              </Route>
              <Route path="/viewSchedule">
                <ViewSchedule />
              </Route>
              <Route path="/allSchedule">
                <AllSchedule />
              </Route>
              <Route path="/viewTicket">
                <ViewTicket />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/allTicket">
                <AllTicket />
              </Route>
              <Route path="/addTicket">
                <AddTicket />
              </Route>
              <Route path="/updateTicket">
                <UpdateTicket />
              </Route>
              <Route path="/viewEmployee">
                <ViewEmployeeInfo />
              </Route>
              <Route path="/addEmployee">
                <AddEmployeeInfo />
              </Route>
              <Route path="/allEmployee">
                <AllEmployee />
              </Route>
              <Route path="/viewLocation">
                <ViewLocation />
              </Route>
              <Route path="/allLocation">
                <AllLocation />
              </Route>
              <Route path="/addLocation">
                <AddLocation />
              </Route>
              <Route path="/factory/location">
                <SelectLocation />
              </Route>
              <Route path="/factory">
                <SelectFactory />
              </Route>
              <Route path="/updateLocation">
                <UpdateLocation />
              </Route>
              <Route path="/updateMachine">
                <UpdateMachine />
              </Route>
              <Route path="/updateSchedule">
                <UpdateSchedule />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/resetPassword/:id">
                <ResetPassword />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          )}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
