import axios from 'axios'
// import { Buffer } from 'buffer'
var Buffer = require('buffer/').Buffer  // note: the trailing slash is important
const globalVar={
    username:'user_name',
    password:'123456789'
}
// Setting up base Url for fetching data
const instance = axios.create({
    // Client
    // baseURL: 'https://hawkins-client.herokuapp.com/api/',

    // Development
    // baseURL: 'https://hawkinsapp.herokuapp.com/api/',
    // baseURL: 'https://stormy-waters-26311.herokuapp.com/api/',
     baseURL: 'https://hawkins-backend.herokuapp.com/api/',

    headers:{
        'Content-Type':'application/json',
                    // 'Acess-Control-Allow-Origin':'*',
                    'Authorization': `Basic ${new Buffer(`${globalVar.username}:${globalVar.password}`).toString('base64')}`,
                    'Accept': "application/json"
        }

    // Debug
    // baseURL: 'http://localhost:5000/api/',
})

export default instance