import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import detailsIcon from '../Assets/9-squares-menu 2.png'
import logo from '../Assets/logo.svg'
import Modal from 'react-modal'
import '../Styles/DashboardHeader.css'
import { Avatar } from "@material-ui/core"
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded'
import { useStateValue } from '../Functionalities/StateProvider'
import axios from "../Functionalities/axios";
import { useHistory } from 'react-router'
import AddEmployeeInfo from '../Pages/AddEmployeeInfo'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {randomHexColor} from 'random-hex-color-generator'

// Required for Modal Component to show without error in console
Modal.setAppElement('#root')

// Header for logged-in user
function DashboardHeader() {

    const [state, dispatch] = useStateValue()
    const [modalState, setModalState] = useState(false)
    const [notificationModalState, setNotificationModalState] = useState(false)
    const [locations, setLocations] = useState([])
    const [selectedFactory, setSelectedFactory] = useState(localStorage.getItem('selected_factory'))
    const history = useHistory()

    // Whenever the factory gets changed the locations under that particular factory is been fetched inside this useEffect
    useEffect(() => {

        dispatch({
            type: 'SELECTED_FACTORY',
            factory: selectedFactory
        })

        const fetchLocations = () => {
            console.log('fetch location',selectedFactory)
            if (selectedFactory?.length === 2) {
                console.log('fetch location',selectedFactory)
                axios.get(`locations/${selectedFactory[0]}`)
                    .then(response => {
                        console.log('data location',response)
                        setLocations(response.data.locations)
                    })
                    .catch(error => {
                        if (error.response) {
                            if (
                                error.response.status === 400 ||
                                error.response.status === 401 ||
                                error.response.status === 404 ||
                                error.response.status === 403 ||
                                error.response.status === 500
                            ) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: error.response.data.error,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Internal Server Error',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else if (error.request) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Network Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            if (error.message) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: error.message,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else if (error.data) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: error.data,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Something went wrong',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        }
                    })
            }
        }
        fetchLocations()        
    }, [selectedFactory])

    // Header for logged users
    return (
        <div className='dashboardHeader'>
<div className="row rowWidth">
    <div className="col-md-6"> {/* Dashboard Header left part containing icon, logo, app name with a button */}
            <div className="dashboardHeader__left">
                <IconButton onClick={e => setModalState(!modalState)}>
                    <img src={detailsIcon} alt="" />
                </IconButton>

                {/* Selecting Factory and Selecting Location Modal */}
                <Modal
                    isOpen={modalState}
                    className='dashboardHeader__modal'
                    closeTimeoutMS={200}
                >
                    <div className="dashboardHeader__modalHeading">select factory</div>
                    <div className="dashboardHeader__modalRow">
                        {
                            state.factory.map(singleFactory => (
                                <button
                                    key={singleFactory[0]}
                                    className="dashboardHeader__modalBox"
                                    onClick={
                                        e => {

                                            setSelectedFactory(() => ([
                                                singleFactory[0],
                                                singleFactory[1]
                                            ]))
                                        }
                                    }
                                >
                                    {singleFactory[1]}
                                </button>
                            ))
                        }
                    </div>
                    {console.log("locations",locations)}
                    {
                        locations.length > 0 ? <div className="dashboardHeader__modalHeading">select location</div> : null
                    }
                    <div className="dashboardHeader__modalRow">
                        {
                            locations.map(location => (
                                <button
                                    key={location._id}
                                    value={location.locationName}
                                    className='dashboardHeader__modalBox'
                                    onClick={
                                        () => {
                                            dispatch({
                                                type: 'SELECTED_LOCATION',
                                                location: [location.locationID, location.locationName],
                                            })
                                            setModalState(!modalState)
                                            history.push('/dashboard')
                                        }
                                    }
                                >
                                    {location.locationName}
                                </button>
                            ))
                        }
                    </div>
                </Modal>
                <img className='dashboardHeader__logo' src={logo} alt="Hawkins Logo" />
                <h2>Incident Management App</h2>
                <button className="borderRaiseButton"
                    onClick={e => {
                        e.preventDefault()

                        history.push('/addTicket')
                    }}
                >
                    Raise A Ticket
                </button>
            </div></div>
    <div className="col-md-6"> {/* Dashboard Header right part containing notification icon with an avatar */}
            <div className="dashboardHeader__right">
                <input
                    size={35}
                    type="text"
                    placeholder='search anything...'
                />
                <IconButton
                    onClick={e => setNotificationModalState(!notificationModalState)}
                >
                    <NotificationsRoundedIcon />
                </IconButton>
                <Modal
                    isOpen={notificationModalState}
                    className='dashboardHeader__notification'
                >
                    <h4>notifications</h4>
                    <div className="dashboardHeader__notificationList">
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                        <div className="dashboardHeader__notificationBox">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            <span>27-Jan-2021</span>
                        </div>
                    </div>
                    <button>see all</button>
                </Modal>
                <Avatar 
                    className='dashboardHeader__rightAvatar' 
                    src={`https://ui-avatars.com/api/?name=${state.employee.name}`} 
                    alt={state.employee.name} 
                />
            </div></div>

</div>
           

           
        </div>
    )
}

export default DashboardHeader