import { IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import '../Styles/AddTaskForm.css'
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded'
import axios from '../Functionalities/axios'
import { useStateValue } from '../Functionalities/StateProvider'

function AddTaskFormOnSchedule({ index, removeTask, store, setTaskIDs }) {

    const [state, dispatch] = useStateValue()
    const [taskName, setTaskName] = useState('')
    const [taskDescription, setTaskDescription] = useState('')
    const [taskDeadline, setTaskDeadline] = useState('')
    const [deadlineTime, setDeadlineTime] = useState('')
    const [taskStart, setTaskStart] = useState('')
    const [startTime, setStartTime] = useState('')

    const handleClick = e=> {
        e.preventDefault()
console.log('state.tasklist',state.taskList)
   console.log('TaskList Before -> ', state.taskList.length);
        let newTaskList = state.taskList
        console.log('index',index)
        newTaskList.splice(index, 1)
        console.log(newTaskList);
        console.log('TaskList After -> ', newTaskList.length);
        console.log(index)
        // setTaskList(newTaskList)
        dispatch({
            type: 'taskList',
            taskList: newTaskList
        })

        // console.log('setTaskID',)
        // removeTask(index)
    }

    // Uploading Task
    const addTask = async () => {
        console.log('taskName',taskName,taskDescription,'taskDescription',taskStart,'taskStart',startTime,'startTime',taskDeadline,'taskDeadline',deadlineTime,'deadlineTime')

        if (
            taskName && 
            taskDescription && 
            taskStart &&
            startTime &&
            taskDeadline &&
            deadlineTime
        ) {
            await axios
                .post('tasks/new', {
                    "taskName": taskName,
                    "taskDescription": taskDescription,
                    "status": "Open",
                    "taskStartTime": new Date(taskStart + ' ' + startTime).toISOString(),
                    "taskEndTime": new Date(taskDeadline + ' ' + deadlineTime).toISOString()
                }).then(response => response.data)
                .then(data => {
                    setTaskIDs(taskIDs => [...taskIDs, data._id])
                    store.addNotification({
                        title: "Task Created!",
                        message: `Task Name - ${data.taskName}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        if (
                            error.response.status === 500 && 
                            error.response.data !== undefined &&
                            error.response.data !== null
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Task exists already',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else if (taskName === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task Name',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskDescription === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task Description',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskStart === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task Start Date',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (startTime === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task Start Time',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskDeadline === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task End Date',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (deadlineTime === '') {
            store.addNotification({
                title: "Cannot create Task!",
                message: 'Enter Task End Time',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        }
    }

    // Add Task Form in Create Schedule Page
    return (
        <div className='addTaskForm'>
            <div className="addTaskForm__inputGroup">
                <label>task name</label>
                <input type="text" className="heightOfInputBox" value={taskName} onChange={e => setTaskName(e.target.value)} />
            </div>
            <div className="addTaskForm__inputGroup">
                <label>task description</label>
                <textarea value={taskDescription} onChange={e => setTaskDescription(e.target.value)} />
            </div>
            <div className="addTaskForm__remove">
                <IconButton onClick={handleClick} className='addTaskForm__icon'>
                    <RemoveCircleRoundedIcon />
                </IconButton>
            </div>
            <div className="addTaskForm__inputGroup">
                <label>task Start Date</label>
                <input type="date" className="heightOfInputBox" value={taskStart} onChange={e => setTaskStart(e.target.value)} />
            </div>
            <div className="addTaskForm__inputGroup">
                <label>Task start time</label>
                <input type="time" className="heightOfInputBox" value={startTime} onChange={e => setStartTime(e.target.value)} />
            </div>
            <div className="addTaskForm__inputGroup">
                <label>task end date</label>
                <input type="date" className="heightOfInputBox" value={taskDeadline} onChange={e => setTaskDeadline(e.target.value)} />
            </div>
            <div className="addTaskForm__inputGroup">
                <label>task end time</label>
                <input type="time" className="heightOfInputBox" value={deadlineTime} onChange={e => setDeadlineTime(e.target.value)} />
            </div>
            <div className="addTaskForm__inputGroup">
                <div className="addTaskForm__buttons">
                    <button onClick={()=>{addTask()}}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default AddTaskFormOnSchedule
