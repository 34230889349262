import React, { useEffect, useState } from "react";
import "../Styles/AllEmployee.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";
import axios from "../Functionalities/axios";
import { useStateValue } from "../Functionalities/StateProvider";
import { useHistory } from "react-router-dom";
import ExportExcelFile from "../Components/ExportExcelFile";
import ReactNotification, { store } from "react-notifications-component";
import Loader from "react-loader-spinner";

import "react-notifications-component/dist/theme.css";
import { getEmployee } from "../Functionalities/getEmployee";
import Modal from "react-modal";
function AllEmployee() {
    const [employees, setEmployees] = useState([]);
    const [state, dispatch] = useStateValue();

    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage
        .getItem("selected_factory")
        .split(",");
    const selectedLocationDetails = localStorage
        .getItem("selected_location")
        .split(",");
    const history = useHistory(); // For Browser Routing
    const [deleteEmployees, setDeleteEmployees] = useState(false);

    // Getting account of employees selected from employee list
    const [isChecked, setIsChecked] = useState([]);
    const [locations, setLocations] = useState(null);
    const [locationSelected, setLocationSelected] = useState([
        {
            label: "All",
            value: "All",
        },
    ]);
    const [maintenanceType, setMaintenanceType] = useState(null);
    const [authorizationStatusSelected, setAuthorizationStatusSelected] =
        useState(null);
    const [dateTime, setDateTime] = useState(null);
    const [loader,setLoader] = useState(true)

    const [page, setPage] = useState(1); // For Pagination
    const [previousPageShow, setPreviousPageShow] = useState(false); // For showing Previous Page Button
    const [nextPageShow, setNextPageShow] = useState(false); // For showing Next Page Button
    const [editEmployee, setEditEmployee] = useState(null);
    const limit = 10; // For Pagination
    const [search, setSearch] = useState(null);
    useEffect(() => {
        console.log(isChecked);
        console.log(employees);

        getEmployee(
            setEmployees,
            store,
            selectedFactoryDetails[0],
            limit,
            page,
            setNextPageShow,
            setPreviousPageShow,
            setLoader
        );
        setEditEmployee(employees);
    }, []);
    const deleteEmployee = (id) => {
        axios
            .delete(`employees/${id}`)
            .then((response) => response.data)
            .then((data) => {
                //    console.log('data of delete employee',data)
                //    alert("Employee Deleted Successfully")
                //    history.goBack();
            })
            .catch((error) => {
                alert(error);
            });
    };
    const deleteEmployeeArray = () => {
        console.log(isChecked);
        console.log(employees);
        employees.map((item, key) => {
            return isChecked.map((checkk, checkKey) => {
                return checkKey == key && checkk == true
                    ? deleteEmployee(item.employeeID)
                    : null;
            });
        });
        getEmplo();
    };
    const getEmplo = async () => {
        // getEmployee(setEmployees, store, selectedFactoryDetails[0])
        console.log("getEmplo");
        setIsChecked([]);
        setDeleteEmployees(false);
        await getEmployee(
            setEmployees,
            store,
            selectedFactoryDetails[0],
            limit,
            page,
            setNextPageShow,
            setPreviousPageShow,
            setLoader
        );
        
  
        // console.log('all employee route triger')
        // history.push("/allEmployee");
    };
    // Sorting Options
    const sortOptions = [
        {
            value: 1,
            label: "Employee ID",
        },
        {
            value: 2,
            label: "Department",
        },
        {
            value: 3,
            label: "Name",
        },
    ];
    // Maintenance Type
    const maintenanceTypeList = [
        {
            value: "All",
            label: "All",
        },
        {
            value: "Maintenance Personnel",
            label: "Maintenance Personnel",
        },
        {
            value: "Maintenance Manager",
            label: "Maintenance Manager",
        },
    ];
    const authorizationStatus = [
        {
            value: "All",
            label: "All",
        },
        {
            value: "Pending",
            label: "Pending",
        },
        {
            value: "Verified",
            label: "Verified",
        },
        {
            value: "Cancelled",
            label: "Cancelled",
        },
        {
            value: "Changes Needed",
            label: "Changes Needed",
        },
        {
            value: "Disabled",
            label: "Disabled",
        },
    ];
    // Timeline List
    const timelineList = [
        {
            value: "All",
            label: "All",
        },
        {
            value: "today",
            label: "Today",
        },
        {
            value: "this-week",
            label: "This Week",
        },
        {
            value: "this-month",
            label: "This Month",
        },
        {
            value: "last-three-months",
            label: "Last 3 Months",
        },
        {
            value: "this-year",
            label: "This Year",
        },
    ];
    // search function
    const searchFunc = async () => {
        let haveLocation = locationSelected;
        let haveMaintenanceType = maintenanceType;
        let haveAuthorizationStatusSelected = authorizationStatusSelected;
        let haveDateTime = dateTime;

        if (locationSelected) {
            console.log("if", locationSelected);
            if (locationSelected.label === "All") {
                haveLocation = null;
            } else {
                haveLocation = locationSelected.label;
            }
        }

        if (maintenanceType) {
            console.log("if", maintenanceType);
            if (maintenanceType.label === "All") {
                haveMaintenanceType = null;
            } else {
                haveMaintenanceType = maintenanceType.label;
            }
        }

        if (authorizationStatusSelected) {
            console.log("if", authorizationStatusSelected);
            if (authorizationStatusSelected.label === "All") {
                haveAuthorizationStatusSelected = null;
            } else {
                haveAuthorizationStatusSelected = authorizationStatusSelected.label;
            }
        }

        if (dateTime) {
            console.log("if", dateTime);
            if (dateTime.label === "All") {
                haveDateTime = null;
            } else {
                haveDateTime = dateTime.value;
            }
        }

        console.log(
            "search key",
            search,
            "haveLocation",
            haveLocation,
            "haveMaintenanceType",
            haveMaintenanceType,
            "haveAuthorizationStatusSelected",
            haveAuthorizationStatusSelected,
            "haveDateTime",
            haveDateTime
        );
        await axios
            .get(
                `employees/factory/${selectedFactoryDetails[0]}?text=${search}&location_id=${haveLocation}&maintenance_type=${haveMaintenanceType}&status=${haveAuthorizationStatusSelected}&date_filter=${haveDateTime}`
            )
            .then((response) => response.data)
            .then((data) => {
                console.log("data.get all All Employees search", data);
                console.log("search result of employees", data.employees);
                setEmployees(data.employees);
                if (data.next) setNextPageShow(true);
                if (data.previous) setPreviousPageShow(true);
            })
            .catch((error) => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: "Internal Server Error",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: "Network Error",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true,
                        },
                    });
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: "Something went wrong",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    }
                }
            });
    };
    useEffect(() => {
        setPreviousPageShow(false);
        setNextPageShow(false);
    }, [page]);

    // Fetching Data
    useEffect(() => {
        getEmplo();
    }, [page]);

    useEffect(() => {
        // Showing Loading Component till fetching data
        console.log("location get function");
        // Fetching data from Selected Factory
        if (state.selectedFactoryDetails !== {}) {
            console.log("selectedFactoryDetails", selectedFactoryDetails[0]);
            axios
                .get(`locations/${selectedFactoryDetails[0]}`)
                .then((response) => {
                    console.log("data of location", response.data.locations);
                    // setLocations(response.data.locations)

                    let l = [];
                    l.push({
                        label: "All",
                        value: "All",
                    });
                    response.data.locations.forEach((singleLocation) => {
                        console.log("singleLocation", singleLocation);
                        l.push({
                            value: singleLocation.locationID,
                            label: singleLocation.locationName,
                        });
                    });
                    setLocations(l);
                    setLocationSelected(l[0]);
                    resetAllValue(l[0]);
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true,
                                },
                            });
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: "Internal Server Error",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true,
                                },
                            });
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: "Network Error",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true,
                                },
                            });
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true,
                                },
                            });
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: "Something went wrong",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true,
                                },
                            });
                        }
                    }
                });
        }
    }, []);
    const resetAllValue = (locationStart) => {
        setSearch("");
        setLocationSelected(locationStart);
        setMaintenanceType(maintenanceTypeList[0]);
        setAuthorizationStatusSelected(authorizationStatus[0]);
        setDateTime(timelineList[0]);
        console.log("done");
        getEmplo();
    };
    return (
        <div className="allEmployee">
            {/* Header */}
            <DashboardHeader />

            <div className="allEmployee__mainBody">
                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />
                <div className="allEmployee__container">
                    {/* Navigation Breadcrumb */}
                    <div className="allEmployee__containerPath">
                        Factory - {selectedFactoryDetails[1]} / All Employees
                    </div>

                    {/* Heading */}
                    <h1>All Employees</h1>

                    {/* Input Fields */}
                    <div className="allEmployee__inputFields">
                        <div className="row rowWidthAllLocation">
                            <div className="col-md-3 col-xs-12 col-sm-12">
                                {" "}
                                <input
                                    type="text"
                                    className="widthOfSearch heightOfInputBox"
                                    size={100}
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />{" "}
                            </div>
                            <div className="col-md-2 col-xs-12 col-sm-12">
                                {console.log(" all locations", locations)}
                                <Select
                                    options={locations}
                                    placeholder="Location"
                                    className="allEmployee__select "
                                    defaultValue={locationSelected[0]}
                                    value={locationSelected}
                                    onChange={(e) => setLocationSelected(e)}
                                />
                            </div>
                            <div className="col-md-2 col-xs-12 col-sm-12">
                                <Select
                                    options={maintenanceTypeList}
                                    placeholder="Maintenance Type"
                                    className="allEmployee__select "
                                    defaultValue={maintenanceTypeList[0]}
                                    value={maintenanceType}
                                    onChange={(e) => setMaintenanceType(e)}
                                />{" "}
                            </div>
                            <div className="col-md-2 col-xs-12 col-sm-12">
                                <Select
                                    options={authorizationStatus}
                                    placeholder="Authorization Status"
                                    className="allEmployee__select "
                                    defaultValue={authorizationStatus[0]}
                                    value={authorizationStatusSelected}
                                    onChange={(e) => setAuthorizationStatusSelected(e)}
                                />{" "}
                            </div>
                            <div className="col-md-2 col-xs-12 col-sm-12">
                                <Select
                                    options={timelineList}
                                    placeholder="Date"
                                    className="allEmployee__select "
                                    defaultValue={timelineList[0]}
                                    value={dateTime}
                                    onChange={(e) => setDateTime(e)}
                                />{" "}
                            </div>

                            <div className="col-md-1 col-sm-12 col-xs-12">
                                {" "}
                                <button
                                    className="heightOfInputBox"
                                    style={{ marginBottom: 10 }}
                                    onClick={() => searchFunc()}
                                >
                                    Search
                                </button>
                            </div>
                            <div className="col-md-1 col-sm-12 col-xs-12">
                                {" "}
                                <button
                                    className="heightOfInputBox"
                                    style={{ marginBottom: 10 }}
                                    onClick={() =>
                                        resetAllValue({
                                            label: "All",
                                            value: "All",
                                        })
                                    }
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="allEmployee__buttons">
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                {state.employee.isAdmin && <div className="allEmployee__leftBtns">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            {" "}
                                            <button
                                                style={{ marginBottom: 10 }}
                                                onClick={() => {
                                                    console.log(editEmployee, "editLocation");
                                                    history.push({
                                                        pathname: "/viewEmployee",
                                                        state: { data: editEmployee },
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="allEmployee__icon"
                                                    icon={faEdit}
                                                />
                                                edit
                                            </button>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <button
                                                // onClick={()=>{
                                                //     deleteEmployeeArray();
                                                // }}
                                                onClick={() => {
                                                    setDeleteEmployees(true);
                                                }}
                                                className="alignDeleteButton"
                                            >
                                                <FontAwesomeIcon
                                                    className="allEmployee__icon"
                                                    icon={faTrashAlt}
                                                />
                                                delete
                                            </button>
                                        </div>
                                    </div>

                                    {/* modal start here  */}
                                    <Modal
                                        className="delete__modal"
                                        isOpen={deleteEmployees}
                                        closeTimeoutMS={20}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <h4>do you want to delete this employee?</h4>
                                            <div className="row rowWidth">
                                                <div className="col-md-6 col-sm-12 col-xs-12">
                                                    {" "}
                                                    <button onClick={() => setDeleteEmployees(false)}>
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <button
                                                        onClick={() => {
                                                            deleteEmployeeArray();
                                                        }}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                    {/* modal end here */}
                                </div>}
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                {" "}
                                <div className="allEmployee__rightBtns">
                                    <ExportExcelFile csvData={employees} fileName="Employees" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {loader ? <div style={{width:'100%',padding:'14% 48%' ,justifyContent:'center',alignContent:'center'}}>
        <Loader
        type="Puff"
        color="#C71800"
        height={'100%'}
        width={"100%"}
        // timeout={3000} //3 secs
      /> 
      </div>
        :   
        <div className="allEmployee__table">
                        {/* Table Heading */}
                        <div className="allEmployee__tableHeading">
                            <span>
                                <input
                                    type="checkbox"
                                    className="heightOfInputBox"
                                    onChange={(e) => {
                                        setIsChecked((previousValue) => {
                                            previousValue = new Array(employees.length);
                                            previousValue.fill(e.target.checked);
                                            return previousValue;
                                        });
                                    }}
                                />
                            </span>
                            <span>Display Picture</span>
                            <span>Name</span>
                            <span>Employee ID</span>
                            <span>Location</span>
                            <span>Phone</span>
                            <span>App Authorization</span>
                        </div>

                        {/* Table Data */}
                        {console.log("employee", employees)}
                        {employees?.map((employee, index) => (
                            <div
                                key={employee._id}
                                className={`allEmployee__tableData ${isChecked[index] && "selected"
                                    }`}
                            >
                                <span>
                                    <input
                                        type="checkbox"
                                        className="heightOfInputBox"
                                        onChange={(e) => {
                                            setIsChecked((isChecked) => {
                                                let c = [...isChecked];
                                                c[index] = e.target.checked;
                                                return c;
                                            });
                                            console.log(isChecked);
                                        }}
                                        checked={isChecked[index]}
                                    />
                                </span>
                                <span>
                                    <Avatar />
                                </span>
                                <span
                                    onClick={(e) => {
                                        e.preventDefault();

                                        dispatch({
                                            type: "SINGLE_EMPLOYEE",
                                            employee: employee,
                                        });
                                        history.push("/viewEmployee");
                                    }}
                                >
                                    {employee.name}
                                </span>
                                <span>{employee.employeeID}</span>
                                <span>{employee.location[0].locationName}</span>
                                <span>{employee.phone}</span>
                                <span className="allEmployee__appAuth">
                                    <div
                                        className={`allEmployee__tableData ${employee.status.toLowerCase()}`}
                                    >
                                        {employee.status}
                                    </div>
                                </span>
                            </div>
                        ))}
                    </div>
                    }
                    {/* Table */}
                   

                    {/* Pagination Section */}
                    <div className="allEmployee__pages">
                        {previousPageShow && (
                            <>
                                <button
                                    onClick={() => {
                                        if (page > 0) setPage(page - 1);
                                    }}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => {
                                        if (page > 0) setPage(page - 1);
                                    }}
                                >
                                    {page - 1}
                                </button>
                            </>
                        )}
                        <button className="select">{page}</button>
                        {nextPageShow && (
                            <>
                                <button
                                    onClick={() => {
                                        setPage(page + 1);
                                    }}
                                >
                                    {page + 1}
                                </button>
                                <button
                                    onClick={() => {
                                        setPage(page + 1);
                                    }}
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllEmployee;