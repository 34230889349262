import React, { useEffect, useState } from "react";
import "../Styles/AllTicket.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import { useStateValue } from "../Functionalities/StateProvider";
import Select from "react-select";
import Loader from "react-loader-spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faRedoAlt,
  faShareAlt,
  faTools,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "../Functionalities/axios";
import { useHistory } from "react-router";
import ExportExcelFile from "../Components/ExportExcelFile";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { dateFormat } from "../Functionalities/dateFormat";
import Modal from "react-modal";
import {
  useLocation
} from "react-router-dom";
function AllTicket() {
  const history = useHistory();
  const [state, dispatch] = useStateValue();
  const [tickets, setTickets] = useState([]);
  const locationn = useLocation();
  const dataa = locationn?.state?.data;
  // Getting previously selected Factory and Location for Data Persistent
  const selectedFactoryDetails = localStorage
    .getItem("selected_factory")
    .split(",");
  const selectedLocationDetails = localStorage
    .getItem("selected_location")
    .split(",");

  // Getting account of tickets selected from ticket list
  const [isChecked, setIsChecked] = useState([]);

  const [page, setPage] = useState(1); // For Pagination
  const [previousPageShow, setPreviousPageShow] = useState(false); // For showing Previous Page Button
  const [nextPageShow, setNextPageShow] = useState(false); // For showing Next Page Button
  const limit = 10; // For Pagination
  const [deleteTicket, setDeleteTicket] = useState(false); // for modal open and close
  const [editTicket, setEditTicket] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [status, setStatus] = useState(null);
  const [priority, setPriority] = useState(null);
  const [ticketType, setTicketType] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [search, setSearch] = useState(null);
  const [loader,setLoader] = useState(true)

  console.log(selectedLocationDetails);
  console.log(state.employee.isAdmin);

  const deleteTicketArray = () => {
    console.log(isChecked);
    console.log(tickets);
    tickets.map((item, key) => {
      return isChecked.map((checkk, checkKey) => {
        return checkKey == key && checkk == true
          ? deleteTicketID(item.ticketID)
          : null;
      });
    });
    gettickets();
  };
  const gettickets = async () => {
    // getEmployee(setEmployees, store, selectedFactoryDetails[0])
    console.log("gettickets");
    setDeleteTicket(false);
    setIsChecked([]);
    await getTicket(setTickets, store, selectedFactoryDetails[0]);
    history.push("/allTicket");
  };
  const deleteTicketID = (id) => {
    axios
      .delete(`tickets/${id}`)
      .then((response) => response.data)
      .then((data) => {
        //    console.log('data of delete employee',data)
        //    alert("Employee Deleted Successfully")
        //    history.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };
  // Sorting Type List
  const sortList = [
    {
      value: 1,
      label: "All",
    },

    {
      value: 2,
      label: "Requested By",
    },
    {
      value: 3,
      label: "Assignee",
    },
  ];

  // Status List
  const statusList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "In-Progress",
      label: "In-Progress",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
  ];

  // Priority List
  const priorityList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Low",
      label: "Low",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "High",
      label: "High",
    },
  ];

  // Ticket Type List
  const ticketTypeList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Repair",
      label: "Repair",
    },
    {
      value: "Replace",
      label: "Replace",
    },
    {
      value: "General",
      label: "General",
    },
  ];

  // Timeline List
  const timelineList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    {
      value: "last-three-months",
      label: "Last 3 Months",
    },
    {
      value: "this-year",
      label: "This Year",
    },
  ];

  useEffect(async () => {
    setPreviousPageShow(false);
    setNextPageShow(false);

    if (dataa) {
      console.log('data comming from dashboard', dataa)

      if (dataa === 'all') {
        await resetAllValue()
        searchFuncV2(null)
      }
      if (dataa === 'open') {
        setFilterBy(sortList[0]);
        await setStatus(statusList[1]);
        setPriority(priorityList[0]);
        setTicketType(ticketTypeList[0]);
        setDateTime(timelineList[0]);
        setSearch("");
        searchFuncV2("Open")

      }
      if (dataa === 'RequestedBy') {
        setFilterBy(sortList[1]);
        await setStatus(statusList[0]);
        setPriority(priorityList[0]);
        setTicketType(ticketTypeList[0]);
        setDateTime(timelineList[0]);
        setSearch(state.employee.name);
        searchFuncV2("RequestedBy")

      }
      if (dataa === 'AssignTo') {
        setFilterBy(sortList[2]);
        await setStatus(statusList[0]);
        setPriority(priorityList[0]);
        setTicketType(ticketTypeList[0]);
        setDateTime(timelineList[0]);
        setSearch(state.employee.name);
        searchFuncV2("AssignTo")

      }
      if (dataa === 'in-progress') {
        setFilterBy(sortList[0]);
        await setStatus(statusList[2]);
        setPriority(priorityList[0]);
        setTicketType(ticketTypeList[0]);
        setDateTime(timelineList[0]);
        setSearch("");
        searchFuncV2("In-Progress")
      }
      if (dataa === 'closed') {
        setFilterBy(sortList[0]);
        await setStatus(statusList[3]);
        setPriority(priorityList[0]);
        setTicketType(ticketTypeList[0]);
        setDateTime(timelineList[0]);
        setSearch("");
        searchFuncV2("Resolved")
      }
    }

  }, [page]);

 // Fetching Data
 useEffect(() => {
  if(dataa){
      return true
  }else{

      getTicket();
  }
}, [page]);
  // search function
  const searchFuncV2 = async (e) => {
    let haveFilterBy = filterBy;
    let haveStatus = status;
    let havePriority = priority;
    let haveTicketType = ticketType;
    let haveDateTime = dateTime;

    if (filterBy) {
      console.log("if", filterBy);
      if (filterBy.label === "All") {
        haveFilterBy = null;
      } else {
        haveFilterBy = filterBy.label;
      }
    }
    if (status) {
      console.log("if", status);
      if (status.label === "All") {
        haveStatus = null;
      } else {
        haveStatus = status.label;
      }
    }
    if (priority) {
      console.log("if", priority);
      if (priority.label === "All") {
        havePriority = null;
      } else {
        havePriority = priority.label;
      }
    }
    if (ticketType) {
      console.log("if", ticketType);
      if (ticketType.label === "All") {
        haveTicketType = null;
      } else {
        haveTicketType = ticketType.label;
      }
    }
    if (dateTime) {
      console.log("if", dateTime);
      if (dateTime.label === "All") {
        haveDateTime = null;
      } else {
        haveDateTime = dateTime.value;
      }
    }
    let search2 = ''
    if(e === 'RequestedBy'){
      e = null
haveFilterBy = 'Requested By'
search2 = state.employee.name
    }
    if(e === 'AssignTo'){
      e = null
haveFilterBy = 'Assignee'
search2 = state.employee.name
    }
    console.log(
      "search inpunt",
      search2,
      "filterby",
      haveFilterBy,
      "status",
      e,
      "havePriority",
      havePriority,
      "haveTicketType",
      haveTicketType,
      "haveDateTime",
      haveDateTime
    );

    await axios
      .get(
        `tickets/location/${selectedLocationDetails[0]}?&employee_name=${search2}&employee_type=${haveFilterBy}&status=${e}&priority=${havePriority}&ticket_type=${haveTicketType}&date_filter=${haveDateTime}`
      )
      .then((response) => {
        setLoader(false)
        console.log("data.get all ticxket search", response);
        setTickets(response.data.tickets);
        // if (response.data.next) setNextPageShow(true)
        // if (response.data.previous) setPreviousPageShow(true)
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };

  // search function
  const searchFunc = async () => {
    let haveFilterBy = filterBy;
    let haveStatus = status;
    let havePriority = priority;
    let haveTicketType = ticketType;
    let haveDateTime = dateTime;

    if (filterBy) {
      console.log("if", filterBy);
      if (filterBy.label === "All") {
        haveFilterBy = null;
      } else {
        haveFilterBy = filterBy.label;
      }
    }
    if (status) {
      console.log("if", status);
      if (status.label === "All") {
        haveStatus = null;
      } else {
        haveStatus = status.label;
      }
    }
    if (priority) {
      console.log("if", priority);
      if (priority.label === "All") {
        havePriority = null;
      } else {
        havePriority = priority.label;
      }
    }
    if (ticketType) {
      console.log("if", ticketType);
      if (ticketType.label === "All") {
        haveTicketType = null;
      } else {
        haveTicketType = ticketType.label;
      }
    }
    if (dateTime) {
      console.log("if", dateTime);
      if (dateTime.label === "All") {
        haveDateTime = null;
      } else {
        haveDateTime = dateTime.value;
      }
    }
    console.log(
      "search inpunt",
      search,
      "filterby",
      haveFilterBy,
      "status",
      haveStatus,
      "havePriority",
      havePriority,
      "haveTicketType",
      haveTicketType,
      "haveDateTime",
      haveDateTime
    );

    await axios
      .get(
        `tickets/location/${selectedLocationDetails[0]}?&employee_name=${search}&employee_type=${haveFilterBy}&status=${haveStatus}&priority=${havePriority}&ticket_type=${haveTicketType}&date_filter=${haveDateTime}`
      )
      .then((response) => {
        setLoader(false)
        console.log("data.get all ticxket search", response);
        setTickets(response.data.tickets);
        // if (response.data.next) setNextPageShow(true)
        // if (response.data.previous) setPreviousPageShow(true)
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  // Fetching Ticket List
  const getTicket = async () => {
    await axios
      .get(
        `tickets/location/${selectedLocationDetails[0]}?limit=${limit}&page=${page}`
      )
      .then((response) => {
        setLoader(false)
        console.log("data.tickets get tickets", response.data.tickets);
        setTickets(response.data.tickets);
        if (response.data.next) setNextPageShow(true);
        if (response.data.previous) setPreviousPageShow(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  const resetAllValue = () => {
    setFilterBy(sortList[0]);
    setStatus(statusList[0]);
    setPriority(priorityList[0]);
    setTicketType(ticketTypeList[0]);
    setDateTime(timelineList[0]);
    setSearch("");
  };

  return (
    <div className="allTicket">
      {/* {searchFunc()} */}
      {/* Header */}
      <DashboardHeader />

      <div className="allTicket__mainBody">
        {/* Notifications */}
        <ReactNotification />

        {/* Sidebar */}
        <Sidebar />
        <div className="allTicket__container">
          {/* Navigation Breadcrumb */}
          <div className="allTicket__containerPath">
            {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / All
            Tickets
          </div>

          {/* Heading */}
          <h1>All Tickets</h1>

          {/* Input Fields */}
          <div className="allTicket__inputFields">
            <div className="row rowWidth">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <input
                  className="marginTopCol"
                  // size={35}
                  style={{ width: "100%" }}
                  className="heightOfInputBox"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <Select
                  options={sortList}
                  value={filterBy}
                  placeholder="Filter By"
                  className="allTicket__select marginTopCol sortMarginTopAllTicket"
                  defaultValue={sortList[0]}
                  onChange={(e) => {
                    setFilterBy(e);
                  }}
                />
              </div>
              {/* <div className="col-md-1 col-sm-12 col-xs-12"> </div> */}
              <div className="col-md-2 col-sm-12 col-xs-12">
                {" "}
                <Select
                  options={statusList}
                  placeholder="Status"
                  value={status}
                  className="allTicket__select marginTopCol"
                  defaultValue={statusList[0]}
                  onChange={(e) => {
                    setStatus(e);
                  }}
                />
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12">
                <Select
                  options={priorityList}
                  defaultValue={priorityList[0]}
                  value={priority}
                  onChange={(e) => {
                    setPriority(e);
                  }}
                  placeholder="Priority"
                  className="allTicket__select marginTopCol"
                />
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12">
                {" "}
                <Select
                  className="marginTopCol"
                  options={ticketTypeList}
                  placeholder="Ticket Type"
                  value={ticketType}
                  defaultValue={ticketTypeList[0]}
                  onChange={(e) => {
                    setTicketType(e);
                  }}
                  className="allTicket__select"
                />
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12">
                <Select
                  options={timelineList}
                  defaultValue={timelineList[0]}
                  placeholder="Date"
                  className="allTicket__select marginTopCol"
                  styles={{ marginBottom: 5 }}
                  value={dateTime}
                  onChange={(e) => {
                    setDateTime(e);
                  }}
                />
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12">
                <button
                  className="marginTopCol"
                  style={{ borderRadius: 5 }}
                  className="heightOfInputBox"
                  onClick={() => searchFunc()}
                //   onClick={searchFilter}
                >
                  Search
                </button>
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12">
                <button
                  className="marginTopCol"
                  style={{ borderRadius: 5 }}
                  className="heightOfInputBox"
                  onClick={() => resetAllValue()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="allTicket__buttons">
            {state.employee.isAdmin && <div className="allTicket__leftBtns">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {" "}
                  <button
                    className="mobBottom"
                    onClick={() => {
                      history.push({
                        pathname: "/updateTicket",
                        state: { data: editTicket },
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="allTicket__icon"
                      icon={faEdit}
                    />
                    edit
                  </button>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <button
                    onClick={() => {
                      setDeleteTicket(true);
                    }}
                    className="alignDeleteButton"
                  >
                    <FontAwesomeIcon
                      className="allTicket__icon"
                      icon={faTrashAlt}
                    />
                    delete
                  </button>
                </div>
                {/* modal start here  */}
                <Modal
                  className="delete__modal"
                  isOpen={deleteTicket}
                  closeTimeoutMS={20}
                  shouldCloseOnOverlayClick={true}
                >
                  <div style={{ width: "100%" }}>
                    <h4>do you want to delete this Tickets?</h4>
                    <div className="row rowWidth">
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        {" "}
                        <button onClick={() => setDeleteTicket(false)}>
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <button
                          onClick={() => {
                            deleteTicketArray();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* modal end here */}
              </div>
            </div>}
            <div className="allTicket__rightBtns">
              <ExportExcelFile csvData={tickets} fileName="Tickets" />
              {/* <button>export</button> */}
            </div>
          </div>

          {/* Table */}
       {loader ? <div style={{width:'100%',padding:'14% 48%' ,justifyContent:'center',alignContent:'center'}}>
        <Loader
        type="Puff"
        color="#C71800"
        height={'100%'}
        width={"100%"}
        // timeout={3000} //3 secs
      /> 
      </div>
        :    <div className="allTicket__table">
            {/* Table Heading */}
            <div className="allTicket__tableHeading">
              <span>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setIsChecked((previousValue) => {
                      previousValue = new Array(tickets.length);
                      previousValue.fill(e.target.checked);
                      return previousValue;
                    });
                  }}
                />
              </span>
              <span>T</span>
              <span>Ticket ID</span>
              <span>Summary</span>
              <span>Requested By</span>
              <span>Assignee</span>
              <span>Status</span>
              <span>Priority</span>
              <span>Created</span>
            </div>

            {/* Table Data */}
            {tickets?.map((ticket, index) => (
              <div
                key={ticket._id}
                className={`allTicket__tableData ${isChecked[index] && "selected"
                  }`}
              >
                {/* {console.log('ticket single', ticket)} */}
                <span>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEditTicket(ticket);
                      setIsChecked((isChecked) => {
                        let c = [...isChecked];
                        c[index] = e.target.checked;
                        return c;
                      });
                    }}
                    checked={isChecked[index]}
                  />
                </span>
                <FontAwesomeIcon
                  icon={
                    ticket.issueType.toLowerCase() === "repair"
                      ? faTools
                      : ticket.issueType.toLowerCase() === "replace"
                        ? faRedoAlt
                        : faShareAlt
                  }
                  className={`allTicket__icon ${ticket.issueType.toLowerCase()}`}
                />
                <span>{ticket.ticketID}</span>
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch({
                      type: "SINGLE_TICKET",
                      ticket: ticket,
                    });

                    history.push("/viewTicket");
                  }}
                >
                  {ticket.faultDefinition}
                </span>
                <span>{ticket.requestedBy.employeeName}</span>
                <span>{ticket.assignTo.employeeName}</span>
                <span
                  className={`allTicket__status ${ticket.status.toLowerCase()}`}
                >
                  {ticket.status}
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={ticket.priority === "low" ? faArrowDown : faArrowUp}
                    className={`allTicket__icon ${ticket.priority.toLowerCase()}`}
                  />
                  {ticket.priority}
                </span>
                <span>{dateFormat(ticket.createdAt)}</span>
              </div>
            ))}
          </div> }

          {/* Pagination Section */}
          <div className="allTicket__pages">
            {previousPageShow && (
              <>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  {page - 1}
                </button>
              </>
            )}
            <button className="select">{page}</button>
            {nextPageShow && (
              <>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {page + 1}
                </button>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTicket;