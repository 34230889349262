import React, { useEffect, useState } from "react";
import "../Styles/AllSchedule.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import { useStateValue } from "../Functionalities/StateProvider";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import axios from "../Functionalities/axios";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { dateFormat } from "../Functionalities/dateFormat";
import Modal from "react-modal";
import Loader from "react-loader-spinner";

import ExportExcelFile from "../Components/ExportExcelFile";
import {
  useLocation
} from "react-router-dom";
function AllSchedule() {
  const [state, dispatch] = useStateValue();

  const history = useHistory(); // For Browser Routing
  const [schedules, setSchedules] = useState([]);
  const [editSchedule, setEditSchedules] = useState(null);
  // Dynamically fetching Machines
  const locationn = useLocation();
  const dataa = locationn?.state?.data;
  const [machineList, setMachineList] = useState([]);
  const [loader,setLoader] = useState(true)

  // Getting previously selected Factory and Location for Data Persistent
  const selectedFactoryDetails = localStorage
    .getItem("selected_factory")
    .split(",");
  const selectedLocationDetails = localStorage
    .getItem("selected_location")
    .split(",");

  // Sorting and Filtering variables
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [machine, setMachine] = useState(null);
  const [timeline, setTimeLine] = useState("");
  const [deleteTicket, setDeleteSchedule] = useState(false);

  // Getting account of schedules selected from schedule list
  const [isChecked, setIsChecked] = useState([]);

  const [page, setPage] = useState(1); // For Pagination
  const [previousPageShow, setPreviousPageShow] = useState(false); // For showing Previous Page Button
  const [nextPageShow, setNextPageShow] = useState(false); // For showing Next Page Button
  const limit = 10; // For Pagination
  const [filterBy, setFilterBy] = useState(null);
  const [status, setStatus] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  // Sorting Type List
  const sortList = [
    {
      value: 1,
      label: "All",
    },

    {
      value: 2,
      label: "Requested By",
    },
    {
      value: 3,
      label: "Assignee",
    },
  ];
  // Status List
  const statusList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "In-Progress",
      label: "In-Progress",
    },
    {
      value: "Completed",
      label: "Completed",
    },
  ];

  // Timeline List
  const timelineList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    {
      value: "last-three-months",
      label: "Last 3 Months",
    },
    {
      value: "this-year",
      label: "This Year",
    },
  ];
  const deleteTicketArray = () => {
    console.log(isChecked);
    console.log(schedules);
    schedules.map((item, key) => {
      return isChecked.map((checkk, checkKey) => {
        return checkKey == key && checkk == true
          ? deleteScheduleID(item.scheduleID)
          : null;
      });
    });
    getScheduless();
  };
  const getScheduless = async () => {
    // getEmployee(setEmployees, store, selectedFactoryDetails[0])
    console.log("getScheduless");
    setDeleteSchedule(false);
    setIsChecked([]);
    await getSchedules();
    history.push("/allSchedule");
  };
  const deleteScheduleID = (id) => {
    axios
      .delete(`schedules/${id}`)
      .then((response) => response.data)
      .then((data) => {
        //    console.log('data of delete employee',data)
        //    alert("Employee Deleted Successfully")
        //    history.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };
  // Dynamically getting Machines List
  useEffect(() => {
    const getMachines = async () => {
      await axios
        .get(`machines/${selectedLocationDetails[0]}`)
        .then((response) => {
          const mList = [];
          console.log("all machine", response.data.machines);
          mList.push({
            label: "All",
            value: "All",
          });
          response.data.machines.map((m) => {
            const singleMachine = {
              label: m.machineName,
              value: m.machineID,
            };

            mList.push(singleMachine);
          });
          console.log("mList", mList);
          setMachineList(mList);
          setMachine(mList[0]);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 400 ||
              error.response.status === 401 ||
              error.response.status === 404 ||
              error.response.status === 403 ||
              error.response.status === 500
            ) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Internal Server Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          } else if (error.request) {
            store.addNotification({
              title: "Error Occurred!",
              message: "Network Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            if (error.message) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else if (error.data) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Something went wrong",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          }
        });
    };

    getMachines();
    reSetAll();
  }, []);

  // Getting Schedules List
  useEffect(() => {
    getSchedules();

    if (dataa) {
      console.log('data comming from dashboard', dataa)

   
      if (dataa === 'AssignTo') {
        setFilterBy(sortList[2]);
        setSearchValue(state.employee.name);
        searchFuncV2("AssignTo")

      }
    }

  }, [page]);
  const searchFuncV2 = async (e) => {
    let haveFilterBy = filterBy;
    let haveDateTime = dateTime;
    let haveMachine = machine;
    let haveStatus = status;
    console.log("employee.isAdmin", state.employee.isAdmin);

    if (filterBy) {
      console.log("if", filterBy);
      if (filterBy.label === "All") {
        haveFilterBy = null;
      } else {
        haveFilterBy = filterBy.label;
      }
    }
    if (status) {
      console.log("if", status);
      if (status.label === "All") {
        haveStatus = null;
      } else {
        haveStatus = status.label;
      }
    }
    if (machine) {
      console.log("if", machine);
      if (machine.label === "All") {
        haveMachine = null;
      } else {
        haveMachine = machine.label;
      }
    }

    if (dateTime) {
      console.log("if", dateTime);
      if (dateTime.label === "All") {
        haveDateTime = null;
      } else {
        haveDateTime = dateTime.value;
      }
    }
    let search2 = state.employee.name
    if(e === "AssignTo"){
      haveFilterBy = 'Assignee'
    }
    console.log(
      "search inpunt",
      search2,
      "filterby",
      haveFilterBy,
      "haveDateTime",
      haveDateTime,
      "haveMachine",
      haveMachine,
      "haveStatus",
      haveStatus
    );

    await axios
      .get(
        `schedules/${selectedLocationDetails[0]}?search=${search2}&employee_type=${haveFilterBy}&status=${haveStatus}&machine_id=${haveMachine}&date_filter=${haveDateTime}`
      )
      .then((response) => response.data)
      .then((data) => {
        console.log("data get all schedule search", data);
        setSchedules(data.results);
        if (data.next) setNextPageShow(true);
        if (data.previous) setPreviousPageShow(true);
        // setTickets(response.data.results);
        // if (response.data.next) setNextPageShow(true);
        // if (response.data.previous) setPreviousPageShow(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  const getSchedules = async () => {
    await axios
      .get(
        `/schedules/${selectedLocationDetails[0]}?limit=${limit}&page=${page}`
      )
      .then((response) => response.data)
      .then((data) => {
        setLoader(false)

        setSchedules(data.results);
        if (data.next) setNextPageShow(true);
        if (data.previous) setPreviousPageShow(true);
      })
      .catch((error) => {
        setLoader(false)

        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  const searchFunc = async () => {
    let haveFilterBy = filterBy;
    let haveDateTime = dateTime;
    let haveMachine = machine;
    let haveStatus = status;
    console.log("employee.isAdmin", state.employee.isAdmin);

    if (filterBy) {
      console.log("if", filterBy);
      if (filterBy.label === "All") {
        haveFilterBy = null;
      } else {
        haveFilterBy = filterBy.label;
      }
    }
    if (status) {
      console.log("if", status);
      if (status.label === "All") {
        haveStatus = null;
      } else {
        haveStatus = status.label;
      }
    }
    if (machine) {
      console.log("if", machine);
      if (machine.label === "All") {
        haveMachine = null;
      } else {
        haveMachine = machine.label;
      }
    }

    if (dateTime) {
      console.log("if", dateTime);
      if (dateTime.label === "All") {
        haveDateTime = null;
      } else {
        haveDateTime = dateTime.value;
      }
    }
    console.log(
      "search inpunt",
      searchValue,
      "filterby",
      haveFilterBy,
      "haveDateTime",
      haveDateTime,
      "haveMachine",
      haveMachine,
      "haveStatus",
      haveStatus
    );

    await axios
      .get(
        `schedules/${selectedLocationDetails[0]}?search=${searchValue}&employee_type=${haveFilterBy}&status=${haveStatus}&machine_id=${haveMachine}&date_filter=${haveDateTime}`
      )
      .then((response) => response.data)
      .then((data) => {
        console.log("data get all schedule search", data);
        setSchedules(data.results);
        if (data.next) setNextPageShow(true);
        if (data.previous) setPreviousPageShow(true);
        // setTickets(response.data.results);
        // if (response.data.next) setNextPageShow(true);
        // if (response.data.previous) setPreviousPageShow(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  const reSetAll = () => {
    setDateTime(timelineList[0]);
    setMachine(machineList[0]);
    setStatus(statusList[0]);
    setFilterBy(sortList[0]);
    setSearchValue("");
  };

  return (
    <div className="allSchedule">
      {/* Header */}
      <DashboardHeader />
      <div className="allSchedule__mainBody">
        {/* Notifications */}
        <ReactNotification />

        {/* Sidebar */}
        <Sidebar />
        <div className="allSchedule__container">
          {/* Navigation Breadcrumb */}
          <div className="allSchedule__containerPath">
            {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / All
            Schedule
          </div>

          {/* Heading */}
          <h1>All Schedules</h1>

          {/* Input Fields */}
          <div className="allSchedule__inputFields">
            <div className="row widthRoww">
              <div className="col-md-4 col-sm-12 col-xs-12">
                {" "}
                <input
                  className="searchBoxx heightOfInputBox"
                  // size={45}
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Select
                  options={sortList}
                  placeholder="Filter By"
                  className="allSchedule__select sortMarginTopShedule"
                  defaultValue={sortList[0]}
                  value={filterBy}
                  onChange={(e) => setFilterBy(e)}
                />
              </div>
              <div
                className="col-md-2 col-sm-12 col-xs-12 "
                style={{ marginTop: 10 }}
              >
                <Select
                  options={statusList}
                  placeholder="Status"
                  value={status}
                  defaultValue={statusList[0]}
                  className="allTicket__select marginTopCol"
                  onChange={(e) => setStatus(e)}
                />
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12">
                <Select
                  options={machineList}
                  placeholder="Machines"
                  className="allSchedule__select"
                  value={machine}
                  onChange={(e) => setMachine(e)}
                />
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12">
                <Select
                  options={timelineList}
                  placeholder="Date"
                  className="allSchedule__select"
                  defaultValue={timelineList[0]}
                  value={dateTime}
                  onChange={(e) => setDateTime(e)}
                />
              </div>
              <div
                className="col-md-1 col-sm-12 col-xs-12"
                style={{ marginTop: "10px" }}
              >
                {" "}
                <button
                  style={{ marginBottom: 10 }}
                  className="heightOfInputBox"
                  onClick={() => searchFunc()}
                >
                  Search
                </button>
              </div>
              <div
                className="col-md-1 col-sm-12 col-xs-12"
                style={{ marginTop: "10px" }}
              >
                <button
                  style={{ marginBottom: 10 }}
                  className="heightOfInputBox"
                  onClick={() => reSetAll()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="allSchedule__buttons">
            {state.employee.isAdmin && <div className="allSchedule__leftBtns">
              <button
                onClick={() => {
                  history.push({
                    pathname: "/updateSchedule",
                    state: { data: editSchedule },
                  });
                }}
              >
                <FontAwesomeIcon className="allSchedule__icon" icon={faEdit} />
                edit
              </button>
              <button
                onClick={() => {
                  setDeleteSchedule(true);
                }}
              >
                <FontAwesomeIcon
                  className="allSchedule__icon"
                  icon={faTrashAlt}
                />
                delete
              </button>
            </div>}{" "}
            {/* modal start here  */}
            <Modal
              className="delete__modal"
              isOpen={deleteTicket}
              closeTimeoutMS={20}
              shouldCloseOnOverlayClick={true}
            >
              <div style={{ width: "100%" }}>
                <h4>do you want to delete this Tickets?</h4>
                <div className="row rowWidth">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    {" "}
                    <button onClick={() => setDeleteSchedule(false)}>
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <button
                      onClick={() => {
                        deleteTicketArray();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* modal end here */}
            <div className="allSchedule__rightBtns">
              <ExportExcelFile csvData={schedules} fileName="Schedules" />
              {/* <button>export</button> */}
            </div>
          </div>

          {/* Table */}
          {loader ?  
            <div style={{width:'100%',padding:'14% 48%' ,justifyContent:'center',alignContent:'center'}}>
        <Loader
        type="Puff"
        color="#C71800"
        height={'100%'}
        width={"100%"}
        // timeout={3000} //3 secs
      /> 
      </div>
          :
          <div className="allSchedule__table">
            {/* TableHeading */}
            <div className="allSchedule__tableHeading">
              <span>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setIsChecked((previousValue) => {
                      previousValue = new Array(schedules.length);
                      previousValue.fill(e.target.checked);
                      return previousValue;
                    });
                  }}
                />
              </span>
              <span>Schedule</span>
              <span>Machine</span>
              <span>Summary</span>
              <span>Requested By</span>
              <span>Tasks</span>
              <span>Status</span>
              <span>DOM</span>
            </div>

            {/* Table Data */}
            {schedules?.map((schedule, index) => (
              <div
                key={schedule._id}
                className={`allSchedule__tableData ${isChecked[index] && "selected"
                  }`}
              >
                <span>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEditSchedules(schedule);
                      setIsChecked((isChecked) => {
                        let c = [...isChecked];
                        c[index] = e.target.checked;
                        return c;
                      });
                    }}
                    checked={isChecked[index]}
                  />
                </span>
                <span>{schedule.scheduleID}</span>
                <span>{schedule.machine.machineName}</span>
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch({
                      type: "SINGLE_SCHEDULE",
                      schedule: schedule,
                    });

                    history.push("/viewSchedule");
                  }}
                >
                  {schedule.scheduleSummary}
                </span>
                <span>{schedule.requestedBy.requestedByName}</span>
                <span>{schedule.taskID.length}</span>
                <span
                  className={`allSchedule__status ${schedule.status.toLowerCase()}`}
                >
                  {schedule.status}
                </span>
                <span>{dateFormat(schedule.createdAt)}</span>
              </div>
            ))}
          </div>
            }
         

          {/* Pagination Section */}
          <div className="allSchedule__pages">
            {previousPageShow && (
              <>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  1
                </button>
              </>
            )}
            <button className="select">{page}</button>
            {nextPageShow && (
              <>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {page + 1}
                </button>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllSchedule;