import React, { useEffect, useState } from 'react'
import '../Styles/ViewSchedule.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import TaskComment from '../Components/TaskComment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowUp, faChevronLeft, faChevronRight, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'
import repairImage from '../Assets/repair 4.png'
import Editor from '../Components/Editor'
import Select from 'react-select'
import axios from '../Functionalities/axios'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { dateFormat } from '../Functionalities/dateFormat'
import { timeFormat } from '../Functionalities/timeFormat'
import { useStateValue } from '../Functionalities/StateProvider'
import { randomHexColor } from 'random-hex-color-generator'
import assemblyLogo from '../Assets/assembly-location-logo 2.png'
import { Avatar } from '@material-ui/core'
import Modal from 'react-modal'


function ViewSchedule() {

    const history = useHistory()
    const [{ employee }, dispatch] = useStateValue()
    const [taskList, setTaskList] = useState([])
    const [status, setStatus] = useState(null)
    const [comment, setComment] = useState('')
    const [postComment, setPostComment] = useState('')
    const [cancelComment, setCancelComment] = useState(false)
    const [singleTaskModal, setSingleTaskModal] = useState(false)// for modal to delete single task
    const [editSchedule, setEditSchedule] = useState(null)
    const [singleTaskDelete, setSingleTaskDelete] = useState(null)// to store id of delete task

    const [deleteSchedule, setDeleteSchedule] = useState(false)

    // Getting previously created or selected Ticket for Data Persistency
    const selectedScheduleDetails = JSON.parse(localStorage.getItem('single-schedule'))
    useEffect(() => {
        setEditSchedule(selectedScheduleDetails)
    }, [])
    // Status List Options
    const statusList = [
        {
            value: 1,
            label: 'Open'
        },
        {
            value: 2,
            label: 'In-Progress'
        },
        {
            value: 3,
            label: 'Completed'
        }
    ]

    // Previous Page Navigation
    const backNavigation = e => {
        e.preventDefault()

        history.goBack()
    }

    // Update Comment
    const updateComment = (message) => {
        setComment(message)
    }

    //delete task
    const deleteTask = (id) => {
        setSingleTaskModal(true)
        setSingleTaskDelete(id)
    }
    const taskStatus = async (e, id) => {
        console.log('taskStatus', e, id)

        await axios
            .patch(`tasks/${id}`, {
                "status": e.label,
            })
            .then(response => {
                console.log('reload')
                store.addNotification({
                    title: "Task status Changed successfully!",
                    message: `Your status - ${e.label}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
                window.location.reload()  
                                        
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!1",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!2",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!3",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            })




    }
    const deleteTaskByID = () => {

        axios
            .delete(`tasks/${singleTaskDelete}`)
            .then(response => response.data)
            .then(data => {
                //    console.log('data of delete employee',data)
                //    alert("Employee Deleted Successfully")
                setSingleTaskDelete(null)
                //    history.goBack();
                selectedScheduleDetails.taskID.forEach(taskID => getTaskList(taskID))
            }).catch(error => {
                alert(error)
            })
        setSingleTaskModal(false)

    }
    const deleteScheduleID = (id) => {

        axios
            .delete(`schedules/${id}`)
            .then(response => response.data)
            .then(data => {
                //    console.log('data of delete employee',data)
                //    alert("Employee Deleted Successfully")
                history.goBack();

            }).catch(error => {
                alert(error)
            })

    }
    // Upload Task Comment
    const uploadTaskComment = async (commentMessage, taskObjectID) => {
        await axios
            .post('/taskComments/new', {
                "commentedByID": employee.employeeID,

                "commentedByName": employee.name,
                "commentMessage": commentMessage,
                "taskObjectID": taskObjectID
            }).then(response => response.data)
            .then(data => {
                store.addNotification({
                    title: "Task Comment added successfully!",
                    message: `Your comment - ${data.commentMessage}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
                setComment('')
                window.location.reload()
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }

    // Fetching Tasks by ID
    const getTaskList = async (taskID) => {
        await axios
            .get(`/tasks/${taskID}`)
            .then(response => response.data)
            .then(data => {
                setTaskList(previousTaskList => [...previousTaskList, data].sort())
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }

    // Getting Tasks according to there ID
    useEffect(() => {
        selectedScheduleDetails.taskID.forEach(taskID => getTaskList(taskID))
    }, [])

    const saveStatus = () => {
        console.log("status", status)

        axios
            .patch(`schedules/${selectedScheduleDetails.scheduleID}`, {
                "status": status,
                // "employeeID":employee.employeeID
            }).then(response => {
                store.addNotification({
                    title: "Status Updated!",
                    message: `${status} - status is changed.`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
                // history.push('/allSchedule');
                // setStatus('')
            })
            .catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 409) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Location Name exist.',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 500) {
                        if (error.response.data.name === 'MongoError') {
                            if (error.response.data.keyPattern.locationID) {
                                const duplicate = error.response.data.keyPattern.locationID
                                console.log(duplicate);
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: `Location ID exist previously`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else if (error.response.data.keyPattern.locationName) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Location Name exist.',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
    return (
        <div className='viewSchedule'>

            {/* Header */}
            <DashboardHeader />
            <div className="viewSchedule__mainBody">

                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />

                {/* Main Container */}
                <div className="viewSchedule__container">
                    <div className="viewSchedule__nav">


                        <div className="row" style={{ width: '68%' }}>
                            <div className="col-md-2">
                                <div className="viewSchedule__back" onClick={backNavigation}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                    <span>Back</span>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="viewSchedule__ticketID">
                                    <img src={repairImage} />
                                    <span>{selectedScheduleDetails.scheduleID}</span>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div style={{ textAlign: 'right' }} >
                                    <div className="row">
                                        {employee.isAdmin && <div className="col-md-6 col-sm-12 col-xs-12"> <button className="mobBottom" style={{ backgroundColor: '#C4C4C4', color: '#000' }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: '/updateSchedule',
                                                    state: { data: editSchedule }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className='allTicket__icon' icon={faEdit} style={{ color: '#C31A1A' }} />
                                            Edit
                                        </button></div>}
                                        {employee.isAdmin && <div className="col-md-4 col-sm-12 col-xs-12"><button style={{ backgroundColor: '#C4C4C4', color: '#000' }} onClick={() => {
                                            setDeleteSchedule(true)
                                        }}
                                            className="alignDeleteButton"
                                        >
                                            <FontAwesomeIcon className='allTicket__icon' icon={faTrashAlt} style={{ color: '#C31A1A' }} />
                                            Delete
                                        </button></div>}
                                        {/* modal start here  */}
                                        <Modal
                                            className='delete__modal'
                                            isOpen={deleteSchedule}
                                            closeTimeoutMS={20}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div style={{ width: '100%' }}>
                                                <h4>do you want to delete this Schedule?</h4>
                                                <div className="row rowWidth">
                                                    <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={() => setDeleteSchedule(false)}>Cancel</button></div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={() => {
                                                        deleteScheduleID(selectedScheduleDetails.scheduleID);
                                                    }}>Delete</button></div>

                                                </div>
                                            </div>

                                        </Modal>
                                        {/* modal end here */}

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="viewSchedule__sections">

                        {/* Middle Section */}
                        <div className="viewSchedule__middleSection">
                            <h1>{selectedScheduleDetails.scheduleSummary}</h1>
                            <p>
                                {selectedScheduleDetails.requestedBy.requestedByName} {' '}
                                <span>
                                    created this schedule for {' '}
                                    {dateFormat(selectedScheduleDetails.date)}
                                </span>
                            </p>

                            {/* Task List */}
                            {
                                taskList?.map(task => (
                                    <div key={task._id} className="viewSchedule__ticket">
                                        <div className="viewSchedule__metaData">
                                            <div className="viewSchedule__leftHeader">
                                                <p>Task Name: {task.taskName}</p>
                                            </div>
                                            <div className="viewSchedule__rightHeader">
                                                <Select
                                                    options={statusList}
                                                    placeholder='Mark as'
                                                    className='viewSchedule__select'
                                                    onChange={e => taskStatus(e, task._id)}
                                                />
                                                <span
                                                    className={`viewSchedule__status ${task.status?.toLowerCase()}`}
                                                >
                                                    {task.status}
                                                </span>
                                            </div>
                                        </div>
                                        <p>Start Date and Time: {dateFormat(task.taskStartTime)}, {timeFormat(task.taskStartTime)} </p>
                                        <p>End Date and Time: {dateFormat(task.taskEndTime)}, {timeFormat(task.taskEndTime)}</p>
                                        <p>Description</p>
                                        <p className='viewSchedule__description'>
                                            {task.taskDescription}
                                        </p>

                                        <p>Comments</p>
                                        <span className='viewSchedule__subject'>Remark</span>
                                        <div className="viewSchedule__editor">
                                            {/* <Editor
                                                updateComment={updateComment}
                                            // postComment={postComment}
                                            /> */}
                                            <textarea
                  className="addTicket__textarea"
                  placeholder="Add Comment"
                  style={{ width: "100%", minHeight: 180 }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                                        </div>
                                        <div className="viewSchedule__buttons">
                                            <button
                                                onClick={() => {
                                                    uploadTaskComment(comment, task._id)
                                                    setPostComment('Commented')
                                                }}
                                            >
                                                post comment
                                            </button>
                                            {/* <span style={{marginLeft:10,color:'#cd000c',textDecorationLine:'underline'}}
                                                onClick={() => {  deleteTask(task._id)  }}
                                            >
                                                delete
                                            </span> */}
                                            {/* modal start here  */}
                                            <Modal
                                                className='delete__modal'
                                                isOpen={singleTaskModal}
                                                closeTimeoutMS={20}
                                                shouldCloseOnOverlayClick={true}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    <h4>do you want to delete this task?</h4>
                                                    <div className="row rowWidth">
                                                        <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={() => setSingleTaskModal(false)}>Cancel</button></div>
                                                        <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={() => {
                                                            deleteTaskByID();
                                                        }}>Delete</button></div>

                                                    </div>
                                                </div>

                                            </Modal>
                                            {/* modal end here */}
                                        </div>
                                        <div className="viewSchedule__comments">
                                            <TaskComment
                                                postComment={postComment}
                                                taskObjectID={task._id}
                                                store={store}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {/* Right Section Schedule MetaData */}
                        <div className="viewSchedule__details">
                            <div className="viewSchedule__ticketStatus">
                            {status ?      <span className={`viewSchedule__status ${status?.toLowerCase()}`}>
                                    {status}
                                </span> :      <span className={`viewSchedule__status ${selectedScheduleDetails.status?.toLowerCase()}`}>
                                    {selectedScheduleDetails.status}
                                </span> }
                           
                                <Select
                                    options={statusList}
                                    placeholder='Mark as'
                                    className='viewSchedule__select'
                                    onChange={e => {
                                        setStatus(e.label)
                                    }}
                                />
                                <button onClick={() => {
                                    saveStatus()
                                }}>save</button>
                            </div>

                            <div className="viewSchedule__infoGroup">
                                <label>assignee</label>
                                <span>
                                    <Avatar
                                        src={`https://ui-avatars.com/api/?name=${selectedScheduleDetails.assignedTo.assignedToName}&background=${randomHexColor().substring(1)}&color=${randomHexColor().substring(1)}`}
                                    />
                                    {selectedScheduleDetails.assignedTo.assignedToName}
                                </span>
                            </div>
                            <div className="viewSchedule__infoGroup">
                                <label>requested by</label>
                                {console.log(selectedScheduleDetails)}
                                <span>
                                    <Avatar
                                        src={`https://ui-avatars.com/api/?name=${selectedScheduleDetails.assignedTo.assignedToName}&background=${randomHexColor().substring(1)}&color=${randomHexColor().substring(1)}`}
                                    />
                                    {selectedScheduleDetails.requestedBy.requestedByName}
                                </span>
                            </div>
                            <div className="viewSchedule__infoGroup">
                                <label>machine</label>
                                <span>
                                    
                                {selectedScheduleDetails.machine.machineName}

                                </span>
                            </div>
                            {/* <div className="viewSchedule__infoGroup">
                                <label>Schedule period</label>
                                <span style={{ textTransform: 'none' }}>
                                    Repeat {' '}
                                    {selectedScheduleDetails.repeatCycle} {' '}
                                    times every {' '}
                                    {selectedScheduleDetails.repeatAmount} {' '}
                                    {selectedScheduleDetails.repeatInterval}
                                </span>
                            </div> */}
                            <div className="viewSchedule__infoGroup">
                                <label>location</label>
                                <span>
                                    <img src={assemblyLogo} alt="" />
                                    {selectedScheduleDetails.location.locationName}
                                </span>
                            </div>
                            <div className="viewSchedule__infoGroup">
                                <label>machine / part</label>
                                <span>{selectedScheduleDetails.machine.machineName}</span>
                            </div>
                            <div className="viewSchedule__infoGroup">
                                <label>Date Of Maintenance</label>
                                <span>
                                    {dateFormat(selectedScheduleDetails.date)}
                                </span>
                            </div>
                            <div className="viewSchedule__infoGroup">
                                <label>Created On</label>
                                <span>
                                    {dateFormat(selectedScheduleDetails.createdAt)}
                                    {', '}
                                    {timeFormat(selectedScheduleDetails.createdAt)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewSchedule