import React from 'react'
import ReactApexChart from 'react-apexcharts'

function PieChart({ tickets }) {

    const high = tickets.filter((i) => i.priority === "High").length
    const medium = tickets.filter((i) => i.priority === "Medium").length
    const low = tickets.filter((i) => i.priority === "Low").length
    // console.log(low);

    // Amounts for Pie Chart
    const series = [high, medium, low]

    // Customizing Pie Chart
    const options = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['High', 'Medium', 'Low'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    // Actual Pie Chart
    return (
        <div
            className='pieChart'
            style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
                width={'100%'}
                height={'200px'}
            />
        </div>
    )
}

export default PieChart
