import React, { useEffect, useState } from 'react'
import '../Styles/AddSchedule.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import Select from "react-select"
import { useStateValue } from '../Functionalities/StateProvider'
import AddTaskFormOnSchedule from '../Components/AddTaskFormOnSchedule'
import axios from '../Functionalities/axios'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Loader from "react-loader-spinner";

import { useHistory } from 'react-router-dom'
import { getAssigneeList } from '../Functionalities/getMaintenancePersons'
import { getMachinesListByLocation } from '../Functionalities/getMachine'

function AddSchedule() {

    const [state, dispatch] = useStateValue()
    const [taskList, setTaskList] = useState([])


    // Dynamically fetching from server according to Location
    const [machineList, setMachineList] = useState([])
    const [assigneeList, setAssigneeList] = useState([])

    // Storing Task IDs on saving
    const [taskIDs, setTaskIDs] = useState([])

    const history = useHistory()

    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')
    const [loader,setLoader] = useState(false)

    // Input fields for Schedule Form
    const [scheduleDate, setScheduleDate] = useState('')
    const [scheduleID, setScheduleID] = useState('')
    const [repeatAmount, setRepeatAmount] = useState(0)
    const [repeatCycle, setRepeatCycle] = useState(0)
    const [repeatInterval, setRepeatInterval] = useState('days')
    const [machine, setMachine] = useState({})
    const [assignedTo, setAssignedTo] = useState({})
    const [scheduleSummary, setScheduleSummary] = useState('')

    // Dynamically getting Machines List
    useEffect(() => {
        getMachinesListByLocation(setMachineList, store, selectedLocationDetails[0])
    }, [])

    // Fetching Maintenance Personnel and Maintenance Manager List
    useEffect(() => {
        getAssigneeList(setAssigneeList, store, selectedLocationDetails[0])
    }, [])

    const periodList = [
        {
            value: 1,
            label: 'Days'
        },
        {
            value: 2,
            label: 'Weeks'
        },
        {
            value: 3,
            label: 'Months'
        },
        {
            value: 4,
            label: 'Years'
        }
    ]

    const addTask = () => {
        // e.preventDefault()
console.log('lenght of lastlist',state.taskList.length)
        // setTaskList(taskList.concat(
        //     <AddTaskFormOnSchedule
        //         key={taskList.length}
        //         index={taskList.length}
        //         removeTask={removeTask}
        //         store={store}
        //         setTaskIDs={setTaskIDs}
        //     />
        // ))

        dispatch({
            type: 'taskList',
            taskList: state.taskList.concat(
                <AddTaskFormOnSchedule
                    key={state.taskList.length - 1}
                    index={state.taskList.length}
                    removeTask={removeTask}
                    store={store}
                    setTaskIDs={setTaskIDs}
                />
            )
        })

      
    }

    const removeTask = async (index) => {
        // index.preventDefault();
        // console.log('TaskList Before -> ', taskList.length);
        // let newTaskList = taskList
        // newTaskList.splice(index, 1)
        // console.log(newTaskList);
        // console.log('TaskList After -> ', newTaskList.length);
        // console.log(index)
        // setTaskList(newTaskList)

    }

    const addSchedule = async () => {
setLoader(true)
        if (
            scheduleDate !== '' &&
            // scheduleID !== '' &&
            machine !== {} &&
            assignedTo !== {} &&
            scheduleSummary !== '' &&
            repeatAmount >= 0 &&
            repeatCycle >= 0 &&
            taskIDs.length > 0
        ) {
            await axios
                .post('/schedules/new', {
                    // "scheduleID": scheduleID,
                    "date": new Date(scheduleDate).toISOString(),
                    "taskID": taskIDs,
                    "repeatAmount": Number(1),
                    "repeatCycle": Number(1),
                    "repeatInterval": repeatInterval,
                    "requestedBy": {
                        "requestedByID": state.employee.employeeID,
                        "requestedByName": state.employee.name
                    },
                    "location": {
                        "locationID": selectedLocationDetails[0],
                        "locationName": selectedLocationDetails[1]
                    },
                    "machine": machine,
                    "assignedTo": assignedTo,
                    "status": "Open",
                    "scheduleSummary": scheduleSummary
                })
                .then(response => {
                    setLoader(false)
                    store.addNotification({
                        title: "Ticket Created!",
                        message: `Your Schedule ID is ${response.data.scheduleID}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })

                    dispatch({
                        type: 'SINGLE_SCHEDULE',
                        schedule: response.data
                    })

                    history.push('/viewSchedule')
                }).then(() => {
                    // setScheduleID('')//
                    setScheduleSummary('')
                    setRepeatAmount(0)
                    setLoader(false)
                    setRepeatCycle(0)
                    setTaskList([])
                }).catch(error => {
                    setLoader(false)
                    // alert(error.response.status)
                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                    // alert(error.response.data)

                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else {
            if (scheduleDate === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select a Date.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (machine === {}) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select a Machine.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (assignedTo === {}) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select some to assign Schedule.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (scheduleSummary === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter Schedule summary.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (repeatAmount < 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter correct number repeat amount.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (repeatCycle < 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter correct number of repeat cycle.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (taskIDs.length === 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, upload a task first.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            }
        }
    }

    return (
        <div className='addSchedule'>

            {/* Header */}
            <DashboardHeader />

            <div className="addSchedule__mainBody">

                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />

                <div className="addSchedule__container">
                    <div className="addSchedule__containerPath">
                        {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / Create Schedule
                    </div>
                    <h1>Create Schedule</h1>
                    <div className="addSchedule__form">
                   
                        <div className="addSchedule__input">
                        <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'><label>Date</label></div>
                        <div className='col-md-5 col-xs-12 col-sm-12'><input
                                type="date"
                                className="heightOfInputBox"
                                value={scheduleDate}
                                onChange={e => setScheduleDate(e.target.value)}
                            /></div>
                        <div className='col-md-3 col-xs-12 col-sm-12'> 
                        {/* <label className='addSchedule__ticketNo'>Schedule ID</label> */}
                        </div>
                        <div className='col-md-1 col-xs-12 col-sm-12'>
                         {/* <input type="text" className="heightOfInputBox" value={scheduleID} onChange={e => setScheduleID(e.target.value)} /> */}
                         </div>


                    </div>
                            
                            
                           
                           
                        </div>
                        <div className="addSchedule__input">
                        {/* <div className="row rowWidth">
                        <div className='col-md-2 col-xs-12 col-sm-12' style={{paddingLeft:0}}><label>Schedule Period</label></div>
                        <div className='col-md-2 col-xs-12 col-sm-12'style={{paddingLeft:0}}><label>Repeat in</label></div>
                        <div className='col-md-2 col-xs-12 col-sm-12'style={{paddingLeft:0}}> <input
                                type="text"
                                size={100}
                                placeholder='1'
                                value={repeatAmount}
                                onChange={e => setRepeatAmount(e.target.value)}
                                disabled
                            /></div>
                        <div className='col-md-2 col-xs-12 col-sm-12'style={{paddingLeft:0}}> <Select
                                options={periodList}
                                placeholder='Days'
                                onChange={e => setRepeatInterval(e.label.toLowerCase())}
                            /></div>
                        <div className='col-md-2 col-xs-12 col-sm-12'style={{paddingLeft:0}}><label>Repeat Cycle</label></div>
                        <div className='col-md-2 col-xs-12 col-sm-12'style={{paddingLeft:0}}><input
                                type="text"
                                placeholder='1'
                                value={repeatCycle}
                                onChange={e => setRepeatCycle(e.target.value)}
                                disabled
                            /></div>


                    </div> */}
                            
                            
                           
                           
                            
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'><label>Requested By</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'><input
                                type="text"
                                placeholder={state.employee.name}
                                className="heightOfInputBox"
                                disabled
                            /></div>

                    </div>
                            
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'><label>Location</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'> <input  className="heightOfInputBox" type="text" placeholder={selectedLocationDetails[1]} disabled /></div>

                    </div>
                            
                           
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'> <label>Machine / Part</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'><Select
                                options={machineList}
                                placeholder='Select'
                                className='addSchedule__select'
                                onChange={
                                    e => setMachine({
                                        "machineID": e.value,
                                        "machineName": e.label
                                    })
                                }
                            /></div>

                    </div>
                           
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'><label>Assignee To</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'><Select
                                options={assigneeList}
                                placeholder='Select'
                                className='addSchedule__select'
                                // defaultValue={assignedTo.assignedToName === {} ? assignedTo.assignedToName : 'Select Assignee'}
                                onChange={
                                    e => setAssignedTo({
                                        "assignedToID": e.value,
                                        "assignedToName": e.label
                                    })
                                }
                            /></div>

                    </div>
                            
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'><label>Schedule Summary</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'><textarea
                                type="text"
                                style={{minHeight:90,width:'100%'}}
                                placeholder='Add Preventive Maintenance Schedule Summary'
                                value={scheduleSummary}
                                onChange={e => setScheduleSummary(e.target.value)}
                            /></div>

                    </div>
                            
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'> <label>Activity Details</label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'><div className="addSchedule__addTask">
                                <button onClick={addTask}>Add Task</button>
                                {
                                    state.taskList.map((task, index) => (
                                        task
                                    ))
                                }
                                {/* {state.taskList?.length > 1 ? <button onClick={addTask}>Add Task</button> : null } */}
                            </div></div>

                    </div>
                           
                            
                        </div>
                        <div className="addSchedule__input">
                         <div className="row rowWidth">
                        <div className='col-md-3 col-xs-12 col-sm-12'> <label></label></div>
                        <div className='col-md-9 col-xs-12 col-sm-12'> 
                        
                        {loader ? 
                            <Loader
        type="Puff"
        color="#C71800"
        height={50}
        width={50}
        // timeout={3000} //3 secs
      />  :
                            <button
                                className="addSchedule__btns"
                                onClick={addSchedule}
                            >
                                Create Schedule
                            </button>
                         }

                           
                            <button
                                className="addSchedule__btns"
                                onClick={
                                    () => {
                                        setScheduleID('')
                                        setScheduleSummary('')
                                        setRepeatAmount(0)
                                        setRepeatCycle(0)
                                        setAssignedTo({})
                                    }
                                }
                            >
                                Cancel
                            </button></div>

                    </div>
                           
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSchedule