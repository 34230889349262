import React, { useEffect, useRef, useState } from "react";
import "../Styles/AddEmployeeInfo.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import noProfilePicture from "../Assets/manicon 6.png";
import plusPicture from "../Assets/+.png";
import Select from "react-select";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import axios from "../Functionalities/axios";
import { useStateValue } from "../Functionalities/StateProvider";
import { getLocationsListByFactory } from "../Functionalities/getLocation";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase";
import { Height } from "@material-ui/icons";
import Mimage from "../Assets/22.png";
function AddEmployeeInfo() {
  const [state, dispatch] = useStateValue();
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [name, setName] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [location, setLocation] = useState({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [maintenanceType, setMaintenanceType] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [pushToDB, setPushToDB] = useState(false);
  const [image, setImage] = useState(null);
  // For Selecting Location Options
  const [locationList, setLocationList] = useState([]);

  // Getting previously selected Factory and Location for Data Persistent
  const selectedFactoryDetails = localStorage
    .getItem("selected_factory")
    .split(",");
  const selectedLocationDetails = localStorage
    .getItem("selected_location")
    .split(",");

  // Maintenance Type
  const maintenanceTypeList = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "Maintenance Personnel",
      label: "Maintenance Personnel",
    },
    {
      value: "Maintenance Manager",
      label: "Maintenance Manager",
    },
  ];

  // For referring the uploaded image
  const file = useRef();
  // Location List
  // Previewing Image on upload
  useEffect(() => {
    getLocationsListByFactory(
      setLocationList,
      store,
      selectedFactoryDetails[0]
    );
    // if (profilePicture) {
    //   setImage(profilePicture);
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreviewImage(reader.result);
    //   };
    //   reader.readAsDataURL(profilePicture);
    // } else {
    //   setPreviewImage(null);
    //   // setImage(null);
    // }
  }, []);

  // Validating different input fields
  const nameValidate = (e) => {
    if (name.length > 60) {
      store.addNotification({
        title: "Invalid Input",
        message: "Name is too long!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }

    setName(e.target.value);
  };

  const employeeIDValidate = (e) => {
    setEmployeeID(e.target.value);

    if (employeeID.length > 7) {
      store.addNotification({
        title: "Invalid Input",
        message: "Employee ID must be less than 7!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };

  const emailValidate = (e) => {
    setEmail(e.target.value);

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (!validEmailRegex.test(email)) {
      store.addNotification({
        title: "Invalid Input",
        message: "Enter a valid email id!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else {
      store.addNotification({
        title: "goood",
        message: "valid email id",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };

  const phoneValidate = (e) => {
    e.preventDefault();

    setPhone(e.target.value);
    if (phone.length < 10) {
      store.addNotification({
        title: "Invalid Input",
        message: "Phone number is less than 10 number!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else {
      store.addNotification({
        title: "goood",
        message: "valid Phone number",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };
  const passwordValidate = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
    const regex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,18}$/
    );
    console.log(regex.test(confirmPassword), "validation confirm password"); // true

    if (!regex.test(confirmPassword)) {
      store.addNotification({
        title: "Invalid Input",
        message:
          "Minimum 6 and maximum 18 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else {
      if (confirmPassword === password) {
        store.addNotification({
          title: "goood",
          message: "valid password",
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      } else {
        setPasswordStatus(false);
        store.addNotification({
          title: "Password not match",
          message: "confirm Password is not matching your password",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
    }
  };
  const confirmPasswordValidte = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
    const regex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,18}$/
    );
    console.log(regex.test(confirmPassword), "validation confirm password"); // true

    if (!regex.test(confirmPassword)) {
      store.addNotification({
        title: "Invalid Input",
        message:
          "Minimum 6 and maximum 18 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } else {
      if (confirmPassword === password) {
        store.addNotification({
          title: "goood",
          message: "valid password",
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      } else {
        setPasswordStatus(false);
        store.addNotification({
          title: "Password not match",
          message: "confirm Password is not matching your password",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
    }
  };

  // Adding Employee to Database
  const createEmployee = (e) => {
    e.preventDefault();
    console.log(
      "name",
      name,
      "employeeID",
      employeeID,
      "location",
      location,
      "phone",
      phone,
      "email",
      email,
      "maintenanceType",
      maintenanceType,
      "password",
      password,
      "confirmPassword",
      confirmPassword,
      "selectedFactoryDetails[0]",
      selectedFactoryDetails[0],
      "selectedFactoryDetails[1]",
      selectedFactoryDetails[1]
    );
    console.log("image of employee defalut", image);

    if (
      name &&
      employeeID &&
      location.length != 0 &&
      phone.length >= 10 &&
      email &&
      maintenanceType &&
      password &&
      confirmPassword === password &&
      setPasswordStatus
    ) {
      if (image !== null) {
        console.log("all goood");
        console.log(image);
        console.log("deployrun with image");
        var metadata = {
          contentType: "image/jpeg",
        };
        const imageName = uuidv4();
        const uploadTask = storage
          .ref(`employee/${imageName}${image.name}`)
          .put(image, metadata);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
          },
          (err) => {},
          () => {
            storage
              .ref("employee")
              .child(`${imageName}${image.name}`)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                console.log("fireBaseUrl", fireBaseUrl);
                // setImageUrl(fireBaseUrl);
                deploy(fireBaseUrl);
                setPushToDB(true);
              });
          }
        );
      } else {
        deploy(
          "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png"
        );
        console.log(
          "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png"
        );
      }
    } else {
      store.addNotification({
        title: "Error Occurred!",
        message: "Please fill the required fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      console.log(
        "something is missing",
        name,
        "name",
        employeeID,
        "employeeID",
        selectedFactoryDetails[0],
        "selectedFactoryDetails",
        selectedFactoryDetails[1],
        "selectedFactoryDetails",
        location,
        "location",
        phone,
        "phone",
        email,
        "email",
        password,
        "password",
        confirmPassword,
        "confirmPassword",
        maintenanceType,
        "maintenanceType"
      );
    }
  };
  const deploy = async (url) => {
    console.log("Loading: ", url);
    if (pushToDB || true) {
      console.log("Loading");
      store.addNotification({
        title: "Adding...",
        message: "Adding employee to database",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      await axios
        .post("employees", {
          name: name,
          employeeID: employeeID,
          factory: [
            {
              factoryID: selectedFactoryDetails[0],
              factoryName: selectedFactoryDetails[1],
            },
          ],
          location: location,
          phone: phone,
          email: {
            emailID: email,
          },
          password: password,
          confirmPassword: confirmPassword,
          profilePic: url,
          maintenanceType: maintenanceType,
        })
        .then((response) => {
          console.log("employee created response", response);
          store.addNotification({
            title: "New Account Created!",
            message: `Now ${name} can login.`,
            type: "success",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 400 ||
              error.response.status === 401 ||
              error.response.status === 404 ||
              error.response.status === 403 ||
              error.response.status === 500
            ) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data.error,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Internal Server Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          } else if (error.request) {
            store.addNotification({
              title: "Error Occurred!",
              message: "Network Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            if (error.message) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else if (error.data) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Something went wrong",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          }
        });
    }
  };
  // useEffect(async () => {

  // }, [pushToDB]);
  const locationChange = (e) => {
    let list = [];
    e.map((item) => {
      list.push({
        locationName: item.label,
        locationID: item.value,
      });
    });
    setLocation(list);
    console.log("Location: ", list);
  };
  // Add Employee Page
  return (
    <div className="addEmployeeInfo">
      {/* Header */}
      <DashboardHeader />
      <ReactNotification />
      <div className="addEmployeeInfo__mainBody">
        {/* Sidebar */}
        <Sidebar />

        {/* Add Employee Page */}
        <div className="addEmployeeInfo__container">
          {/* Navigation Breadcrumb */}
          <div className="addEmployeeInfo__containerPath">
            {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / Add
            Employees
          </div>

          {/* Heading */}
          <h1>Add Employee</h1>

          {/* Form */}
          <div className="addEmployeeInfo__form">
            <div className="row">
              <div className="col-md-4">
                {" "}
                {/* Photo Section */}
                <div className="addEmployeeInfo__photo">
                  <label>add photo</label>
                  <input
                    type="file"
                    id="real-file"
                    hidden="hidden"
                    accept="image/*"
                    ref={file}
                    onChange={(e) => {
                      const img = e.target.files[0];
                      if (img && img.type.substr(0, 5) === "image") {
                        setProfilePicture(img);
                      } else {
                        setProfilePicture(null);
                      }
                    }}
                  />
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      file.current.click();
                    }}
                    className="addEmployeeInfo__image"
                  >
                    <img
                      src={`${previewImage ? previewImage : noProfilePicture}`}
                      className="addEmployeeInfo__noProfilePicture"
                    />
                    {previewImage ? null : (
                      <img
                        src={plusPicture}
                        className="addEmployeeInfo__plusPicture"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {" "}
                {/* Form Section */}
                <div className="addEmployeeInfo__details">
                  <div className="addEmployeeInfo__innerForm">
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Name</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="First Name and Last Name"
                            className="heightOfInputBox"
                            value={name}
                            onChange={nameValidate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Employee ID</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="EMP0001"
                            className="heightOfInputBox"
                            value={employeeID}
                            onChange={employeeIDValidate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Factory</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="heightOfInputBox"
                            placeholder={selectedFactoryDetails[1]}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Location</label>
                        </div>
                        <div className="col-md-8">
                          <Select
                            options={locationList}
                            placeholder="Select location"
                            className="addEmployeeInfo__select"
                            isMulti
                            onChange={(e) => locationChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Phone No.</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="9876543210"
                            className="heightOfInputBox"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                            onBlur={phoneValidate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Email</label>
                        </div>
                        <div className="col-md-8">
                          {" "}
                          <input
                            type="text"
                            placeholder="abc@gmail.com"
                            className="heightOfInputBox"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            onBlur={emailValidate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>password</label>
                        </div>
                        <div className="col-md-8">
                          {" "}
                          <input
                            type="password"
                            placeholder="******"
                            className="heightOfInputBox"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            onBlur={passwordValidate}
                          />
                        </div>
                        <div>
                          {/* {
                                                    passwordStatus ? <p  style={{color:'red'}}>password must contain Minimum 6 and maximum 18 characters, at least one uppercase letter, one lowercase letter, one number and one special character*</p> : null
                                                } */}
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Confirm Password</label>
                        </div>
                        <div className="col-md-8">
                          {" "}
                          <input
                            type="password"
                            placeholder="******"
                            className="heightOfInputBox"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            onBlur={confirmPasswordValidte}
                          />
                        </div>
                        <div>
                          {/* {
                                                    confirmPasswordStatus ? <p  style={{color:'red'}}>password must contain Minimum 6 and maximum 18 characters, at least one uppercase letter, one lowercase letter, one number and one special character*</p> : null
                                                } */}
                        </div>
                      </div>
                    </div>
                    <div className="addEmployeeInfo__input">
                      <div className="row rowWidth">
                        <div className="col-md-4">
                          <label>Maintenance Type</label>
                        </div>
                        <div className="col-md-8">
                          <Select
                            options={maintenanceTypeList}
                            placeholder="Select type"
                            className="addEmployeeInfo__select"
                            onChange={(e) => setMaintenanceType(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="addEmployeeInfo__input"> */}
                    <div className="addLocation__buttons">
                      <div className="row rowWidth">
                        <div style={{ width: "50%" }}>
                          <button
                            style={{ left: "0%" }}
                            onClick={createEmployee}
                          >
                            Add
                          </button>
                        </div>
                        <div style={{ width: "50%" }}>
                          <button
                            className="addLocation__cancelButton buttonCancel"
                            style={{ left: "0%" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmployeeInfo;
