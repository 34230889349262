import firebase from "firebase";

const config = {
  apiKey: "AIzaSyB_oTZwmuiapUcyuFAsrfhN-LbH941QFfI",
  authDomain: "hawkins-incident-management.firebaseapp.com",
  projectId: "hawkins-incident-management",
  storageBucket: "hawkins-incident-management.appspot.com",
  messagingSenderId: "902299516282",
  appId: "1:902299516282:web:2d54f4d073c6026c5faac3",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(config);
const storage = firebaseApp.storage();
let messaging = new firebase.messaging();

async function getToken() {
  let token = await messaging.getToken({
    vapidKey:
      "BNT6kAsNlMwwZHpojRNBVwX7yTgOifm11dNZf2OlDWvX6WifGp-kOTgBgYAfyEO1Vms1RSDh3LGg7gL1gwVwQpg",
  });
  console.log(token);
  return token;
}
// getToken();
export { storage, messaging, getToken };
