import { IconButton } from '@material-ui/core'
import React, { useState,useEffect } from 'react'
import '../Styles/AddTaskForm.css'
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded'
import axios from '../Functionalities/axios'
import { DateFormatNumeric } from '../Functionalities/dateFormatNumeric'
import { TimeFormatTask } from '../Functionalities/timeFormatTask'
function UpdateTaskForm({ index, removeTask, store, setEditTaskIDs, editTaskListItem }) {

    const [taskName, setTaskName] = useState(editTaskListItem.taskName)
    const [taskDescription, setTaskDescription] = useState(editTaskListItem.taskDescription)
    const [taskDeadline, setTaskDeadline] = useState('')
    const [deadlineTime, setDeadlineTime] = useState('')
    const [taskStart, setTaskStart] = useState('')
    const [startTime, setStartTime] = useState('')

    const handleClick = e => {
        e.preventDefault()

        removeTask(index)
    }

    // Uploading Task
    const updateTask = async (id) => {
        console.log('taskName',taskName,taskDescription,'taskDescription',taskStart,'taskStart',startTime,'startTime',taskDeadline,'taskDeadline',deadlineTime,'deadlineTime')
        if (
            taskName && 
            taskDescription && 
            taskStart &&
            startTime &&
            taskDeadline &&
            deadlineTime
        ) {
            await axios
                .put(`tasks/${id}`, {
                    "taskName": taskName,
                    "taskDescription": taskDescription,
                    "status": "Open",
                    "taskStartTime": new Date(taskStart + ' ' + startTime).toISOString(),
                    "taskEndTime": new Date(taskDeadline + ' ' + deadlineTime).toISOString()
                }).then(response => response.data)
                .then(data => {
                    // setEditTaskIDs(editTaskIDs => [...editTaskIDs, data._id])
                    store.addNotification({
                        title: "Task Updated!",
                        message: `Task Name - ${taskName}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        if (
                            error.response.status === 500 && 
                            error.response.data !== undefined &&
                            error.response.data !== null
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Task exists already',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else if (taskName === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task Name',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskDescription === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task Description',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskStart === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task Start Date',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (startTime === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task Start Time',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (taskDeadline === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task End Date',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } else if (deadlineTime === '') {
            store.addNotification({
                title: "Cannot Update Task!",
                message: 'Enter Task End Time',
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        }
    }
useEffect(()=>{
 let reverseStartDate = DateFormatNumeric(editTaskListItem.taskStartTime).replace(/\/+/g,"-")
 setTaskStart(reverseStartDate.split("-").reverse().join("-"))

 // start time

 let reverseStartTime = TimeFormatTask(editTaskListItem.taskStartTime).replace(/\/+/g,"-")
 setStartTime(reverseStartTime.split("-").reverse().join("-"))

// task end time 
let reverseEndDate = DateFormatNumeric(editTaskListItem.taskEndTime).replace(/\/+/g,"-")
setTaskDeadline(reverseEndDate.split("-").reverse().join("-"))

// start time

let reverseEndTime = TimeFormatTask(editTaskListItem.taskEndTime).replace(/\/+/g,"-")
setDeadlineTime(reverseEndTime.split("-").reverse().join("-"))

},[])
    // Add Task Form in Create Schedule Page
    return (<div className='addTaskForm'>
    {
        console.log(editTaskListItem,'editTaskListItem')
        
    }
    {
        console.log(taskName,'taskName')

    }
                <div className="addTaskForm__inputGroup">
                    <label>task name</label>
                    <input type="text" value={taskName} className="heightOfInputBox" onChange={e => setTaskName(e.target.value)} />
                </div>
                <div className="addTaskForm__inputGroup">
                    <label>task description</label>
                    <textarea value={taskDescription} onChange={e => setTaskDescription(e.target.value)} />
                </div>
                <div className="addTaskForm__remove">
                    <IconButton onClick={handleClick} className='addTaskForm__icon'>
                        <RemoveCircleRoundedIcon />
                    </IconButton>
                </div>
                <div className="addTaskForm__inputGroup">
                    <label>task Start Date</label>
                    {console.log(taskStart,'taskStart')}
                    <input type="date"  value={taskStart} className="heightOfInputBox" onChange={e => setTaskStart(e.target.value)} />
                </div>
                <div className="addTaskForm__inputGroup">
                    <label>Task start time</label>
                    {console.log(startTime,'startTime')}
                    <input type="time" value={startTime} className="heightOfInputBox" onChange={e => setStartTime(e.target.value)} />
                </div>
                <div className="addTaskForm__inputGroup">
                    <label>task end date</label>
                    <input type="date" value={taskDeadline} className="heightOfInputBox" onChange={e => setTaskDeadline(e.target.value)} />
                </div>
                <div className="addTaskForm__inputGroup">
                    <label>task end time</label>
                    <input type="time" value={deadlineTime} className="heightOfInputBox" onChange={e => setDeadlineTime(e.target.value)} />
                </div>
                <div className="addTaskForm__inputGroup">
                    <div className="addTaskForm__buttons">
                        <button onClick={()=>{updateTask(editTaskListItem._id)}}>Save</button>
                    </div>
                </div>
            </div>)
}

export default UpdateTaskForm
