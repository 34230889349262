import { Avatar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import '../Styles/TicketComment.css'
import axios from '../Functionalities/axios'
import { dateFormat } from '../Functionalities/dateFormat'
import { timeFormat } from '../Functionalities/timeFormat'
import { useStateValue } from '../Functionalities/StateProvider'
import Modal from 'react-modal'
import UpdateEditor from './UpdateEditor'
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router'


function TicketComment({ticketID, store}) {
    const location = useLocation();
    const history = useHistory()

    const [state, dispatch] = useStateValue()
    const [ticketComments, setTicketComments] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteID, setDeleteID] = useState('')
    const [comment, setComment] = useState('')
    const [editStatus, setEditStatus] = useState(false)


    const updateComment = message => {
        setComment(message)
    }
    const getTicketComments = async () => {
        console.log(ticketID,'ticketID')
        await axios
            .get(`/ticketComments/${ticketID}`).then(response => response.data)
            .then(data => {
                setTicketComments(data)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
    useEffect(() => {
       

        getTicketComments()
    }, [])
    const updateEditComment = id => {
        // e.preventDefault()

        axios
            .patch(`ticketComments/${id}`, {
                "commentMessage": comment
            }).then(response => {
                store.addNotification({
                    title: "Comment Updated!",
                    message: `${comment} - Comment is added.`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
// history.push('/allTicket');

                setComment('');
                getTicketComments()
                // setLocationID('')
            })
            .catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 409) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Location Name exist.',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 500) {
                        if (error.response.data.name === 'MongoError') {
                            if (error.response.data.keyPattern.locationID) {
                                const duplicate = error.response.data.keyPattern.locationID
                                console.log(duplicate);
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: `Location ID exist previously`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else if (error.response.data.keyPattern.locationName) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Location Name exist.',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
  
const deleteComment = () =>{

    axios
    .delete(`ticketComments/${deleteID}`)
    .then(response => response.data)
    .then(data => {
    //    console.log('data of delete employee',data)
    //    alert("Employee Deleted Successfully")/*  */
       setDeleteID('')
       setDeleteModal(false)
    //    history.push('/allTicket');
    getTicketComments()
    //    history.goBack();

    }).catch(error => {
        alert(error)
     })
    //  useEffect();
}
    return (
        <div className='ticketComment'>
        {console.log("comment",comment)}
            {
                ticketComments?.map(ticketComment => (
                    <div key={ticketComment._id} className="ticketComment__list">

                        {/* User Details */}
                        <div className="ticketComment__user">
                            <Avatar src={`https://ui-avatars.com/api/?name=${state.employee.name}`}  />
                            <h5>{ticketComment.commentedByName}</h5>
                            <p>{dateFormat(ticketComment.createdAt)}, {timeFormat(ticketComment.createdAt)}</p>
                        </div>
  {/* modal start here  */}
  <Modal
                            className='delete__modal'
                            isOpen={deleteModal}
                            closeTimeoutMS={20}
                            shouldCloseOnOverlayClick={true}
                        >
                        <div style={{width:'100%'}}>
                            <h4>do you want to delete this comment?</h4>
                            <div className="row rowWidth">
                                <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={()=> setDeleteModal(false)}>Cancel</button></div>
                                <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={()=>{
                                deleteComment();
                            }}>Delete</button></div>

                            </div>
                        </div>
                          
                        </Modal>
{/* modal end here */}
                        {/* Comment Message */}
                        <div className="ticketComment__comment">
                            <p>{ticketComment.commentMessage}</p>
                        </div>
                        {editStatus ?  <UpdateEditor
                                  updateComment={updateComment} commentMessage={ticketComment.commentMessage}
                                /> : null }
                       
                          
                        {/* Comment Functionalities */}
                        <div className="ticketComment__function">
                        {editStatus ?   <button onClick={()=>{
                                setEditStatus(false)
                                updateEditComment(ticketComment._id)
                            }}>
                                Save
                            </button> :   <button onClick={()=>{
                                setEditStatus(true)
                            }}>
                                Edit
                            </button> }
                          
                            <button onClick={()=> {setDeleteID(ticketComment._id); setDeleteModal(true)}}>
                                Delete
                            </button>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TicketComment
