import React, { useState } from "react"
import '../Styles/AddLocation.css'
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import axios from "../Functionalities/axios";
import { useHistory } from "react-router";
import ReactNotification, { store } from 'react-notifications-component'
import { useStateValue } from "../Functionalities/StateProvider";
import { useLocation } from "react-router-dom";
function UpdateLocation() {
    const location = useLocation();
    const data = location.state.data;
    const history = useHistory()
    const [state, dispatch] = useStateValue()
    const [locationName, setLocationName] = useState(data.locationName)
    const [locationID, setLocationID] = useState(data.locationID)
    const [factoryName, setFactoryName] = useState('')
    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')

    // Adding new Location to Database
    const updateLocation = e => {
        e.preventDefault()

        axios
            .patch(`locations/${data.locationID}`, {
                "locationName": locationName
            }).then(response => {
                store.addNotification({
                    title: "Location Updated!",
                    message: `${response.data.locationName} - location is added.`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
history.push('/allLocation');
                setLocationName('')
                setLocationID('')
            })
            .catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 409) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Location Name exist.',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 500) {
                        if (error.response.data.name === 'MongoError') {
                            if (error.response.data.keyPattern.locationID) {
                                const duplicate = error.response.data.keyPattern.locationID
                                console.log(duplicate);
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: `Location ID exist previously`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else if (error.response.data.keyPattern.locationName) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Location Name exist.',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }

    // Cancelling form fill-up
    const cancelledForm = e => {
        e.preventDefault()

        setLocationName('')
        setLocationID('')
        setFactoryName('')
    }

    return (
        <div className='addLocation'>
{console.log('data received from all location',data)}
            {/* Header */}
            <DashboardHeader />
            <div className="addLocation__mainBody">

                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />

                {/* Add Location Page */}
                <div className="addLocation__newContainer">

                    {/* Heading */}
                    <div className="addLocation__heading">
                        <h1>Edit Location Name</h1>
                    </div>

                    {/* Form Section */}
                    <form className='addLocation__inputFields'>
                        {/* <div className="row rwoWidthh"> */}

                        <div className="addLocation__input">
                            <div className="row rwoWidthh">
                                <div className="col-md-4">
                                    <label>Location Name</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        size={45}
                                        placeholder={'Press Shop'}
                                        value={locationName}
                                        onChange={e => setLocationName(e.target.value)}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="addLocation__input">
                            <div className="row rwoWidthh">
                                <div className="col-md-4"> <label>Location ID</label></div>
                                <div className="col-md-8"> <input
                                    type="text"
                                    size={45}
                                    placeholder={'L209'}
                                    value={locationID}
                                    disabled
                                    onChange={e => setLocationID(e.target.value)}
                                /></div>

                            </div>



                            

                        </div>
                        <div className="addLocation__input">
                            <div className="row rwoWidthh">
                                <div className="col-md-4"><label>Factory</label></div>
                                <div className="col-md-8"><input
                                    size={45}
                                    type="text"
                                    value={selectedFactoryDetails[1]}
                                    disabled
                                /></div>                                

                            </div>


                        </div>
                        <div className="addLocation__buttons">
                        <div className="row rwoWidthh">
                                      <div style={{width:'50%'}}><button className="buttonAdd" style={{left:'0%'}} onClick={updateLocation}>Save</button></div>
                                <div style={{width:'50%'}}>
                                <button onClick={cancelledForm} className='addLocation__cancelButton buttonCancel' style={{left:'0%'}}>Cancel</button>


                                </div></div>
                                
                            </div>
                        {/* </div> */}

                    </form>
                </div>

            </div>
        </div>
    )
}

export default UpdateLocation