import React from 'react'
import ReactApexChart from 'react-apexcharts'

function BasicBarChart({ tickets }) {
    const open = tickets.filter((i) => i.status === "Open").length
    const inProgress = tickets.filter((i) => i.status === "In-Progress").length
    const completed = tickets.filter((i) => i.status === "Completed").length
    // Values for Basic Bar CHart
    const series = [{
        data: [open, inProgress, completed]
    }]

    // Options required for displaying the Bar Chart
    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: [
                'Open',
                'In-Progress',
                'Completed'
            ],
        }
    }

    // Returning the Basic Bar Chart
    return (
        <div
            className='basicBarChart'
            style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                width={'100%'}
                height={'100%'}
            />
        </div>
    )
}

export default BasicBarChart
