import React, { useEffect, useState } from 'react'
import RichTextEditor from 'react-rte-17'

function UpdateEditor({ updateComment, commentMessage }) {
    const [value, setValue] = useState(commentMessage)


    // const [value, setValue] = useState(RichTextEditor.createEmptyValue())
    // setValue(commentMessage)
    console.log(commentMessage, 'commentMessage')
    // const changed = e => {
    //     updateComment(e.toString('html').replace(/<[^>]+>/g, ''))
    //     console.log(e.toString('html').replace(/<[^>]+>/g, ''))
    //     console.log('setValye=>',e)
    //     setValue(e)
    // }

    // useEffect(() => {
    //     if (postComment === 'Commented') {
    //         setValue('')
    //     }
    // }, [postComment])

    // Editor for commenting and Creating Tickets
    return (
        <div className='editor'>
            {/* <RichTextEditor
                value={value}
                onChange={changed}
            /> */}
            <textarea
                type="text"
                placeholder='Edit Comment'
                style={{width:'95%',minHeight:100,marginLeft:20}}
                value={value}
                onChange={e => {
                    setValue(e.target.value)
                    updateComment(e.target.value)
                }
                }
            />
        </div>
    )
}

export default UpdateEditor