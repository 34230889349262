import React, { useEffect, useState } from "react"
import logo from '../Assets/logo.svg'
import '../Styles/Login.css'
import Modal from 'react-modal'
import Select from "react-select"
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useStateValue } from "../Functionalities/StateProvider";
import axios from "../Functionalities/axios";
import { parseJwt } from "../Functionalities/parseJwt"
import { useHistory } from "react-router"
import { getFactories } from "../Functionalities/getFactory"
import { getLocationsListByFactory } from "../Functionalities/getLocation"

// Required for Modal Component to show without error in console
Modal.setAppElement('#root')

function Login() {

    // Checking for fetching Data for showing Loading Component
    const [isLoading, setIsLoading] = useState(false)
    // Visibility of modals are controlled using these variables
    const [createAccountModal, setCreateAccountModal] = useState(false)
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
    const [loginEmail, setLoginEmail] = useState('sk9022522568@gmail.com')
    const [loginPassword, setLoginPassword] = useState('poiPOI@000')
    const [createAccountName, setCreateAccountName] = useState('')
    const [createAccountEmployeeID, setCreateAccountEmployeeID] = useState('')
    const [createAccountFactory, setCreateAccountFactory] = useState('')
    const [createAccountLocation, setCreateAccountLocation] = useState([])
    const [createAccountMaintenancePersonnel, setCreateAccountMaintenancePersonnel] = useState(false)
    const [createAccountMaintenanceManager, setCreateAccountMaintenanceManager] = useState(false)
    const [createAccountPhone, setCreateAccountPhone] = useState('')
    const [createAccountEmail, setCreateAccountEmail] = useState('')
    const [createAccountPassword, setCreateAccountPassword] = useState('')
    const [createAccountConfirmPassword, setCreateAccountConfirmPassword] = useState('')
    const [sended,setSended] = useState(false)

    const [empIDForgot,setEmpIDForgot] = useState('')
    const [emailIDForgot,setEmailIDForgot] = useState('')

    const history = useHistory() // Browser Pagination
    const [{ }, dispatch] = useStateValue() //Context API

    // Dynamically List will be initialized
    const [factoryList, setFactoryList] = useState([])
    const [locationList, setLocationList] = useState([])

    // Fetching Factory List from Database
    useEffect(() => {
        getFactories(setFactoryList, store)
    }, [])
// reset password 
const resetPassword = async() =>{
    console.log('empID,emailIDForgot',empIDForgot,emailIDForgot)
    if(empIDForgot && emailIDForgot){
     
        try {
            await axios
            .post('employees/sending-reset-password-link', {
                'employeeID': empIDForgot,
                'email': emailIDForgot,
            }).then(response =>{
                setSended(true)
                console.log('response.data=>',response.data)
            } 
            ).catch(error => {
                setIsLoading(false)
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 422 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!1",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!2",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!3",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!4",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!5",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!6",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
          } catch (error) {
            alert(error)
            
          }
    }
       
    }
    // Location List
    useEffect(() => {
        console.log('get location called')
        getLocationsListByFactory(setLocationList, store, createAccountFactory.value)
    }, [createAccountFactory])

    //Signing In a user
    const signIn = async e => {
        e.preventDefault()

        // /employees/login
        if (loginEmail && loginPassword) {

            setIsLoading(true)

            await axios
                .post('employees/login', {
                    'email': loginEmail,
                    'password': loginPassword,
                })
                .then(async response => {
                    console.log('response',response)
                    localStorage.setItem('token', response.data.token)
                    const { employeeID } = parseJwt(response.data.token)

                    // TODO: Link will be changed latter
                    //  Fetch from Employee new API
                    console.log('employeeID',employeeID)
                    await axios
                        .get(`employees/${employeeID}`)
                        .then(response => {
                            console.log("dataLogin",response.data)
                            const employee = response.data
                            console.log('user',employee)

                            dispatch({
                                type: 'LOGGED_EMPLOYEE',
                                user: {
                                    'email': employee.email,
                                    'employeeID': employee.employeeID,
                                    'name': employee.name,
                                    'factory': employee.factory,
                                    'location': employee.location,
                                    'isAdmin': employee.isAdmin,
                                    'maintenanceType': employee.maintenanceType,
                                    'status': employee.status,
                                    'profilePic': employee.profilePic
                                }
                            })

                            if (employee.status === 'pending' || employee.status === 'Pending') {
                                let employeeFactory = []
                                employee.factory.map(f => {
                                    let singleFactory = []
                                    singleFactory[0] = f.factoryID
                                    singleFactory[1] = f.factoryName
                                    employeeFactory.push(singleFactory)
                                })

                                dispatch({
                                    type: 'SET_FACTORY',
                                    factory: employeeFactory
                                })

                                dispatch({
                                    type: 'SELECTED_FACTORY',
                                    factory: employeeFactory,
                                })

                                let employeeLocation = []
                                employee.location.map(l => {
                                    let singleLocation = []
                                    singleLocation[0] = l.locationID
                                    singleLocation[1] = l.locationName
                                    employeeLocation.push(singleLocation)
                                })

                                dispatch({
                                    type: 'SET_LOCATION',
                                    location: employeeLocation
                                })

                                dispatch({
                                    type: 'SELECTED_LOCATION',
                                    location: employeeLocation,
                                })

                                history.push('/profile')
                            } else {

                                if (employee.factory.length === 1 && employee.location.length === 1) {

                                    let employeeFactory = []
                                    employee.factory.map(f => {
                                        let singleFactory = []
                                        singleFactory[0] = f.factoryID
                                        singleFactory[1] = f.factoryName
                                        employeeFactory.push(singleFactory)
                                    })

                                    dispatch({
                                        type: 'SET_FACTORY',
                                        factory: employeeFactory
                                    })

                                    dispatch({
                                        type: 'SELECTED_FACTORY',
                                        factory: employeeFactory,
                                    })

                                    let employeeLocation = []
                                    employee.location.map(l => {
                                        let singleLocation = []
                                        singleLocation[0] = l.locationID
                                        singleLocation[1] = l.locationName
                                        employeeLocation.push(singleLocation)
                                    })

                                    dispatch({
                                        type: 'SET_LOCATION',
                                        location: employeeLocation
                                    })

                                    dispatch({
                                        type: 'SELECTED_LOCATION',
                                        location: employeeLocation,
                                    })

                                    history.push('/dashboard')
                                } else if (employee.factory.length > 1) {

                                    let employeeFactory = []
                                    employee.factory.map(f => {
                                        let singleFactory = []
                                        singleFactory[0] = f.factoryID
                                        singleFactory[1] = f.factoryName
                                        employeeFactory.push(singleFactory)
                                    })

                                    dispatch({
                                        type: 'SET_FACTORY',
                                        factory: employeeFactory
                                    })

                                    setIsLoading(false)
                                    history.push('/factory')
                                } else if (employee.location.length > 1) {

                                    let employeeFactory = []
                                    employee.factory.map(f => {
                                        let singleFactory = []
                                        singleFactory[0] = f.factoryID
                                        singleFactory[1] = f.factoryName
                                        employeeFactory.push(singleFactory)
                                    })

                                    dispatch({
                                        type: 'SET_FACTORY',
                                        factory: employeeFactory
                                    })

                                    dispatch({
                                        type: 'SELECTED_FACTORY',
                                        factory: employeeFactory,
                                    })

                                    let employeeLocation = []
                                    employee.location.map(l => {
                                        let singleLocation = []
                                        singleLocation[0] = l.locationID
                                        singleLocation[1] = l.locationName
                                        employeeLocation.push(singleLocation)
                                    })

                                    dispatch({
                                        type: 'SET_LOCATION',
                                        location: employeeLocation
                                    })

                                    history.push('/factory/location')
                                }
                            }
                        }).catch(error => {
                            setIsLoading(false)
                            if (error.response) {
                                if (
                                    error.response.status === 400 ||
                                    error.response.status === 401 ||
                                    error.response.status === 404 ||
                                    error.response.status === 403 ||
                                    error.response.status === 500
                                ) {
                                    store.addNotification({
                                        title: "Error Occurred!7",
                                        message: error.response.data.error,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 2000,
                                            onScreen: true
                                        }
                                    })
                                } else {
                                    store.addNotification({
                                        title: "Error Occurred!8",
                                        message: 'Internal Server Error',
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 2000,
                                            onScreen: true
                                        }
                                    })
                                }
                            } else if (error.request) {
                                store.addNotification({
                                    title: "Error Occurred!9",
                                    message: 'Network Error',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else {
                                if (error.message) {
                                    store.addNotification({
                                        title: "Error Occurred!11",
                                        message: error.message,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 2000,
                                            onScreen: true
                                        }
                                    })
                                } else if (error.data) {
                                    store.addNotification({
                                        title: "Error Occurred!222",
                                        message: error.data,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 2000,
                                            onScreen: true
                                        }
                                    })
                                } else {
                                    store.addNotification({
                                        title: "Error Occurred!33",
                                        message: 'Something went wrong',
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 2000,
                                            onScreen: true
                                        }
                                    })
                                }
                            }
                        })
                }).catch(error => {
                    setIsLoading(false)
                    if (error.response) {
                        console.log("error.response",error.response)
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!33",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!44",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!55",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!66",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!77",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!88",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else {
            if (loginEmail === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Email ID.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (loginPassword === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Password.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            }
        }
    }

    // Create Account for a user
    const createAccount = async e => {

        e.preventDefault()

        // Creating Location Object to be passed in API
        let createAccountLocationList = []
        createAccountLocation.forEach(locationObject => {
            createAccountLocationList.push({
                "locationID": locationObject.value,
                "locationName": locationObject.label
            })
        })
        setCreateAccountLocation(createAccountLocationList)

        // Creating Factory Object to be passed in API
        let createAccountFactoryList = []
        console.log('createAccountFactory',createAccountFactory)
        createAccountFactoryList.push({
            "factoryID": createAccountFactory.value,
            "factoryName": createAccountFactory.label
        })

        let maintenanceType = 'None'
        if (createAccountMaintenancePersonnel && createAccountMaintenanceManager) {
            maintenanceType = 'Maintenance Manager'
        } else if (createAccountMaintenancePersonnel) {
            maintenanceType = 'Maintenance Personnel'
        }

        setCreateAccountModal(false)

        if (
            createAccountName &&
            createAccountEmployeeID &&
            createAccountFactory &&
            createAccountLocation &&
            createAccountPhone &&
            createAccountEmail &&
            createAccountPassword &&
            createAccountConfirmPassword
        ) {
const emailID = createAccountEmail
            setIsLoading(true)
console.log("name", createAccountName,
"employeeID", createAccountEmployeeID,
"factory", createAccountFactoryList,
"location", createAccountLocationList,
"phone", createAccountPhone,
"email", {emailID},
"password", createAccountPassword,
"confirmPassword", createAccountConfirmPassword,
"maintenanceType", maintenanceType)
            await axios
                .post('employees', {
                    "name": createAccountName,
                    "employeeID": createAccountEmployeeID,
                    "factory": createAccountFactoryList,
                    "location": createAccountLocationList,
                    "phone": createAccountPhone,
                    "email": {emailID},
                    "password": createAccountPassword,
                    "confirmPassword": createAccountConfirmPassword,
                    "maintenanceType": maintenanceType
                }).then(response => response.data)
                .then(employee => {
                    setIsLoading(false)

                    store.addNotification({
                        title: "New Account Created!",
                        message: `Now ${employee.name} can login.`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                })
                .catch(error => {
                    setIsLoading(false)

                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!111",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!222",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!323",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred3232!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else {
            if (createAccountName === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Name and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountEmployeeID === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Employee ID and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountFactory === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select your Factory and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountLocation.length < 1) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select your Location and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountPhone === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Phone Number and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountEmail === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Email and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountPassword === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Password and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (createAccountConfirmPassword === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Confirmation Password and Try Again.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            }
        }

        setCreateAccountName('')
        setCreateAccountEmployeeID('')
        setCreateAccountFactory('')
        setCreateAccountLocation([])
        setCreateAccountMaintenancePersonnel(false)
        setCreateAccountMaintenanceManager(false)
        setCreateAccountPhone('')
        setCreateAccountEmail('')
        setCreateAccountPassword('')
        setCreateAccountConfirmPassword('')
    }

    // Cancelled Creating Account Form
    const cancelCreateAccount = e => {
        e.preventDefault()

        setCreateAccountModal(false)
        setCreateAccountName('')
        setCreateAccountEmployeeID('')
        setCreateAccountFactory('')
        setCreateAccountLocation([])
        setCreateAccountMaintenancePersonnel(false)
        setCreateAccountMaintenanceManager(false)
        setCreateAccountPhone('')
        setCreateAccountEmail('')
        setCreateAccountPassword('')
        setCreateAccountConfirmPassword('')
    }

    return (
        <div className='login'>

            {/* Notification */}
            <ReactNotification />

            {/* Page and Application Details*/}
            <img src={logo} alt="" />
            <h3>incident management application</h3>
            <h1>login</h1>

            {/* Form for login */}
            <form>
                <div className="login__input">
                    <label>Email / Emp ID</label>
                    <input
                        value={loginEmail}
                        size='35'
                        type="text"
                        className='heightOfInputBox'
                        placeholder='abc@gmail.com'
                        onChange={e => setLoginEmail(e.target.value)}
                    />
                </div>
                <div className="login__input">
                    <label>Password</label>
                    <input
                        size='35'
                        className='login__pass'
                        type="password"
                        placeholder='********'
                        className='heightOfInputBox'
                        value={loginPassword}
                        onChange={e => setLoginPassword(e.target.value)}
                    />

                    {/* Create Account Modal Form */}
                    <div className="login__popups">
                        <span onClick={() => setCreateAccountModal(true)}>Create Account</span>
                        <Modal
                            className='login__modal'
                            isOpen={createAccountModal}
                            closeTimeoutMS={200}
                            shouldCloseOnOverlayClick={true}
                        >
                            <h1>Create Account</h1>
                            <div className="login__input">
                                <label>Name</label>
                                <input
                                    size={40}
                                    type="text"
                                    placeholder='Enter your full name'
                                    value={createAccountName}
                                    className='heightOfInputBox'
                                    onChange={e => setCreateAccountName(e.target.value)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Emp ID</label>
                                <input
                                    size={35}
                                    type="text"
                                    placeholder='H012345'
                                    value={createAccountEmployeeID}
                                    className='heightOfInputBox'
                                    onChange={e => setCreateAccountEmployeeID(e.target.value)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Factory</label>
                                <Select
                                    options={factoryList}
                                    placeholder='Select Factory'
                                    className='login__select'
                                    onChange={e => setCreateAccountFactory(e)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Location</label>
                                <Select
                                    options={locationList}
                                    placeholder='Select Location'
                                    className='login__select'
                                    isMulti
                                    onChange={e => setCreateAccountLocation(e)}
                                />
                            </div>
                            <div className="login__input personnel">
                                <label>User Role</label>
                                <span style={{marginLeft:-15}}>
                                    <input
                                        type="checkbox"
                                        className='heightOfInputBox'
                                        onChange={() => {
                                            setCreateAccountMaintenancePersonnel(!createAccountMaintenancePersonnel)
                                        }}
                                    />
                                    <label>
                                        I am Maintenance Personnel
                                                </label>
                                </span>
                            </div>
                            <div
                                className={`
                                                login__input manager
                                                ${!createAccountMaintenancePersonnel ? 'show' : ''}
                                            `}
                            >
                                <span style={{marginLeft:-11}}>
                                    <input
                                        type="checkbox"
                                        className='heightOfInputBox'
                                        onChange={() => {
                                            setCreateAccountMaintenanceManager(!createAccountMaintenanceManager)
                                        }}
                                    />
                                    <label>
                                        I am Maintenance Manager
                                                </label>
                                </span>
                            </div>
                            <div className="login__input">
                                <label>Phone No.</label>
                                <input
                                    type="text"
                                    placeholder='9876543210'
                                    size={35}
                                    value={createAccountPhone}
                                    className='heightOfInputBox'
                                    onChange={e => setCreateAccountPhone(e.target.value)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Email ID</label>
                                <input
                                    style={{ textTransform: 'none' }}
                                    type="email"
                                    className='heightOfInputBox'
                                    placeholder='email@hawkinscooker.com'
                                    size={35}
                                    value={createAccountEmail}
                                    onChange={e => setCreateAccountEmail(e.target.value)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className='heightOfInputBox'
                                    // placeholder='********'
                                    size={35}
                                    value={createAccountPassword}
                                    onChange={e => setCreateAccountPassword(e.target.value)}
                                />
                            </div>
                            <div className="login__input">
                                <label>Confirm Password</label>
                                <input
                                    type="password"
                                    // placeholder='********'
                                    className='heightOfInputBox'
                                    size={35}
                                    value={createAccountConfirmPassword}
                                    onChange={e => setCreateAccountConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className="login__createAccountBtns">
                                <button onClick={cancelCreateAccount}>Cancel</button>
                                <button onClick={createAccount}>Create</button>
                            </div>
                        </Modal>

                        {/* Forgot Password Modal Form */}
                        <div className="login__popupsDivider"></div>
                        <span onClick={() => setForgotPasswordModal(true)}>Forgot Password</span>
                        <Modal
                            className='login__modal'
                            isOpen={forgotPasswordModal}
                            closeTimeoutMS={200}
                            shouldCloseOnOverlayClick={true}
                        >
                            <h1>Forgot Password</h1>

                            <div className="login__input">
                                <label>Emp ID</label>
                                <input className='heightOfInputBox' size={35} type="text" placeholder='EMP001' 
                                    onChange={e =>{
                                        console.log(e.target.value)
                                        setEmpIDForgot(e.target.value)}
                                    }
                                />
                            </div>
                            <div className="login__input">
                                <label>Email ID</label>
                                <input style={{ textTransform: 'none' }} className='heightOfInputBox' type="email"
                                    onChange={e =>{
                                        console.log(e.target.value)
                                        setEmailIDForgot(e.target.value)}
                                    }
                                 placeholder='abc@gmail.com' size={35} />
                            </div>
                            <div className="login__buttonContainer">
                                <button className='login__resetBtn' onClick={() => resetPassword()}>reset password</button>
                            </div>
                            {sended ?  <p>
                                * A password Reset link has been sent on your registered email ID.
                            </p> : null }
                           
                            <u><p onClick={()=> setForgotPasswordModal(false)} style={{color:'#C31A1A'}}>close</p></u>
                        </Modal>
                    </div>
                </div>

                {/* Back to form */}
                <div className="login__input">
                    <button onClick={signIn}>sign in</button>
                </div>
            </form>
        </div>
    )
}

export default Login