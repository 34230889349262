import React, { useEffect, useState, useRef } from 'react'
import '../Styles/Profile.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import Select from "react-select"
import noProfilePicture from '../Assets/manicon 6.png'
import plusPicture from '../Assets/+.png'
import { useStateValue } from '../Functionalities/StateProvider'
import axios from "../Functionalities/axios";
import { data } from 'browserslist'
import { getFactories } from "../Functionalities/getFactory"
import ReactNotification, { store } from 'react-notifications-component'
import { getLocationsListByFactory } from "../Functionalities/getLocation"
import { encodingLength } from 'dns-packet'
import assemblyLogo from '../Assets/assembly-location-logo 2.png'
import { Phone } from '@material-ui/icons'

function Profile() {

    const [state, dispatch] = useStateValue()
    const file = useRef()
    const [profilePicture, setProfilePicture] = useState(null)
    const [previewImage, setPreviewImage] = useState('')
    const [editStatus, setEditStatus] = useState(false)

    const [data, setData] = useState(null)
    const [name, setName] = useState(null)
    const [employeeID, setEmployeeID] = useState(null)
    const [factory, setFactory] = useState(null)
    const [location, setLocation] = useState(null)
    const [maintenanceType, setMaintenanceType] = useState(null)
    const [admin, setAdmin] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [oldPassword, setOldPassword] = useState(null)
    const [adminFactories,setAdminFactories] = useState(null)
const [authStatus,setAuthStatus] = useState(null)
    const [editData, setEditData] = useState(null)
    const [editName, setEditName] = useState(null)
    const [editEmployeeID, setEditEmployeeID] = useState(null)
    const [editFactory, setEditFactory] = useState(null)
    const [editFactoryList, setEditFactoryList] = useState(null)
    const [editLocation, setEditLocation] = useState(null)
    const [editLocationList, setEditLocationList] = useState(null)
    const [editMaintenanceType, setEditMaintenanceType] = useState(null)
    const [editAdmin, setEditAdmin] = useState(null)
    const [editPhone, setEditPhone] = useState(null)
    const [editEmail, setEditEmail] = useState(null)
    const [editOldPassword, setEditOldPassword] = useState(null)
    const [editNewPassword, setEditNewPassword] = useState(null)
    const [editConfirmPassword, setEditConfirmPassword] = useState(null)
    const [editAuthStatus,setEditAuthStatus] = useState(null)
    const [profileImage,setProfileImage] = useState(null)
    const [ticketCount,setTicketCount] = useState(null)

    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')


    const maintenanceTypeList = [
        {
            'value': 'None',
            'label': 'None'
        },
        {
            'value': 'Maintenance Personnel',
            'label': 'Maintenance Personnel'
        },
        {
            'value': 'Maintenance Manager',
            'label': 'Maintenance Manager'
        }
    ]

    // Image Previewing
    useEffect(() => {
        if (profilePicture) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreviewImage(reader.result)
            }
            reader.readAsDataURL(profilePicture)
        } else {
            setPreviewImage(null)
        }

    }, [profilePicture])
    // get factory
    useEffect(() => {

        getFactories(setEditFactoryList, store)
    }, [])

    const getLocation = () => {
        console.log("editFactory", editFactory)
        // "getLocationsListByFactory",getLocationsListByFactory(setEditLocationList, store, editFactory[0].fac)
    }
    useEffect(() => {
        console.log('get location called', editFactory)
        if (editFactory) {
            if (editFactory[0]?.factoryID) {
                console.log(editFactory[0].factoryID)
                getLocationsListByFactory(setEditLocationList, store, editFactory[0].factoryID)

            } else {
                console.log(editFactory[0]?.value)
                getLocationsListByFactory(setEditLocationList, store, editFactory[0]?.value)

            }
        }

    }, [editFactory])
    // employee get
    useEffect(async () => {
        let employeeID = state.employee.employeeID
        console.log('employeeID', employeeID)
        await axios
            .get(`employees/${employeeID}`)
            .then(response => {
                console.log("response.data", response.data)
                setData(response.data)
                setName(response.data.name)
                setEmployeeID(response.data.employeeID)
                setFactory(response.data.factory)
                setLocation(response.data.location)
                setMaintenanceType(response.data.maintenanceType)
                setAdmin(response.data.isAdmin)
                setEmail(response.data.email)
                setPhone(response.data.phone)
                setAuthStatus(response.data.status)
                setProfileImage(response.data.profilePic)
                setTicketCount(response.data.totalTickets)
                // setOldPassword(response.data.)

            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })

    }, [])
    // setValuesEdit
    const setValuesEdit = () => {
        setEditName(name)
        setEditEmployeeID(employeeID)
        setEditFactory(factory)
        setEditLocation(location)
        setEditMaintenanceType(maintenanceType)
        setEditAdmin(admin)
        setEditPhone(phone)
        setEditEmail(email)
        setEditAuthStatus(authStatus)
    }
    // update api
    const update = async () => {
        let employeeID = state.employee.employeeID
        console.log('employeeID', employeeID)
        console.log(
            "editName", editName,
            "editEmployeeID", editEmployeeID,
            "editFactory", editFactory,
            "editLocation", editLocation,
            "editMaintenanceType", editMaintenanceType,
            "editAdmin", editAdmin,
            "editPhone", editPhone,
            "editEmail", editEmail,
            "editOldPassword", editOldPassword,
        )
        let location = []
  let factory = []


 
  if(editFactory[0]?.value){
      console.log('edit factory',editFactory)
      editFactory.map(item => {
          console.log(item)
          return factory.push({
              factoryID: item.value, factoryName: item.label
          })
      })
      
  }else{
      console.log('edit factory name',editFactory)
// factory = editFactory
editFactory.map(item => {
  console.log(item)
  return factory.push({
      factoryID: item.factoryID, factoryName: item.factoryName
  })
})
  }
  if(editLocation[0]?.value){
      console.log('edit location',editLocation)
      editLocation.map(item => {
          console.log(item)

          return location.push({
              locationID: item.value, locationName: item.label
          })
      })
  }else{
      console.log('edit location name',editLocation)
      // location = editLocation
      editLocation.map(item => {
        console.log(item)

        return location.push({
            locationID: item.locationID, locationName: item.locationName
        })
    })

  }
        let employeeIDD = state.employee.employeeID
        console.log('employeeID', employeeID)
        await axios
            .put(`employees/${employeeIDD}`, {
                name: editName,
                employeeID: editEmployeeID,
                location: location,
                factory: factory,
                phone: '' + editPhone,
                email: {"emailID":editEmail?.emailID},
                maintenanceType: editMaintenanceType,
                // oldPassword: editOldPassword,
                // password: editNewPassword,
                // confirmPassword: editConfirmPassword,
            }
            )
            .then(response => {
                console.log("response.data", response.data)
                setData(response.data)
                setName(response.data.name)
                setEmployeeID(response.data.employeeID)
                setFactory(response.data.factory)
                setLocation(response.data.location)
                setMaintenanceType(response.data.maintenanceType)
                setAdmin(response.data.isAdmin)
                setEmail(response.data.email)
                setPhone(response.data.phone)
                setAuthStatus(response.data.status)

                // setOldPassword(response.data.)

            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }

    return (
        <div className='profile'>
{console.log('location of employee .+',location)}
            {/* Header */}
            <DashboardHeader />
            <div className="profile__mainBody">

                {/* Sidebar */}
                <Sidebar />
                <div className="profile__container">

                    {/* Heading */}
                    <h1>My Profile</h1>
                    <div className="profile__details">
                        <div className="row rowWidth">
                            <div className="col-md-3 col-xs-6 col-sm-12">
                                {/* Photo Section */}
                                <div className="profile__leftDiv">
                                    <span>My Photo</span>
                                    <input className="heightOfInputBox"
                                        type="file"
                                        id='real-file'
                                        hidden='hidden'
                                        accept='image/*'
                                        ref={file}
                                        onChange={e => {
                                            const img = e.target.files[0]
                                            if (img && img.type.substr(0, 5) === 'image') {
                                                setProfilePicture(img)
                                            } else {
                                                setProfilePicture(null)
                                            }
                                        }}
                                    />
                                    <div
                                        onClick={e => {
                                            e.preventDefault()
                                            file.current.click()
                                        }}
                                        className="profile__image">
                                        <img
                                            src={`${profileImage !== 'NA' ? profileImage : noProfilePicture}`}
                                            className='profile__noProfilePicture'
                                        />
                                        {
                                            profileImage !== 'NA' ?
                                                null
                                                : <img src={plusPicture} className='profile__plusPicture' />
                                        }

                                    </div>
                                    <div style={{marginTop:20}}className={`viewEmployeeInfo__appAuth ${authStatus}`}>{authStatus}</div>

                                </div></div>
                            <div className="col-md-6 col-xs-6 col-sm-12">  {/* Form Section */}
                                <div className="profile__info">
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Name</label></div>
                                            {editStatus ? <div className="col-md-8"><input className="heightOfInputBox"
                                                type="text"
                                                defaultValue={name}
                                                onChange={e => setEditName(e.target.value)}
                                            /></div> : <div className="col-md-8"><p>{name}</p></div>}
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Employee ID</label></div>
                                            {editStatus ? <div className="col-md-8"><input className="heightOfInputBox"
                                                type="text"
                                                defaultValue={employeeID}
                                                onChange={e => setEditEmployeeID(e.target.value)}

                                            /></div> : <div className="col-md-8"><p>{employeeID}</p></div>}

                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Factory</label></div>
                                            {editStatus ? <div className="col-md-8"> <Select
                                                options={editFactoryList}
                                                placeholder='Select factory'
                                                className='profile__select'
                                                defaultValue={{ label: factory[0].factoryName }}
                                                onChange={e => setEditFactory([e])}
                                            /></div> : <div className="col-md-8"><span>{selectedFactoryDetails[1]}</span></div>}

                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Location</label></div>
                                            {editStatus ? <div className="col-md-8">
                                                {console.log('editLocation', editLocation?.map(item => {
                                                    return console.log(item)
                                                }), 'editLocationList', editLocationList)}
                                                <Select
                                                    options={editLocationList}
                                                    placeholder='Select Location'
                                                    className='profile__select'
                                                    isMulti
                                                    defaultValue={editLocation?.map(item => {
                                                        return { label: item.locationName, value: item.locationID }
                                                    })}
                                                    onChange={e => setEditLocation(e)}
                                                /></div> : <div className="col-md-8">
                                                {location?.map((item, key) => {
                                                    return <div style={{ marginBottom: 5 }}>


                                                        <img
                                                            src={assemblyLogo}
                                                            alt={item.locationName}
                                                        />
                                                        {item.locationName}
                                                    </div>
                                                })}
                                            </div>}

                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Maintenance Type</label></div>
                                            <div className="col-md-8">
                                                {editStatus ? <Select
                                                    options={maintenanceTypeList}
                                                    placeholder='Select maintenance type'
                                                    className='profile__select'
                                                    defaultValue={{ label: maintenanceType }}
                                                    onChange={e => setEditMaintenanceType(e.label)}
                                                /> : <p>{maintenanceType}</p>}

                                            </div>
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"> <label>Admin role</label></div>
                                            <div className="col-md-8">
                                                {editStatus ? admin ? <p>Yes</p> : <p>N/A</p> : admin ? <p>Yes</p> : <p>N/A</p>}
                                            </div>
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Phone No.</label></div>
                                            <div className="col-md-8">
                                                {editStatus ? <input className="heightOfInputBox"
                                                    type="text"
                                                    defaultValue={phone}
                                                    onChange={e => { setEditPhone(e.target.value) }}
                                                /> : <p>{phone}</p>} </div>
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">
                                            <div className="col-md-4"><label>Email</label></div>
                                            <div className="col-md-8">
                                            {console.log("email=>",email)}
                                                {editStatus ? <input
                                                    type="text"
                                                    className="heightOfInputBox"
                                                    defaultValue={editEmail?.emailID}
                                                    onChange={e => { setEditEmail({"emailID":e.target.value}) }}
                                                /> : <p>{email?.emailID}</p>}</div>
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">{editStatus ? <>
                                            <div className="col-md-4"><label>Old Password</label></div>
                                            <div className="col-md-8">
                                                <input className="heightOfInputBox"
                                                    type="text"
                                                    placeholder='Old Password'
                                                    onChange={e => { setEditOldPassword(e.target.value) }}
                                                />
                                            </div></>
                                            : null}
                                        </div>


                                    </div>
                                    <div className="profile__input">
                                        <div className="row rowWidth">{editStatus ? <>
                                            <div className="col-md-4"><label>New Password</label></div>
                                            <div className="col-md-8">
                                                <input className="heightOfInputBox"
                                                    type="text"
                                                    placeholder='New Password'
                                                    onChange={e => { setEditNewPassword(e.target.value) }}

                                                />

                                            </div> </> : null}
                                        </div>


                                    </div>
                                    <div className="profile__input">

                                        <div className="row rowWidth">  {editStatus ? <>

                                            <div className="col-md-4"> <label>Confirm New Password</label></div>
                                            <div className="col-md-8">
                                                <input className="heightOfInputBox"
                                                    type="text"
                                                    placeholder='Confirm New Password'
                                                    onChange={e => { setEditConfirmPassword(e.target.value) }}

                                                />
                                            </div>
                                        </>
                                            : null}
                                        </div>

                                    </div>
                                    <div className="profile__input">
                                        {editStatus ? <><button className="buttImport" onClick={() => {
                                            setEditStatus(false)
                                            update()
                                        }
                                        }>Save</button> <button onClick={() => setEditStatus(false)}>Cancel</button></> : <button className="buttImport buttImportV2" onClick={() => {
                                            setEditStatus(true)
                                            setValuesEdit()
                                        }}>Edit</button>}


                                    </div>
                                </div></div>
                            <div className="col-md-3 col-xs-6 col-sm-12"> {/* Total Tickets Count Box */}
                                <div className="profile__box">
                                    <div className="profile__boxHeading">
                                        total tickets
                                    </div>
                                    <div className="profile__boxValue">
                                       {ticketCount}
                                    </div>
                                    <div className="profile__boxFooter">
                                        <span>view all</span>
                                        <span>export</span>
                                    </div>
                                </div></div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile