import React, { useEffect, useState } from "react";
import "../Styles/AllLocation.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useStateValue } from "../Functionalities/StateProvider";
import ExportExcelFile from "../Components/ExportExcelFile";
import { useHistory } from "react-router";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { getLocationsByFactory } from "../Functionalities/getLocation";
import axios from "../Functionalities/axios";
import Modal from "react-modal";
import Loader from "react-loader-spinner";

function AllLocation() {
  const history = useHistory();
  const [{ factory, employee }, dispatch] = useStateValue();
  const [locations, setLocations] = useState([]);
  const [editBttuon, setEditButton] = useState(true);
  // Getting previously selected Factory and Location for Data Persistent
  const selectedFactoryDetails = localStorage
    .getItem("selected_factory")
    .split(",");
  const selectedLocationDetails = localStorage
    .getItem("selected_location")
    .split(",");

  // Getting account of locations selected from location list
  const [isChecked, setIsChecked] = useState([]);

  const [page, setPage] = useState(1); // For Pagination
  const [previousPageShow, setPreviousPageShow] = useState(false); // For showing Previous Button in Pagination
  const [nextPageShow, setNextPageShow] = useState(false); // For showing Next Button in Pagination
  const limit = 10; // For Pagination
  const [deleteLocations, setDeleteLocations] = useState(false);
  const [editLocation, setEditLocation] = useState(null);
  const [dateLocation, setDateLocation] = useState(null);
  const [search, setSearch] = useState(null);
  const [loader,setLoader] = useState(true)

  // Timeline List
  const dateLocationList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    {
      value: "last-three-months",
      label: "Last 3 Months",
    },
    {
      value: "this-year",
      label: "This Year",
    },
  ];
  // Timeline List
  const dateMachineList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "this-week",
      label: "This Week",
    },
    {
      value: "this-month",
      label: "This Month",
    },
    {
      value: "last-three-months",
      label: "Last 3 Months",
    },
    {
      value: "this-year",
      label: "This Year",
    },
  ];
  // Sort Type List
  const sortOptions = [
    {
      value: 1,
      label: "Location Name",
    },
    {
      value: 2,
      label: "Location ID",
    },
    {
      value: 3,
      label: "Factory",
    },
  ];

  // Setting to defaults
  useEffect(() => {
    setPreviousPageShow(false);
    setNextPageShow(false);
  }, [page]);

  // delete location

  const deleteAllLocationArray = () => {
    locations.map((item, key) => {
      return isChecked.map((checkk, checkKey) => {
        return checkKey == key && checkk == true
          ? deleteLocation(item.locationID)
          : null;
      });
    });
    getAllLocation();
  };
  const deleteLocation = async (id) => {
    await axios
      .delete(`locations/${id}`)
      .then((response) => response.data)
      .then((data) => {
        //    alert("Employee Deleted Successfully")
        //    history.goBack();
        getAllLocation();
      })
      .catch((error) => {
        alert(error);
      });
  };
  const getAllLocation = async () => {
    setIsChecked([]);
    setDeleteLocations(false);
    await getLocationsByFactory(
      setLocations,
      selectedFactoryDetails[0],
      store,
      limit,
      page,
      setPreviousPageShow,
      setNextPageShow,
      setLoader
    );
    history.push("/allLocation");
  };
  // Fetching data
  useEffect(() => {
    getLocationsByFactory(
      setLocations,
      selectedFactoryDetails[0],
      store,
      limit,
      page,
      setPreviousPageShow,
      setNextPageShow,
      setLoader
    );
  }, [page]);

  // search function
  const searchFunc = async () => {
    let haveDateTime = dateLocation;
    if (dateLocation) {
      console.log("if", dateLocation);
      if (dateLocation.label === "All") {
        haveDateTime = null;
      } else {
        haveDateTime = dateLocation.value;
      }
    }
    console.log(
      "search",
      search,
      "haveDateTime",
      haveDateTime,
      "selectedLocationDetails[0]",
      selectedLocationDetails[0]
    );

    await axios
      .get(
        `locations/${selectedFactoryDetails[0]}?search=${search}&date_filter=${haveDateTime}`
      )
      .then((response) => response.data)
      .then((data) => {
        console.log("data get all location search", data.locations);
        setLocations(data.locations);
        // if (response.data.next) setNextPageShow(true)
        // if (response.data.previous) setPreviousPageShow(true)
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  const reset = () => {
    setDateLocation([
      {
        value: "All",
        label: "All",
      },
    ]);
    setSearch("");
    getAllLocation();
  };
  useEffect(() => {
    reset();
  }, []);

  return (
    <div className="allLocation">
      {/* Header */}
      <DashboardHeader />
      <div className="allLocation__mainBody">
        {/* Notification */}
        <ReactNotification />

        {/* Sidebar */}
        <Sidebar />
        <div className="allLocation__container">
          {/* Heading */}
          <h1>All Locations</h1>

          {/* Input Fields */}
          <div className="allLocation__inputFields">
            <div className="row rowWidthAllLocation">
              <div className="col-md-7 col-sm-12 col-xs-12">
                {" "}
                <input
                  className="widthOfSearch heightOfInputBox"
                  size={100}
                  type="text"
                  placeholder="Search Location"
                  style={{ marginBottom: 10 }}
                  value={search || ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                {" "}
                <Select
                  options={dateLocationList}
                  placeholder="Date"
                  className="allLocationDate__select marginTopCol"
                  defaultValue={dateLocationList[0]}
                  value={dateLocation}
                  onChange={(e) => setDateLocation(e)}
                  style={{ marginBottom: 10 }}
                />
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12">
                {" "}
                <button
                  style={{ marginTop: 0, marginBottom: 10 }}
                  className="heightOfInputBox"
                  onClick={() => searchFunc()}
                >
                  Search
                </button>
              </div>
              <div
                className="col-md-1 col-sm-12 col-xs-12"
                style={{ marginLeft: "10px" }}
              >
                {" "}
                <button
                  style={{ marginTop: 0, marginBottom: 10 }}
                  className="heightOfInputBox"
                  onClick={() => reset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="allLocation__buttons">
            {employee.isAdmin && <div className="allLocation__leftBtns">
              <div className="row">
                {editBttuon ? (
                  <div className="col-md-6 col-xs-12 col-sm-12">
                    {" "}
                    <button
                      className="marginButtonTop"
                      onClick={() => {
                        console.log(editLocation, "editLocation");
                        history.push({
                          pathname: "/updateLocation",
                          state: { data: editLocation },
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className="allLocation__icon"
                        icon={faEdit}
                      />
                      edit
                    </button>
                  </div>
                ) : null}

                <div className="col-md-6 col-xs-12 col-sm-12">
                  {" "}
                  <button
                    onClick={() => {
                      setDeleteLocations(true);
                    }}
                    className="alignDeleteButton"
                  >
                    <FontAwesomeIcon
                      className="allLocation__icon"
                      icon={faTrashAlt}
                    />
                    delete
                  </button>
                </div>
                {/* modal start here  */}
                <Modal
                  className="delete__modal"
                  isOpen={deleteLocations}
                  closeTimeoutMS={20}
                  shouldCloseOnOverlayClick={true}
                >
                  <div style={{ width: "100%" }}>
                    <h4>do you want to delete this Location?</h4>
                    <div className="row rowWidth">
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        {" "}
                        <button onClick={() => setDeleteLocations(false)}>
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <button
                          onClick={() => {
                            deleteAllLocationArray();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* modal end here */}
              </div>
            </div>}
            <div className="allLocation__rightBtns">
              <ExportExcelFile csvData={locations} fileName="Locations" />
            </div>
          </div>

          {/* Table */}
          {loader ? <div style={{width:'100%',padding:'14% 48%' ,justifyContent:'center',alignContent:'center'}}>
        <Loader
        type="Puff"
        color="#C71800"
        height={'100%'}
        width={"100%"}
        // timeout={3000} //3 secs
      /> 
      </div>
        : 
        <div className="allLocation__table">
            {/* Table Heading */}
            <div className="allLocation__tableHeading">
              <span>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setIsChecked((previousValue) => {
                      previousValue = new Array(locations.length);
                      previousValue.fill(e.target.checked);
                      return previousValue;
                    });
                  }}
                />
              </span>
              <span>Location ID</span>
              <span>Location</span>
              <span>Total Employee</span>
              <span>Total Machine</span>
              <span>Total Tickets</span>
              <span>Total Schedules</span>
            </div>

            {/* Table Data */}
            {locations?.map((location, index) => (
              <div
                key={location._id}
                className={`allLocation__tableData ${isChecked[index] && "selected"
                  }`}
              >
                <span>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      console.log("location sleected ", location);
                      setEditLocation(location);
                      setIsChecked((isChecked) => {
                        console.log(isChecked, "isChecked");
                        let c = [...isChecked];
                        c[index] = e.target.checked;
                        return c;
                      });
                    }}
                    checked={isChecked[index]}
                  />
                </span>
                <span>{location.locationID}</span>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: "SINGLE_LOCATION",
                      location: location,
                    });
                    console.log("location sleected ", location);

                    history.push("/viewLocation");
                  }}
                >
                  {location.locationName}
                </span>
                <span>{location.totalEmployeesCount}</span>
                <span>{location.totalMachinesCount}</span>
                <span>{location.totalTicketsCount}</span>
                <span>{location.totalSchedulesCount}</span>
              </div>
            ))}
          </div> 
          }
        

          {/* Pagination Section */}
          <div className="allLocation__pages">
            {previousPageShow && (
              <>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    if (page > 0) setPage(page - 1);
                  }}
                >
                  1
                </button>
              </>
            )}
            <button className="select">{page}</button>
            {nextPageShow && (
              <>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {page + 1}
                </button>
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllLocation;
