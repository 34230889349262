import React from "react"
import logo from '../Assets/logo.svg'
import '../Styles/Header.css'
import {Avatar} from "@material-ui/core";

function Header() {

    // Normal Header without functionality for logged out user
    return (
        <div className='header'>
            <div className="header__logo">
                <img src={logo} alt=""/>
                <h1>Incident Management App</h1>
            </div>
            <Avatar />
        </div>
    )
}

export default Header