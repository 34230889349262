import React, { useState } from 'react'
import '../Styles/AddMachine.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import { useStateValue } from '../Functionalities/StateProvider'
import { useHistory } from 'react-router'
import axios from '../Functionalities/axios'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useLocation } from "react-router-dom";

function UpdateMachine() {
    const location = useLocation();
    const data = location.state.data;
    const [state, dispatch] = useStateValue()
    const history = useHistory()
    const [machineID, setMachineID] = useState(data.machineID)
    const [machineName, setMachineName] = useState(data.machineName)
 
    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')

    // Adding Machine to Database
    const addMachine = e => {
        e.preventDefault()

        axios
            .patch(`/machines/${machineID}`, {
                "machineName": machineName,
            })
            .then(response => {
                store.addNotification({
                    title: "Machine updated successfully!",
                    message: `${machineName} - machine is created!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })  
                setMachineName('')
                history.push('/allMachine')
                
              
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 409) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Machine Name already exist.',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 500) {
                        if (error.response.data.name === 'MongoError') {
                            if (error.response.data.keyPattern.machineID) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: `Machine ID exist previously`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })

    
    }

    // On cancelling form
    const cancelledForm = e => {
        e.preventDefault()

        setMachineID('')
        setMachineName('')
    }

    return (
        <div className='addMachine'>

            {/* Header */}
            <DashboardHeader />

            <div className="addMachine__mainBody">

                {/* Notification */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />
                <div className="addMachine__container">

                    {/* Navigation Breadcrumb */}
                    <div className='addMachine__containerPath'>
                        {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / Edit Machines
                    </div>

                    {/* Heading */}

                    <h1>Edit Machine</h1>

                    {/* Form Section */}
                    <form className="addMachine__form">

                        <div className="addMachine__input">
                            <div className="row rowWidth ">
                                <div className="col-md-4"><label>Machine ID</label>
                                </div>
                                <div className="col-md-8"> <input
                                    type="text"
                                    placeholder='M021'
                                    value={machineID}
                                    disabled
                                    onChange={e => setMachineID(e.target.value)}
                                /></div>
                            </div>

                        </div>
                        <div className="addMachine__input">
                            <div className="row rowWidth">
                                <div className="col-md-4"> <label>Machine Name</label></div>
                                <div className="col-md-8"> <input
                                    type="text"
                                    placeholder='Machine 1'
                                    value={machineName}
                                    onChange={e => setMachineName(e.target.value)}
                                /></div>
                            </div>


                        </div>
                        <div className="addMachine__input">
                            <div className="row rowWidth">
                                <div className="col-md-4"> <label>Location</label></div>
                                <div className="col-md-8"> <input
                                    type="text"
                                    placeholder={selectedLocationDetails[1]}
                                    disabled
                                /></div>
                            </div>


                        </div>
                        {/* <div className="addMachine__input">
                        <label>buttons</label> */}
                      
                        <div className="addLocation__buttons">
                        <div className="row rwoWidthh">
                                      <div style={{width:'50%'}}><button style={{left:'0%'}} onClick={addMachine}>save</button></div>
                                <div style={{width:'50%'}}><div className='buttonCancel'>
                                <button style={{left:'0%'}} onClick={cancelledForm} className="cancelColor addMachine__buttons">cancel</button>

                                </div>

                                </div></div>
                                
                            </div>
                            {/* <div className="addMachine__buttons">
                            <div className="row rowWidth">
                            <div className="ol-md-6 col-sm-6 col-xs-6"> <div className="buttonAdd"></div><button onClick={addMachine}>add</button></div></div>
                            <div className="ol-md-6 col-sm-6 col-xs-6"><div className="buttonCancel"> <button onClick={cancelledForm}>cancel</button></div></div>
                            </div> */}
                               
                            
                        {/* </div> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateMachine
