import React, { useEffect, useState } from 'react'
import '../Styles/Dashboard.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faCalendarPlus, faClipboardCheck, faEdit, faRedoAlt, faShareAlt, faTools } from '@fortawesome/free-solid-svg-icons'
import StackedBarChart from '../Components/StackedBarChart'
import PieChart from '../Components/PieChart'
import BasicBarChart from '../Components/BasicBarChart'
import LineChart from '../Components/LineChart'
import { useHistory } from 'react-router'
import axios from "../Functionalities/axios"
import { useStateValue } from "../Functionalities/StateProvider";
import ExportExcelFile from '../Components/ExportExcelFile'

function Dashboard() {

    const [totalTickets, settotalTickets] = useState("");
    const [tickets, setTickets] = useState([]);
    const [maintainence, setmaintainence] = useState([])
    const selectedFactoryDetails = localStorage
        .getItem("selected_factory")
        .split(",");
    const selectedLocationDetails = localStorage
        .getItem("selected_location")
        .split(",");
    const [state, dispatch] = useStateValue() //Context API
    const getCount = async () => {
        console.log("selectedFactoryDetails", selectedFactoryDetails, 'selectedLocationDetails', selectedLocationDetails)
        await axios.get(`tickets/location/${selectedLocationDetails[0]}`)
            .then(res => {
                console.log("count=>", res.data.tickets)
                settotalTickets(res.data.totalItems)
                setTickets(res.data.tickets)
            }
            )
            .catch(err => console.log(err))
    }
    const getMaintainence = async () => {
        await axios.get(`schedules/${selectedLocationDetails[0]}`)
            .then(res => {
                // console.log("count=>", res.data.results)
                setmaintainence(res.data.results)
            }
            )
            .catch(err => console.log(err))
    }
    useEffect(() => {
        getCount()
        getMaintainence()
    }, [])

    // console.log(tickets);



    const openTickets = [...tickets]
    const open = openTickets.filter((ticket) => ticket.status === "Open");
    const closeTickets = [...tickets]
    const close = closeTickets.filter((ticket) => ticket.status === "Resolved");
    const inProgressTickets = [...tickets]
    const inProgress = inProgressTickets.filter((ticket) => ticket.status === "In-Progress");
    const raisedBy = [...tickets]
    const raiseByYou = raisedBy.filter((ticket) => ticket.requestedBy.employeeName === state.employee.name);
    // console.log("raised by you", raiseByYou);
    const assignedTo = [...tickets]
    const assignedToYou = assignedTo.filter((ticket) => ticket.assignTo.employeeName === state.employee.name);
    // console.log("assigned to you", assignedToYou);


    const history = useHistory()

    // Year List
    const yearList = [
        {
            value: 1,
            label: '2020'
        },
        {
            value: 2,
            label: '2021'
        }
    ]

    return (
        <div className='dashboard'>

            {/* Header */}
            <DashboardHeader />

            <div className="dashboard__mainBody">

                {/* Sidebar */}
                <Sidebar />
                <div className="dashboard__container">

                    {/* Year Selection */}
                    <Select
                        options={yearList}
                        className='dashboard__select'
                    />

                    {/* 5 boxes of Ticket Statistics */}
                    <div className="dashboard__boxes">
                        <div className="dashboard__box">
                            <div className="dashboard__infoHeading">
                                <h3>
                                    total no. of tickets
                                </h3>
                                <h2>{totalTickets}</h2>
                            </div>
                            <div className="dashboard__infoFooter">
                                <span onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'all' },
                          });
                                }}>View all</span>
                                <ExportExcelFile color="blue"
                                    csvData={tickets}
                                    fileName='Tickets'
                                />
                            </div>
                        </div>
                        <div className="dashboard__box">
                            <div className="dashboard__infoHeading">
                                <h3>
                                    open tickets
                                </h3>
                                <h2>{open.length}</h2>
                            </div>
                            <div className="dashboard__infoFooter">
                                <span onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'open' },
                          });
                                }}>View all</span>
                                <ExportExcelFile
                                    csvData={open}
                                    fileName='OpenTickets'
                                />
                            </div>
                        </div>
                        <div className="dashboard__box">
                            <div className="dashboard__infoHeading">
                                <h3>
                                    in-progress tickets
                                </h3>
                                <h2>{inProgress.length}</h2>
                            </div>
                            <div className="dashboard__infoFooter">
                                <span onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'in-progress' },
                          });
                                }}>View all</span>
                                <ExportExcelFile
                                    csvData={inProgress}
                                    fileName='InProgressTickets'
                                />
                            </div>
                        </div>
                        <div className="dashboard__box">
                            <div className="dashboard__infoHeading">
                                <h3>
                                    closed tickets
                                </h3>
                                <h2>{close.length}</h2>
                            </div>
                            <div className="dashboard__infoFooter">
                                <span onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'closed' },
                          });
                                }}>View all</span>
                                <ExportExcelFile
                                    csvData={close}
                                    fileName='CloseTickets'
                                />
                            </div>
                        </div>
                        <div className="dashboard__box">
                            <div
                                className="dashboard__innerNavigation"
                                onClick={e => {
                                    e.preventDefault()

                                    history.push('/addTicket')
                                }}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>raise a ticket</span>
                            </div>
                            <div
                                className="dashboard__innerNavigation"
                                onClick={e => {
                                    e.preventDefault()

                                    history.push('/addSchedule')
                                }}
                            >
                                <FontAwesomeIcon icon={faCalendarPlus} />
                                <span>create schedule</span>
                            </div>
                            <div
                                className="dashboard__innerNavigation"
                                onClick={e => {
                                    e.preventDefault()

                                    history.push('/allEmployee')
                                }}
                            >
                                <FontAwesomeIcon icon={faClipboardCheck} />
                                <span>app authorization</span>
                            </div>
                        </div>
                    </div>

                    {/* 3 boxes of tickets and schedule details */}
                    <div className="dashboard__infoList">
                        <div className="dashboard__ticketsRaised">
                            <h4>tickets raised by you</h4>
                            <div className="dashboard__infoButtons">
                                <button onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'RequestedBy' },
                          });
                                }}>view all</button>
                                <ExportExcelFile
                                    csvData={raiseByYou}
                                    fileName='TicketsRequestedByYou'
                                />
                            </div>
                            <div className="dashboard__infoHeading">
                                <span>t</span>
                                <span>tickets</span>
                                <span>assignee</span>
                                <span>priority</span>
                                <span>status</span>
                            </div>
                            {raiseByYou.slice(0, 5).map((item, index) => {
                                return <div key={index} className="dashboard__infoData">
                                    <FontAwesomeIcon
                                        className='general'
                                        icon={faShareAlt}
                                    />
                                    <span>{item.ticketID}</span>
                                    <span>{item.assignTo.employeeName}</span>
                                    <span>
                                        <FontAwesomeIcon
                                            className='medium'
                                            icon={faArrowUp}
                                        />
                                        {item.priority}
                                    </span>
                                    <span className={item.status.toLowerCase()}>
                                        {item.status}
                                    </span>
                                </div>
                            })}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    className='general'
                                    icon={faShareAlt}
                                />
                                <span>AS21000001</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        className='medium'
                                        icon={faArrowUp}
                                    />
                                    Medium
                                </span>
                                <span className='open'>
                                    open
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faTools}
                                    className='repair'
                                />
                                <span>AS21000002</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className='low'
                                    />
                                    low
                                </span>
                                <span className='closed'>
                                    closed
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faRedoAlt}
                                    className='replace'
                                />
                                <span>AS21000003</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className='high'
                                    />
                                    High
                                </span>
                                <span className='in-progress'>
                                    in-progress
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faTools}
                                    className='repair'
                                />
                                <span>AS21000004</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className='low'
                                    />
                                    low
                                </span>
                                <span className='closed'>
                                    closed
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    className='general'
                                    icon={faShareAlt}
                                />
                                <span>AS21000005</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        className='medium'
                                        icon={faArrowUp}
                                    />
                                    Medium
                                </span>
                                <span className='open'>
                                    open
                                </span>
                            </div> */}
                        </div>
                        <div className="dashboard__ticketsAssigned">
                            <h4>tickets assigned to you</h4>
                            <div className="dashboard__infoButtons">
                                <button onClick={()=>{
                                    history.push({
                            pathname: "/allTicket",
                            state: { data: 'AssignTo' },
                          });
                                }}>view all</button>
                                <ExportExcelFile
                                    csvData={assignedToYou}
                                    fileName='TicketsAssignedToYou'
                                />
                            </div>
                            <div className="dashboard__infoHeading">
                                <span>t</span>
                                <span>tickets</span>
                                <span>requested by</span>
                                <span>priority</span>
                                <span>status</span>
                            </div>
                            {assignedToYou.slice(0, 5).map((item, index) => {
                                return <div key={index} className="dashboard__infoData">
                                    <FontAwesomeIcon
                                        className='general'
                                        icon={faShareAlt}
                                    />
                                    <span>{item.ticketID}</span>
                                    <span>{item.requestedBy.employeeName}</span>
                                    <span>
                                        <FontAwesomeIcon
                                            className='medium'
                                            icon={faArrowUp}
                                        />
                                        {item.priority}
                                    </span>
                                    <span className={item.status.toLowerCase()}>
                                        {item.status}
                                    </span>
                                </div>
                            })}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    className='general'
                                    icon={faShareAlt}
                                />
                                <span>AS21000005</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        className='medium'
                                        icon={faArrowUp}
                                    />
                                    Medium
                                </span>
                                <span className='open'>
                                    open
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faTools}
                                    className='repair'
                                />
                                <span>AS21000004</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className='low'
                                    />
                                    low
                                </span>
                                <span className='closed'>
                                    closed
                                </span>
                            </div>
                            <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faRedoAlt}
                                    className='replace'
                                />
                                <span>AS21000003</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className='high'
                                    />
                                    High
                                </span>
                                <span className='in-progress'>
                                    in-progress
                                </span>
                            </div>
                            <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faRedoAlt}
                                    className='replace'
                                />
                                <span>AS21000002</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className='high'
                                    />
                                    High
                                </span>
                                <span className='in-progress'>
                                    in-progress
                                </span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <FontAwesomeIcon
                                    icon={faTools}
                                    className='repair'
                                />
                                <span>AS21000001</span>
                                <span>kavita gohil</span>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className='low'
                                    />
                                    low
                                </span>
                                <span className='closed'>
                                    closed
                                </span>
                            </div> */}
                        </div>
                        <div className="dashboard__maintenanceAssigned">
                            <h4>maintenance assigned to you</h4>
                            <div className="dashboard__infoButtons">
                                <button onClick={()=>{
                                    history.push({
                            pathname: "/allSchedule",
                            state: { data: 'AssignTo' },
                          });
                                }}>view all</button>
                                <button>export</button>
                            </div>
                            <div className="dashboard__infoHeading">
                                <span>schedule</span>
                                <span>status</span>
                                <span>dom</span>
                            </div>
                            {maintainence.slice(0, 5).map((item, index) => {
                                return <div key={index} className="dashboard__infoData">
                                    <span>{item.scheduleID}</span>
                                    <span className={item.status.toLowerCase()}>
                                        {item.status}
                                    </span>
                                    <span>{item.date.slice(0, 10)}</span>
                                </div>
                            })}
                            {/* <div className="dashboard__infoData">
                                <span>AS21000001</span>
                                <span className='in-progress'>
                                    in-progress
                                </span>
                                <span>27-Jan-2021</span>
                            </div>
                            <div className="dashboard__infoData">
                                <span>AS21000002</span>
                                <span className='closed'>
                                    closed
                                </span>
                                <span>27-Jan-2021</span>
                            </div>
                            <div className="dashboard__infoData">
                                <span>AS21000003</span>
                                <span className='in-progress'>
                                    in-progress
                                </span>
                                <span>27-Jan-2021</span>
                            </div>
                            <div className="dashboard__infoData">
                                <span>AS21000004</span>
                                <span className='open'>
                                    open
                                </span>
                                <span>27-Jan-2021</span>
                            </div> */}
                            {/* <div className="dashboard__infoData">
                                <span>AS21000005</span>
                                <span className='closed'>
                                    closed
                                </span>
                                <span>27-Jan-2021</span>
                            </div> */}
                        </div>
                    </div>

                    {/* 4boxes of Charts */}
                    <div className="dashboard__charts">
                        {/* <div className="dashboard__stackedBarChart">
                            <div className="dashboard__chartHeading">
                                <span>tickets raised by location</span>
                                <button>export</button>
                            </div>
                            <StackedBarChart tickets={tickets} />
                        </div> */}
                        <div className="dashboard__pieChart">
                            <div className="dashboard__chartHeading">
                                <span>tickets raised by priority</span>
                                <button>export</button>
                            </div>
                            <PieChart tickets={tickets} />
                        </div>
                        <div className="dashboard__barChart">
                            <div className="dashboard__chartHeading">
                                <span>ticket as per the status</span>
                                <button>export</button>
                            </div>
                            <BasicBarChart tickets={tickets} />
                        </div>
                        {/* <div className="dashboard__lineChart">
                            <div className="dashboard__chartHeading">
                                <span>tickets raised in last week</span>
                                <button>export</button>
                            </div>
                            <LineChart tickets={tickets} />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard