import React from "react"
import '../Styles/Choice.css'
import {useStateValue} from "../Functionalities/StateProvider";
import {useHistory} from 'react-router-dom'

function Choice({choiceID, choiceName}) {

    const [{}, dispatch] = useStateValue()
    const history = useHistory()

    const chose = e => {
        e.preventDefault()
        // When any factory gets selected then this selected is getting saved in Context API else the location gets selected and is saved in the Context API
        if(choiceName ==='Thane' || choiceName === 'Hoshiarpur' || choiceName === 'Jaunpur') {
            dispatch({
                type: 'SELECTED_FACTORY',
                factory: [choiceID, choiceName],
            })
            history.push('/factory/location')
        } else {
            dispatch({
                type: 'SELECTED_LOCATION',
                location: [choiceID, choiceName],
            })
            history.push('/dashboard')
        }
    }

    // Choice Box in Select Factory Page and Select Location Page for admins and Super-admins
    return (
        <div className='choice' onClick={chose}>
            <h3>{choiceName}</h3>
        </div>
    )
}

export default Choice