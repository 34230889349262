// Initial State of Context API

export const initialState = {
    employee: {},
    factory: [],
    location: [],
    selectedFactoryDetails: [],
    selectedLocationDetails: [],
    singleEmployeeInfo: {},
    singleLocationInfo: {},
    singleMachineInfo: {},
    singleTicketInfo: {},
    singleScheduleInfo: {},
    taskList: [],
    taskListUpdate: []


    
}

// Reducer of Context API

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGGED_EMPLOYEE':
            return {
                ...state,
                employee: action.user
            }
            case 'taskList':
                return {
                    ...state,
                    taskList: action.taskList
                }
                case 'taskListUpdate':
                return {
                    ...state,
                    taskListUpdate: action.taskListUpdate
                }
        case 'SET_FACTORY':
            return {
                ...state,
                factory: action.factory
            }
            case 'ALL_LOCATION':
                return {
                    ...state,
                    allLocation: action.allLocation
                }
        case 'SET_LOCATION':
            return {
                ...state,
                location: action.location
            }

        case 'USER_LOGGED_OUT':

            localStorage.clear()

            return {
                ...state,
                employee: {}
            }

        case 'SELECTED_FACTORY':
            localStorage.setItem('selected_factory', action.factory)
            
            return {
                ...state,
                selectedFactoryDetails: action.factory
            }

        case 'SELECTED_LOCATION':

            localStorage.setItem('selected_location', action.location)
            
            return {
                ...state,
                selectedLocationDetails: action.location
            }

        // For Saving Employee Data for Data Persistency
        case 'SINGLE_EMPLOYEE':

            localStorage.setItem('single-employee', JSON.stringify(action.employee))

            return {
                ...state,
                singleEmployeeInfo: action.employee
            }

        // For Saving Location Data for Data Persistency
        case 'SINGLE_LOCATION':
            localStorage.setItem('single-location', JSON.stringify(action.location))

            return {
                ...state,
                singleLocationInfo: action.location
            }

        // For Saving Machine Data for Data Persistency
        case 'SINGLE_MACHINE':

            localStorage.setItem('single-machine', JSON.stringify(action.machine))

            return {
                ...state,
                singleMachineInfo: action.machine
            }

        // For Saving Ticket Data for Data Persistency
        case 'SINGLE_TICKET':

            localStorage.setItem('single-ticket', JSON.stringify(action.ticket))

            return {
                ...state,               
                singleTicketInfo: action.ticket
            }

        // For Saving Schedule Data for Data Persistency
        case 'SINGLE_SCHEDULE':

            localStorage.setItem('single-schedule', JSON.stringify(action.schedule))

            return {
                ...state,
                singleScheduleInfo: action.schedule
            }
        

        default:
            return state
    }
}

export default reducer