import React, {createContext, useContext, useReducer} from "react"

// Creating Universal Store for Context API
export const StateContext = createContext()

// Creating Provider for providing the store to every component and functions using Context API
export const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
)

// Exporting the store using Context
export const useStateValue = () => useContext(StateContext)