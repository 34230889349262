import React, { useState } from 'react'
import '../Styles/Sidebar.css'
import { NavLink, useHistory } from 'react-router-dom'
import assemblyLogo from '../Assets/assembly-location-logo 2.png'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAddressBook,
    faAlignJustify, faBezierCurve,
    faCalendarPlus,
    faClipboardList,
    faCog,
    faCogs,
    faEdit,
    faMoneyBill,
    faPlusSquare,
    faSignOutAlt,
    faUserAlt,
    faUsers
} from '@fortawesome/free-solid-svg-icons'
import { useStateValue } from "../Functionalities/StateProvider"
import { IconButton } from '@material-ui/core'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'

function Sidebar() {

    const history = useHistory()

    const [{ employee }, dispatch] = useStateValue()
    // console.log(employee);

    // For Collapsing Sidebar
    const [isActive, setIsActive] = useState(false)

    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory')?.split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location')?.split(',')

    // Toggling sidebar
    const sidebarToggle = e => {
        e.preventDefault()

        console.log(isActive);
        setIsActive(!isActive)
        console.log(isActive);
    }

    if (employee.status === "Pending") {
        return (<><div className={`sidebar ${isActive && 'active'}`}>
            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>my account</p>
            </div>
            <NavLink
                to='/profile'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faAddressBook} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>my profile</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <NavLink
                to='/'
                className='sidebar__Link'
            >
                <div
                    className="sidebar__list"
                    onClick={e => {
                        e.preventDefault()

                        history.push('/')

                        dispatch({
                            type: 'USER_LOGGED_OUT'
                        })
                    }}>
                    <FontAwesomeIcon className='sidebar__icon' icon={faSignOutAlt} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>logout</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink></div></>)
    }

    // Sidebar on every logged pages
    return (
        <div className={`sidebar ${isActive && 'active'}`}>

            {/* Sidebar Details section */}
            <div className="sidebar__header">
                <div className="sidebar__logo">
                    <img src={assemblyLogo} alt="" />
                </div>
                <div className={`sidebar__headerDetails ${isActive && 'active'}`}>
                    <p className="locationFont">{selectedLocationDetails[1]}</p>
                    {
                        selectedFactoryDetails ? (
                            <p className="factoryFont">factory - {selectedFactoryDetails[1]}</p>
                        ) : ('')
                    }
                </div>
            </div>

            {/* Sidebar list starts */}
            <NavLink
                to='/dashboard'
                className='sidebar__Link'
                activeClassName="sidebar__selectedLink"
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faAlignJustify} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>dashboard</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>tickets</p>
            </div>
            <NavLink
                to='/addTicket'
                className='sidebar__Link'
                activeClassName="sidebar__selectedLink"
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faEdit} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>raise a ticket</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <NavLink
                to='/allTicket'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faMoneyBill} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>all tickets</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>preventive maintenance</p>
            </div>
            {(employee.isAdmin || employee.maintenanceType === "Maintenance Manager" || "Maintenance Personnel") && <NavLink
                to='/addSchedule'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faCalendarPlus} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>create schedule</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>}
            <NavLink
                to='/allSchedule'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faClipboardList} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>all schedules</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>employee directory</p>
            </div>
            <NavLink
                to='/allEmployee'
                className='sidebar__Link'
                activeClassName="sidebar__selectedLink"
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faUsers} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>all employees</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            {employee.isAdmin && <NavLink
                to={'/addEmployee'}
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faUserAlt} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>add employees</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>}
            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>location master</p>
            </div>
            <NavLink
                to='/allLocation'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faBezierCurve} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>all locations</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            {employee.isAdmin && <NavLink
                to='/addLocation'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faPlusSquare} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>add location</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>}

            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>machine directory</p>
            </div>
            <NavLink
                to='/allMachine'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faCogs} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>all machines</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            {employee.isAdmin && <NavLink
                to='/addMachine'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faCog} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>add machine</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>}

            <div className="sidebar__divisor">
                <p className={`sidebar__iconLabel ${isActive && 'active'}`}>my account</p>
            </div>
            <NavLink
                to='/profile'
                className='sidebar__Link'
                activeClassName='sidebar__selectedLink'
            >
                <div className="sidebar__list">
                    <FontAwesomeIcon className='sidebar__icon' icon={faAddressBook} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>my profile</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            <NavLink
                to='/'
                className='sidebar__Link'
            >
                <div
                    className="sidebar__list"
                    onClick={e => {
                        e.preventDefault()

                        history.push('/')

                        dispatch({
                            type: 'USER_LOGGED_OUT'
                        })
                    }}>
                    <FontAwesomeIcon className='sidebar__icon' icon={faSignOutAlt} />
                    <h5 className={`sidebar__iconLabel ${isActive && 'active'}`}>logout</h5>
                    <KeyboardArrowRightRoundedIcon />
                </div>
            </NavLink>
            {
                isActive ? (
                    <IconButton onClick={sidebarToggle} className='sidebar__showIcon'>
                        <ChevronRightRoundedIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={sidebarToggle} className='sidebar__hideIcon'>
                        <ChevronLeftRoundedIcon />
                    </IconButton>
                )
            }
        </div>
    )
}

export default Sidebar