import axios from './axios'

export const getMachinesListByLocation = async (setMachineList, store, locationID) => {
    console.log("locationID",locationID)
    await axios
        .get(`machines/${locationID}`)
        .then(response => {
            console.log('machine list by location',locationID,"=>",response)
            const mList = []

            response.data.machines.forEach(m => {
                const singleMachine = {
                    value: m.machineID,
                    label: m.machineName
                }

                mList.push(singleMachine)
            })

            setMachineList(mList)
        }).catch(error => {
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 404 ||
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.response.data.error,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Internal Server Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            } else if (error.request) {
                store.addNotification({
                    title: "Error Occurred!",
                    message: 'Network Error',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else {
                if (error.message) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else if (error.data) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Something went wrong',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            }
        })
}

export const getMachinesByLocation = async (setMachines, store, locationID, limit, page, setPreviousPageShow, setNextPageShow,setLoader) => {
    await axios
        .get(`machines/${locationID}?limit=${limit}&page=${page}`)
        .then(response => {
        setLoader(false)

            console.log('resonse data from all machine',response.data.machines)
            setMachines(response.data.machines)
            if (response.data.next) setNextPageShow(true)
            if (response.data.previous) setPreviousPageShow(true)
        }).catch(error => {
        setLoader(false)

            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 404 ||
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.response.data.error,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Internal Server Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            } else if (error.request) {
                store.addNotification({
                    title: "Error Occurred!",
                    message: 'Network Error',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else {
                if (error.message) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else if (error.data) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Something went wrong',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            }
        })
}