import React, { useEffect, useRef, useState } from "react";
import "../Styles/ViewEmployeeInfo.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import { useStateValue } from "../Functionalities/StateProvider";
import noProfilePicture from "../Assets/manicon 6.png";
import Select from "react-select";
import { Checkbox } from "@material-ui/core";
import axios from "../Functionalities/axios";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { NavLink, useHistory } from "react-router-dom";
import Modal from "react-modal";
import { EditLocation, FlareSharp } from "@material-ui/icons";
import assemblyLogo from "../Assets/assembly-location-logo 2.png";
import { getLocationsListByFactory } from "../Functionalities/getLocation";
import { getFactories } from "../Functionalities/getFactory";
import { useLocation } from "react-router-dom";
function ViewEmployeeInfo() {
  const history = useHistory();
  const locationn = useLocation();
  const dataa = locationn?.state?.data;
  const [state, dispatch] = useStateValue();
  // console.log(employee.employeeID);
  const file = useRef();
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  // Dynamically getting List
  const [factoryList, setFactoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  // Getting previously selected Factory and Location for Data Persistent
  const selectedFactoryDetails = localStorage
    .getItem("selected_factory")
    .split(",");
  const selectedLocationDetails = localStorage
    .getItem("selected_location")
    .split(",");
  const selectedEmployeeDetails = JSON.parse(
    localStorage.getItem("single-employee")
  );

  // For Editing
  const [isEditable, setIsEditable] = useState(false);
  const [editableIsMaintenancePersonnel, setEditableIsMaintenancePersonnel] =
    useState(null);
  const [editableIsMaintenanceManager, setEditableIsMaintenanceManager] =
    useState(null);
  const [editableIsAdmin, setEditableIsAdmin] = useState(null);
  const [editableAppAuth, setEditableAppAuth] = useState("");
  const [deleteEmploye, setDeleteEmploye] = useState(false);
  const [editableName, setEditableName] = useState(
    selectedEmployeeDetails.name
  );
  const [editableEmpID, setEditableEmpID] = useState(
    selectedEmployeeDetails.employeeID
  );
  const [editablePhone, setEditablePhone] = useState(
    selectedEmployeeDetails.phone
  );
  const [editableEmail, setEditableEmail] = useState(
    selectedEmployeeDetails.email
  );
  const [editableFactory, setEditableFactory] = useState(
    selectedEmployeeDetails.factory
  );
  const [editableLocation, setEditableLocation] = useState(
    selectedEmployeeDetails.location
  );
  const [editableIsMaintenanceType, setEditableIsMaintenanceType] = useState(
    selectedEmployeeDetails.maintenanceType
  );
  const [profileImage, setProfileImage] = useState(
    selectedEmployeeDetails.profilePic
  );

  //All Tickets
  const [tickets, settickets] = useState([]);

  const [data, setData] = useState(null);
  const [name, setName] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [factory, setFactory] = useState(null);
  const [location, setLocation] = useState(null);
  const [maintenanceType, setMaintenanceType] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [authStatus, setAuthStatus] = useState(null);
  const [adminFactories, setAdminFactories] = useState([]);
  const [editData, setEditData] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editAuthStatus, setEditAuthStatus] = useState(null);
  const [editEmployeeID, setEditEmployeeID] = useState(null);
  const [editFactory, setEditFactory] = useState(null);
  const [editFactoryList, setEditFactoryList] = useState(null);
  const [editLocation, setEditLocation] = useState(null);
  const [editLocationList, setEditLocationList] = useState(null);
  const [editAdminFactories, setEditAdminFactories] = useState(null);
  const [editAdminFactoriesList, setEditAdminFactoriesList] = useState(null);
  const [editMaintenanceType, setEditMaintenanceType] = useState(null);
  const [editAdmin, setEditAdmin] = useState(null);
  const [editPhone, setEditPhone] = useState(null);
  const [editEmail, setEditEmail] = useState("");
  const [editOldPassword, setEditOldPassword] = useState("");
  const [editNewPassword, setEditNewPassword] = useState("");
  const [editConfirmPassword, setEditConfirmPassword] = useState("");
  const [editStatus, setEditStatus] = useState(false);

  const [na, setNA] = useState(false);
  const [jaunpur, setJaunpur] = useState(false);
  const [thane, setThane] = useState(false);
  const [hoshiarpur, setHoshiarpur] = useState(false);

  const checkBoxHandle = (name, value) => {
    console.log("name", name, "value", value);
    if (name === "N/A" && value === true) {
      console.log("NA");
      setNA(!na);
      setThane(false);
      setHoshiarpur(false);
      setJaunpur(false);
    } else {
      console.log("NA,else");

      setNA(!na);
    }
    if (name === "thane") {
      console.log("thane");

      setNA(false);
      setThane(!thane);
    }
    if (name === "jaunpur") {
      console.log("jaunpur");

      setNA(false);
      setJaunpur(!jaunpur);
    }
    if (name === "hoshiarpur") {
      console.log("hoshiarpur");

      setNA(false);
      setHoshiarpur(!hoshiarpur);
    }
  };
  useEffect(async () => {
    setEditAdminFactoriesList([
      { label: "N/A", value: "N/A" },
      { label: "Thane", value: "T" },
      { label: "Jaunpur", value: "J" },
      {"label":'Hoshiarpur',"value":'H'},
    ]);

    await getFactories(setEditFactoryList, store);

    if (dataa) {
      console.log("data coming from all emplyee", dataa);

      setEditStatus(true);
    }
  }, []);

  useEffect(() => {
    console.log("get location called", editFactory);
    if (editFactory) {
      if (editFactory[0]?.factoryID) {
        console.log(editFactory[0].factoryID);
        getLocationsListByFactory(
          setEditLocationList,
          store,
          editFactory[0].factoryID
        );
      } else {
        console.log(editFactory[0]?.value);
        getLocationsListByFactory(
          setEditLocationList,
          store,
          editFactory[0]?.value
        );
      }
    }
  }, [editFactory]);
  // employee get
  // update api
  const update = async () => {
    setEditStatus(false);
    let employeeID = selectedEmployeeDetails.employeeID;
    console.log("employeeID", employeeID);
    console.log(
      "editName",
      editName,
      "editEmployeeID",
      editEmployeeID,
      "editFactory",
      editFactory,
      "editLocation",
      editLocation,
      "editMaintenanceType",
      editMaintenanceType,
      "editAdmin",
      editAdmin,
      "editPhone",
      editPhone,
      "editEmail",
      editEmail,
      "editOldPassword",
      editOldPassword
    );
    let location = [];
    let factory = [];

    if (editFactory[0]?.value) {
      console.log("edit factory", editFactory);
      editFactory.map((item) => {
        console.log(item);
        return factory.push({
          factoryID: item.value,
          factoryName: item.label,
        });
      });
    } else {
      console.log("edit factory name", editFactory);
      // factory = editFactory
      editFactory.map((item) => {
        console.log(item);
        return factory.push({
          factoryID: item.factoryID,
          factoryName: item.factoryName,
        });
      });
    }
    if (editLocation[0]?.value) {
      console.log("edit location", editLocation);
      editLocation.map((item) => {
        console.log(item);

        return location.push({
          locationID: item.value,
          locationName: item.label,
        });
      });
    } else {
      console.log("edit location name", editLocation);
      // location = editLocation
      editLocation.map((item) => {
        console.log(item);

        return location.push({
          locationID: item.locationID,
          locationName: item.locationName,
        });
      });
    }
    let employeeIDD = selectedEmployeeDetails.employeeID;
    console.log("employeeID", employeeID);
    console.log("location", location);
    console.log("factory", factory);

    let adminFactoriesList = [];
    if (na !== true) {
      if (thane === true) {
        adminFactoriesList.push({ factoryName: "Thane", factoryID: "T" });
      }
      if (jaunpur === true) {
        adminFactoriesList.push({ factoryName: "Jaunpur", factoryID: "J" });
      }
      if (hoshiarpur === true) {
        adminFactoriesList.push({ factoryName: "Hoshiarpur", factoryID: "H" });
      }
    }
    console.log("adminFactoriesList", adminFactoriesList);

    await axios
      .put(`employees/${employeeIDD}`, {
        name: editName,
        employeeID: editEmployeeID,
        location: location,
        factory: factory,
        phone: "" + editPhone,
        email: { emailID: editEmail.emailID },
        maintenanceType: editMaintenanceType,
        adminFactories: adminFactoriesList,
        // oldPassword: editOldPassword,
        // password: editNewPassword,
        // confirmPassword: editConfirmPassword,
      })
      .then((response) => {
        console.log("response.data after put=>", response.data);
        setData(response.data);
        setName(response.data.name);
        setEmployeeID(response.data.employeeID);
        setFactory(response.data.factory);
        setLocation(response.data.location);
        setMaintenanceType(response.data.maintenanceType);
        setAdmin(response.data.isAdmin);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setAuthStatus(response.data.status);
        setAdminFactories(response.data.adminFactories);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  // setValuesEdit
  const setValuesEdit = () => {
    setEditName(name);
    setEditEmployeeID(employeeID);
    setEditFactory(factory);
    setEditLocation(location);
    setEditMaintenanceType(maintenanceType);
    setEditAdmin(admin);
    setEditPhone(phone);
    setEditEmail(email);
    setEditAuthStatus(authStatus);
    setEditAdminFactories(adminFactories);
  };
  useEffect(async () => {
    let employeeID = selectedEmployeeDetails.employeeID;
    console.log("employeeID", employeeID);
    await axios
      .get(`employees/${employeeID}`)
      .then((response) => {
        console.log("response.data before put=>", response.data);
        setData(response.data);
        setName(response.data.name);
        setEmployeeID(response.data.employeeID);
        setFactory(response.data.factory);
        setLocation(response.data.location);
        setMaintenanceType(response.data.maintenanceType);
        setAdmin(response.data.isAdmin);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setAuthStatus(response.data.status);
        if (response.data.adminFactories !== []) {
          console.log(
            response.data.adminFactories,
            "response.data.adminFactories"
          );
          if (response.data.adminFactories.length === 0) {
            console.log(" N/A true");
            setNA(true);
          } else {
            response.data.adminFactories.map((item) => {
              if (item.factoryName === "Thane") {
                console.log(" thane true");
                setThane(true);
              }
              if (item.factoryName === "Jaunpur") {
                console.log(" Jaunpur true");
                setJaunpur(true);
              }
              if (item.factoryName === "Hoshiarpur") {
                console.log(" Hoshiarpur true");
                setHoshiarpur(true);
              }
            });
            setAdminFactories(response.data.adminFactories);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  }, []);
  const appAuth = [
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Verified",
    },
    {
      value: 3,
      label: "Changes Needed",
    },
    {
      value: 4,
      label: "Disabled",
    },
    {
      value: 5,
      label: "Delete",
    },
  ];

  const maintenanceTypeList = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "Maintenance Personnel",
      label: "Maintenance Personnel",
    },
    {
      value: "Maintenance Manager",
      label: "Maintenance Manager",
    },
  ];

  const adminTypeList = [
    {
      value: "N/A",
      label: "N/A",
    },
    {
      value: "Yes",
      label: "Yes",
    },
  ];
  const setEditableLocationFunc = async (e) => {
    let locationListEdit = [];
    await e.forEach((locationObject) => {
      locationListEdit.push({
        locationID: locationObject.value,
        locationName: locationObject.label,
      });
    });
    setEditableLocation(locationListEdit);
  };
  useEffect(() => {
    if (selectedEmployeeDetails.isAdmin) {
      setEditableIsAdmin("YES");
    } else {
      setEditableIsAdmin("N/A");
    }
    const getFactories = () => {
      axios
        .get("factories")
        .then((response) => {
          let newFactoryList = [];
          console.log("response.data.results factory", response.data);
          response.data.results.map((factory) => {
            const singleFactory = {
              value: factory.factoryID,
              label: factory.factoryName,
            };

            newFactoryList.push(singleFactory);
          });

          setFactoryList(newFactoryList);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 400 ||
              error.response.status === 401 ||
              error.response.status === 404 ||
              error.response.status === 403 ||
              error.response.status === 500
            ) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data.error,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Internal Server Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          } else if (error.request) {
            store.addNotification({
              title: "Error Occurred!",
              message: "Network Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            if (error.message) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else if (error.data) {
              store.addNotification({
                title: "Error Occurred!",
                message: error.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: "Something went wrong",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          }
        });
    };

    getFactories();
  }, [editableIsAdmin]);

  // Location List
  useEffect(() => {
    // console.log("delete employee modal statrus",deleteEmploye)
    const getLocations = () => {
      if (selectedFactoryDetails[0] !== "") {
        // console.log(selectedFactoryDetails[0]);
        axios
          .get(`locations/${selectedFactoryDetails[0]}`)
          .then((response) => {
            // console.log(response.data.locations);
            let newLocationList = [];
            response.data.locations.map((location) => {
              const singleLocation = {
                value: location.locationID,
                label: location.locationName,
              };

              newLocationList.push(singleLocation);
            });

            setLocationList(newLocationList);
            // console.log(locationList);
          })
          .catch((error) => {
            if (error.response) {
              if (
                error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404 ||
                error.response.status === 403 ||
                error.response.status === 500
              ) {
                store.addNotification({
                  title: "Error Occurred!",
                  message: error.response.data.error,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              } else {
                store.addNotification({
                  title: "Error Occurred!",
                  message: "Internal Server Error",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              }
            } else if (error.request) {
              store.addNotification({
                title: "Error Occurred!",
                message: "Network Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            } else {
              if (error.message) {
                store.addNotification({
                  title: "Error Occurred!",
                  message: error.message,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              } else if (error.data) {
                store.addNotification({
                  title: "Error Occurred!",
                  message: error.data,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              } else {
                store.addNotification({
                  title: "Error Occurred!",
                  message: "Something went wrong",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              }
            }
          });
      }
    };

    getLocations();
  }, []);

  useEffect(() => {
    if (profilePicture) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(profilePicture);
    } else {
      setPreviewImage(null);
    }
  }, [profilePicture]);

  const deleteEmployee = (id) => {
    setDeleteEmploye(false);
    axios
      .delete(`employees/${id}`)
      .then((response) => {
        //    console.log('data of delete employee',data)
        alert("Employee Deleted Successfully");
        // history.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };
  const saveEmployeDetail = (id) => {
    console.log(id);
    const body = {
      adminEmployeeID: state.employee.employeeID,
      status: editableAppAuth,
    };
    axios
      .patch(`/employees/${id}`, body)
      .then((res) => {console.log(res.data);setAuthStatus(res.data.status)})
      .catch((err) => console.log(err.message));
    // setDeleteEmploye(true);
    // //  console.log("saveEmployeDetail called")
    // if (editableAppAuth == 'Delete') {
    //     //  console.log("editableAppAuth called")

    //     setDeleteEmploye(true);
    // }
  };

  const getTicketsCount = () => {
    console.log(editableEmpID, "hello world");
    axios
      .get("/tickets")
      .then((res) => settickets(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTicketsCount();
  }, []);

  const temporaryTickets = [...tickets];
  const totalRequested = temporaryTickets.filter(
    (i) => i.requestedBy.employeeName === selectedEmployeeDetails.name
  );
  // console.log(totalRequested, "total requested");

  const temporaryTicketsTwo = [...tickets];
  const totalAssigned = temporaryTicketsTwo.filter(
    (i) => i.assignTo.employeeName === selectedEmployeeDetails.name
  );
  // console.log(totalAssigned, "totalAssigned");

  console.log(factoryList, "Factory List");
  console.log(locationList, "Location List");
  return (
    <div className="viewEmployeeInfo">
      {console.log("selectedEmployeeDetails", selectedEmployeeDetails)}
      {console.log("LOCATION defalut", selectedEmployeeDetails.location)}
      {console.log(
        "naasti",
        na,
        "thane",
        thane,
        "jaunpur",
        jaunpur,
        "hoshiyarpur",
        hoshiarpur
      )}
      {/* Header */}
      <DashboardHeader />
      <div className="viewEmployeeInfo__mainBody">
        {/* Notifications */}
        <ReactNotification />

        {/* Sidebar */}
        <Sidebar />
        <div className="viewEmployeeInfo__container">
          {/* Navigation Breadcrumb */}
          <div className="viewEmployeeInfo__containerPath">
            <span>
              {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} /{" "}
              {selectedEmployeeDetails.name}
            </span>
          </div>

          {/* Heading */}
          <h1>Employee Details</h1>

          <div
            className="viewEmployeeInfo__form"
            // onSubmit={(e) => e.preventDefault()}
          >
            {/* Photo Section */}
            <div className="viewEmployeeInfo__photo">
              <label>employee photo</label>
              {editStatus ? (
                profileImage !== "NA" ? (
                  <div className="viewEmployeeInfo__image">
                    <img
                      src={`${profileImage}`}
                      className="viewEmployeeInfo__noProfilePicture"
                    />
                  </div>
                ) : (
                  <div className="viewEmployeeInfo__image">
                    <img
                      src={`https://ui-avatars.com/api/?name=${name}`}
                      className="viewEmployeeInfo__noProfilePicture"
                    />
                  </div>
                )
              ) : profileImage !== "NA" ? (
                <div className="viewEmployeeInfo__image">
                  <img
                    src={`${profileImage}`}
                    className="viewEmployeeInfo__noProfilePicture"
                  />
                </div>
              ) : (
                <div className="viewEmployeeInfo__image">
                  <img
                    src={`https://ui-avatars.com/api/?name=${name}`}
                    className="viewEmployeeInfo__noProfilePicture"
                  />
                </div>
              )}

              {/* App Authorization Permission Section */}
              {state.employee.isAdmin && (
                <div className="viewEmployeeInfo__auth">
                  {/* <p className="viewEmployeeInfo__label">app authorization</p> */}
                  <div className={`viewEmployeeInfo__appAuth ${authStatus}`}>
                    {authStatus}
                  </div>
                  <div className="viewEmployeeInfo__authSelect">
                    <Select
                      options={appAuth}
                      placeholder="Mark as"
                      className="viewEmployeeInfo__select"
                      onChange={(e) => setEditableAppAuth(e.label)}
                    />
                    <button
                      className="viewEmployeeInfo__saveBtn"
                      onClick={() => {
                        // e.preventDefault();
                        saveEmployeDetail(selectedEmployeeDetails.employeeID);
                      }}
                    >
                      save
                    </button>
                  </div>
                  {editableAppAuth === "Changes Needed" ? (
                    <input
                      type="textarea"
                      className="viewEmployee__changesBox heightOfInputBox"
                      placeholder="Enter your required changes..."
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            {/* Form Section */}
            <div className="viewEmployeeInfo__details">
              <div className="viewEmployeeInfo__information">
                <div className="profile__info">
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Name</label>
                      </div>
                      {editStatus ? (
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="heightOfInputBox"
                            defaultValue={name}
                            onChange={(e) => setEditName(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="col-md-8">
                          <p>{name}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Employee ID</label>
                      </div>
                      {editStatus ? (
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="heightOfInputBox"
                            defaultValue={employeeID}
                            onChange={(e) => setEditEmployeeID(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="col-md-8">
                          <p>{employeeID}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Factory</label>
                      </div>
                      {editStatus ? (
                        <div className="col-md-8">
                          {factory ? (
                            <Select
                              options={editFactoryList}
                              placeholder="Select factory"
                              className="profile__select"
                              defaultValue={{ label: factory[0].factoryName }}
                              onChange={(e) => setEditFactory([e])}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <div className="col-md-8">
                          <span>{selectedFactoryDetails[1]}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Location</label>
                      </div>
                      {editStatus ? (
                        <div className="col-md-8">
                          {console.log(
                            "editLocation",
                            editLocation?.map((item) => {
                              return console.log(item);
                            }),
                            "editLocationList",
                            editLocationList
                          )}
                          <Select
                            options={editLocationList}
                            placeholder="Select Location"
                            className="profile__select"
                            isMulti
                            defaultValue={editLocation?.map((item) => {
                              return {
                                label: item.locationName,
                                value: item.locationID,
                              };
                            })}
                            onChange={(e) => setEditLocation(e)}
                          />
                        </div>
                      ) : (
                        <div className="col-md-8">
                          {location?.map((item, key) => {
                            return (
                              <div style={{ marginBottom: 5 }}>
                                <img
                                  src={assemblyLogo}
                                  alt={item.locationName}
                                />
                                {item.locationName}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Maintenance Type</label>
                      </div>
                      <div className="col-md-8">
                        {editStatus ? (
                          <Select
                            options={maintenanceTypeList}
                            placeholder="Select maintenance type"
                            className="profile__select"
                            defaultValue={{ label: maintenanceType }}
                            onChange={(e) => setEditMaintenanceType(e.label)}
                          />
                        ) : (
                          <p>{maintenanceType}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        {" "}
                        <label>Admin role</label>
                      </div>
                      <div className="col-md-8">
                        {console.log(
                          "adminFactories",
                          adminFactories.length,
                          "editLocationList",
                          editLocationList,
                          "editLocation",
                          editLocation
                        )}
                        {editStatus ? (
                          <div style={{ marginLeft: 0 }}>
                            <div className="row">
                              <div className="col-md-2">
                                {" "}
                                <input
                                  type="checkbox"
                                  className="heightOfInputBox"
                                  checked={na}
                                  onChange={() => {
                                    checkBoxHandle("N/A", !na);
                                  }}
                                  // onChange={(e) => {checkBoxHandle('N/A',e.target.value)}}
                                />
                              </div>
                              <div className="col-md-10">
                                <label>N/A</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                {" "}
                                <input
                                  type="checkbox"
                                  className="heightOfInputBox"
                                  checked={jaunpur}
                                  onChange={() => {
                                    checkBoxHandle("jaunpur", !jaunpur);
                                  }}
                                />
                              </div>
                              <div className="col-md-10">
                                <label>Jaunpur</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                {" "}
                                <input
                                  type="checkbox"
                                  className="heightOfInputBox"
                                  checked={thane}
                                  onChange={() => {
                                    checkBoxHandle("thane", !thane);
                                  }}
                                />
                              </div>
                              <div className="col-md-10">
                                <label>Thane</label>
                              </div>
                            </div>{" "}
                            <div className="row">
                              <div className="col-md-2">
                                {" "}
                                <input
                                  type="checkbox"
                                  className="heightOfInputBox"
                                  checked={hoshiarpur}
                                  onChange={() => {
                                    checkBoxHandle("hoshiarpur", !hoshiarpur);
                                  }}
                                />
                              </div>
                              <div className="col-md-10">
                                <label>Hoshiarpur</label>
                              </div>
                            </div>
                          </div>
                        ) : adminFactories.length > 0 ? (
                          adminFactories?.map((item) => {
                            return <p>{item.factoryName}</p>;
                          })
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Phone No.</label>
                      </div>
                      <div className="col-md-8">
                        {editStatus ? (
                          <input
                            type="text"
                            className="heightOfInputBox"
                            defaultValue={phone}
                            onChange={(e) => {
                              setEditPhone(e.target.value);
                            }}
                          />
                        ) : (
                          <p>{phone}</p>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8">
                        {editStatus ? (
                          <input
                            className="heightOfInputBox"
                            type="text"
                            defaultValue={editEmail?.emailID}
                            onChange={(e) => {
                              setEditEmail({ emailID: e.target.value });
                            }}
                          />
                        ) : (
                          <p>{email?.emailID}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      {editStatus ? (
                        <>
                          <div className="col-md-4">
                            <label>Old Password</label>
                          </div>
                          <div className="col-md-8">
                            <input
                              className="heightOfInputBox"
                              type="text"
                              placeholder="Old Password"
                              onChange={(e) => {
                                setEditOldPassword(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      {editStatus ? (
                        <>
                          <div className="col-md-4">
                            <label>New Password</label>
                          </div>
                          <div className="col-md-8">
                            <input
                              className="heightOfInputBox"
                              type="text"
                              placeholder="New Password"
                              onChange={(e) => {
                                setEditNewPassword(e.target.value);
                              }}
                            />
                          </div>{" "}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="profile__input">
                    <div className="row rowWidth">
                      {" "}
                      {editStatus ? (
                        <>
                          <div className="col-md-4">
                            {" "}
                            <label>Confirm New Password</label>
                          </div>
                          <div className="col-md-8">
                            <input
                              className="heightOfInputBox"
                              type="text"
                              placeholder="Confirm New Password"
                              onChange={(e) => {
                                setEditConfirmPassword(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {state.employee.isAdmin && (
                    <div className="profile__input">
                      {editStatus ? (
                        <>
                          <button
                            className="buttImport"
                            onClick={() => update()}
                          >
                            Save
                          </button>{" "}
                          <button onClick={() => setEditStatus(false)}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className="buttImport buttImportV2"
                          onClick={() => {
                            // e.preventDefault();
                            setEditStatus(true);
                            setValuesEdit();
                          }}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {/* modal start here  */}
                <Modal
                  className="delete__modal"
                  isOpen={deleteEmploye}
                  closeTimeoutMS={20}
                  shouldCloseOnOverlayClick={true}
                >
                  <div style={{ width: "100%" }}>
                    <h4>do you want to delete this employee?</h4>
                    <div className="row rowWidth">
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        {" "}
                        <button
                          onClick={() => {
                            // e.preventDefault();
                            setDeleteEmploye(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <button
                          onClick={() => {
                            // e.preventDefault();
                            deleteEmployee(editableEmpID);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* modal end here */}
                {/* 2 boxes */}
                <div className="viewEmployeeInfo__group">
                  <div className="viewEmployeeInfo__box">
                    <div className="viewEmployeeInfo__boxBody">
                      <div className="viewEmployeeInfo__boxHeading">
                        tickets Requested
                      </div>
                      <div className="viewEmployeeInfo__boxValue">
                        {totalRequested.length}
                      </div>
                    </div>
                    <div className="viewEmployeeInfo__boxFooter">
                      <span>view all</span>
                      <span>export</span>
                    </div>
                  </div>
                  <div className="viewEmployeeInfo__box">
                    <div className="viewEmployeeInfo__boxBody">
                      <div className="viewEmployeeInfo__boxHeading">
                        tickets Assigned
                      </div>
                      <div className="viewEmployeeInfo__boxValue">
                        {totalAssigned.length}
                      </div>
                    </div>
                    <div className="viewEmployeeInfo__boxFooter">
                      <span>view all</span>
                      <span>export</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEmployeeInfo;
