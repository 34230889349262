import axios from './axios'

export const getEmployee = (setEmployees, store, factoryID, limit, page,setNextPageShow, setPreviousPageShow,setLoader) => {
    axios
        .get(`employees/factory/${factoryID}?limit=${limit}&page=${page}`)
        .then(response => {
            setLoader(false)
            console.log('data of employees',response)
            setEmployees(response.data.employees)
            if (response.data.next) setNextPageShow(true)
            if (response.data.previous) setPreviousPageShow(true)
        }).catch(error => {
            setLoader(false)
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 404 ||
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.response.data.error,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Internal Server Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            } else if (error.request) {
                store.addNotification({
                    title: "Error Occurred!",
                    message: 'Network Error',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else {
                if (error.message) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else if (error.data) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Something went wrong',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            }
        })
}