import React, { useEffect, useState } from 'react'
import '../Styles/AddTicket.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import Select from "react-select"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {DropzoneArea} from 'material-ui-dropzone'
import Loader from "react-loader-spinner";

import {
    faTools,
    faRedoAlt,
    faShareAlt,
    faArrowUp,
    faArrowDown,
    faPlusSquare,
    faCamera
} from '@fortawesome/free-solid-svg-icons'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useStateValue } from '../Functionalities/StateProvider'
import { useHistory } from 'react-router'
import axios from '../Functionalities/axios'
import {dateFormat} from '../Functionalities/dateFormat'
import { getMachinesListByLocation } from '../Functionalities/getMachine'
import { getAssigneeList } from '../Functionalities/getMaintenancePersons'
import { useLocation } from "react-router-dom";
import { DateFormatNumeric } from '../Functionalities/dateFormatNumeric'
function UpdateTicket() {
const [files,setFiles] = useState([])
const location = useLocation();
const data = location.state.data;
const [loader,setLoader] = useState(false)

    const [state, dispatch] = useStateValue()
    const history = useHistory()
    const date = new Date().getDate().toString() + '-'
        + new Date().getMonth().toString() + '-'
        + new Date().getFullYear().toString()
    const [machine, setMachine] = useState(data.machine)
    const [issueType, setIssueType] = useState(data.issueType)
    const [faultDefinition, setFaultDefinition] = useState(data.faultDefinition)
    const [faultDescription, setFaultDescription] = useState(data.faultDescription)
    const [priority, setPriority] = useState(data.priority)
    const [assignee, setAssignee] = useState(data.assignTo)
    // const [assignee, setAssignee] = useState({})


    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')

    // Dynamically creating Machines List and Assignee List
    const [machineList, setMachineList] = useState([])
    const [assigneeList, setAssigneeList] = useState([])
   const handleChange =(files)=>{
        setFiles(files)
      }
    useEffect(() => {
        getMachinesListByLocation(setMachineList, store, selectedLocationDetails[0])
    }, [])

    // Issue List
    const issueList = [
        {
            value: 'Repair',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "skyblue" }} className='addTicket__icon' icon={faTools} />
                <span>Repair</span>
            </div>
        },
        {
            value: 'Replace',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "green" }} className='addTicket__icon' icon={faRedoAlt} />
                <span>Replace</span>
            </div>
        },
        {
            value: 'General',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "purple" }} className='addTicket__icon' icon={faShareAlt} />
                <span>General</span>
            </div>
        }
    ]

    // Priority List
    const priorityList = [
        {
            value: 'High',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "#c71800" }} className='addTicket__icon' icon={faArrowUp} />
                <span>High</span>
            </div>
        },
        {
            value: 'Medium',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "#f98917" }} className='addTicket__icon' icon={faArrowUp} />
                <span>Medium</span>
            </div>
        },
        {
            value: 'Low',
            label: <div className='addTicket__issueSelect'>
                <FontAwesomeIcon style={{ color: "#188f01" }} className='addTicket__icon' icon={faArrowDown} />
                <span>Low</span>
            </div>
        }
    ]

    // Fetching Maintenance Personnel and Maintenance Manager List
    useEffect(() => {
        getAssigneeList(setAssigneeList, store, selectedLocationDetails[0])
    }, [])

    // Adding Ticket to Database
    const EditTicket = e => {
        setLoader(true)
        e.preventDefault()
console.log(
    machine,'machine',
    issueType,'issueType',
    faultDefinition,'faultDefinition',
    faultDescription,'faultDescription',
    priority,'priority',
    assignee,'assignee'
    )
        if (
            machine && 
            issueType && 
            faultDefinition &&
            faultDescription && 
            priority && 
            assignee
        ) {
            // TODO: fields name should be Fault Definition and Fault Description respectively
            console.log('api hits')
            axios
                .put(`tickets/${data.ticketID}`, {
                    // "requestedBy": {
                    //     "employeeID": state.employee.employeeID,
                    //     "employeeName": state.employee.name
                    // },
                    "assignTo": assignee,
                    // "location": {
                    //     "locationID": selectedLocationDetails[0],
                    //     "locationName": selectedLocationDetails[1]
                    // },
                    "machine": machine,
                    "issueType": issueType,
                    "faultDescription":  faultDescription,
                    "faultDefinition": faultDefinition,
                    "priority": priority,
                    // "images": []
                }).then(response => {
            console.log('create tickets called',response.data)
setLoader(false)
                    store.addNotification({
                        title: "Ticket Updated!",
                        message: `Your Ticket ID is ${response.data.ticketID}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })

                    dispatch({
                        type: 'SINGLE_TICKET',
                        ticket: response.data
                    })

                    history.push('/viewTicket')
                }).catch(error => {
                    setLoader(false)
                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                }).finally(() => {
                    // setIssueType('')
                    // setFaultDefinition('')
                    // setFaultDescription('')
                    // setPriority('')
                })
        } else {
            if (machine === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select a Machine.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (issueType === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Issue Type.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (faultDefinition === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Fault Definition.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (faultDescription === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Fault Description.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (priority === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your Priority.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (assignee === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter your machine\'s Assignee.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            }
        }
    }

    // On Cancelling Ticket Form
    const cancelledTicket = e => {
        e.preventDefault()

        setMachine('')
        setIssueType('')
        setFaultDefinition('')
        setFaultDescription('')
        setPriority('')
        setAssignee('')
    }

    return (
        <div className='addTicket'>

            {/* Header */}
            <DashboardHeader />

            <div className="addTicket__mainBody">

                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />

                <div className="addTicket__container">

                    {/* Navigation Breadcrumb */}
                    <div className="addTicket__containerPath">
                        {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / Update Ticket
                    </div>

                    {/* Heading */}
                    <h1>Update ticket</h1>

                    {/* Form Section */}
                    <div className="addTicket__form">
                        <div className='row'>
                            <div className="col-md-12"> 
                            {console.log("data",data)}
                            <div className="addTicket__input">
                            <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Date</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"><input className="marginLeftOfDate heightOfInputBox alignOfDate"
                                    type="text"
                                    value={DateFormatNumeric(data.createdAt).replace(/\/+/g,"-")}
                                    disabled
                                /></div>
                            </div>
                                
                                
                            </div></div>
                            <div className="col-md-12"><div className="addTicket__input">
                            <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Requested By</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12">
                                <input
                                    type="text"
                                    className="heightOfInputBox alignOfRes" 
                                    defaultValue={data.requestedBy.employeeName}
                                    disabled
                                /></div>
                            </div>
                                
                                
                            </div></div>



                        </div>


                        <div className="addTicket__input">
                        <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"> <label>Location</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12">  <input
                                type="text"
                                defaultValue={data.location.locationName}
                                className="heightOfInputBox"
                                disabled
                            /></div>
                            </div>
                           
                          
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"> <label>Machine / Part</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"><Select
                                options={machineList}
                                placeholder={data.machine.machineName}
                                className='addTicket__select'
                                onChange={e => setMachine({
                                    'machineID': e.value,
                                    'machineName': e.label
                                })}
                            /></div>
                            </div>
                           
                            
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Issue Type</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12">   <Select
                                options={issueList}
                                placeholder={data.issueType}
                                className='addTicket__select'
                                onChange={e => setIssueType(e.value)}
                            /></div>
                            </div>
                            
                         
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Fault Definition</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"> <input
                                type="text"
                                placeholder='Add Definition'
                                className="heightOfInputBox"
                                value={faultDefinition}
                                onChange={e => setFaultDefinition(e.target.value)}
                            /></div>
                            </div>
                            
                           
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Fault Description</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12">  <textarea
                                className='addTicket__textarea'
                                placeholder='Add Description'
                                value={faultDescription}
                                onChange={e => setFaultDescription(e.target.value)}
                            /></div>
                            </div>
                            
                          
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"> <label>priority</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"><Select
                                options={priorityList}
                                placeholder={data.priority}
                                className='addTicket__select'
                                onChange={e => setPriority(e.value)}
                            /></div>
                            </div>
                           
                            
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>assignee</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"><Select
                                options={assigneeList}
                                placeholder={data.assignTo.employeeName}
                                className='addTicket__select'
                                onChange={e => setAssignee({
                                    'employeeID': e.value,
                                    'employeeName': e.label
                                })}
                            /></div>
                            </div>
                            
                            
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label>Add Photos</label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12">
                                {/* <FontAwesomeIcon icon={faPlusSquare} /> */}
                                {/* <FontAwesomeIcon icon={faCamera} onClick={()=> <input type="file" accept="image/*" capture="capture" />}/> */}
                               
                            <DropzoneArea
        onChange={handleChange}
        filesLimit={50}
        />
                            </div>
                            </div>
                            
                            
                        </div>
                        <div className="addTicket__input">
                         <div className='row rowWidth'>
                                <div className="col-md-4 col-xs-12 col-sm-12"><label></label></div>
                                <div className="col-md-8 col-xs-12 col-sm-12"> 
                                {loader ?   <Loader
        type="Puff"
        color="#C71800"
        height={50}
        width={50}
        // timeout={3000} //3 secs
      />  :  <button
                                className="addTicket__btns"
                                onClick={EditTicket}
                            >
                                Save
                            </button> }
                           
                            <button
                                className="addTicket__btns"
                                onClick={cancelledTicket}
                            >
                                Cancel
                            </button></div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateTicket