
import React, { useState, useEffect } from "react";
import "../Styles/ViewTicket.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import TicketComment from "../Components/TicketComment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowUp,
  faArrowDown,
  faChevronLeft,
  faChevronRight,
  faTools,
  faRedoAlt,
  faShareAlt,
  faPlusSquare,
  faEdit,
  faTrashAlt,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import repairImage from "../Assets/repair 4.png";
import Editor from "../Components/Editor";
import machine1 from "../Assets/Machine1 1.png";
import machine2 from "../Assets/Machine2.png";
import machine3 from "../Assets/Machine3.png";
import machine4 from "../Assets/Machine4.png";
import { useStateValue } from "../Functionalities/StateProvider";
import axios from "../Functionalities/axios";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Avatar } from "@material-ui/core";
import Select from "react-select";
import { dateFormat } from "../Functionalities/dateFormat";
import { timeFormat } from "../Functionalities/timeFormat";
import { randomHexColor } from "random-hex-color-generator";
import assemblyLogo from "../Assets/assembly-location-logo 2.png";
import Modal from "react-modal";
import { Image } from "antd";

function ViewTicket() {
  const history = useHistory();
  const [{ employee }, dispatch] = useStateValue();
  const [isCancelled, setIsCancelled] = useState(false);

  const [comment, setComment] = useState("");
  const [postComment, setPostComment] = useState("");
  const [cancelComment, setCancelComment] = useState(false);
  const [status, setStatus] = useState(null);
  const [editTicket, setEditTicket] = useState(null);
  const [deleteTicket, setDeleteTicket] = useState(false); // for modal open and close
  // Getting previously created or selected Ticket for Data Persistency
  const selectedTicketDetails = JSON.parse(
    localStorage.getItem("single-ticket")
    );

  const [currentImage, setCurrentImage] = useState(
    // selectedTicketDetails.images[0]
    ''
  );
  const [images, setImages] = useState(selectedTicketDetails.images);
  const [ImagesComponents, setImagesComponents] = useState(null);

  // Issue List
  const issueList = [
    {
      value: "Repair",
      label: (
        <span className="addTicket__issueSelect">
          <FontAwesomeIcon
            style={{ color: "skyblue" }}
            className="addTicket__icon"
            icon={faTools}
          />
          <span>Repair</span>
        </span>
      ),
    },
    {
      value: "Replace",
      label: (
        <span className="addTicket__issueSelect">
          <FontAwesomeIcon
            style={{ color: "green" }}
            className="addTicket__icon"
            icon={faRedoAlt}
          />
          <span>Replace</span>
        </span>
      ),
    },
    {
      value: "General",
      label: (
        <span className="addTicket__issueSelect">
          <FontAwesomeIcon
            style={{ color: "purple" }}
            className="addTicket__icon"
            icon={faShareAlt}
          />
          <span>General</span>
        </span>
      ),
    },
  ];

  console.log(currentImage, images);
  const deleteTicketID = (id) => {
    axios
      .delete(`tickets/${id}`)
      .then((data) => {
        //    console.log('data of delete employee',data)
        //    alert("Employee Deleted Successfully")
        history.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };
  // Status List Options
  const statusList = [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "In-Progress",
    },
    {
      value: 3,
      label: "Resolved",
    },
  ];

  // Previous Page Navigation
  const backNavigation = (e) => {
    e.preventDefault();

    history.goBack();
  };

  const updateComment = (message) => {
    setComment(message);
  };

  const saveStatus = () => {
    console.log("status", status);

    axios
      .patch(`tickets/${selectedTicketDetails.ticketID}`, {
        status: status,
        // "employeeID":employee.employeeID,
      })
      .then((response) => {
        store.addNotification({
          title: "Status Updated!",
          message: `${status} - status is changed.`,
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        // history.push("/viewTicket");
        console.log("response.data.status",response.data.status)
        setStatus(response.data.status);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.response.status === 409) {
            store.addNotification({
              title: "Error Occurred!",
              message: "Location Name exist.",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.response.status === 500) {
            if (error.response.data.name === "MongoError") {
              if (error.response.data.keyPattern.locationID) {
                const duplicate = error.response.data.keyPattern.locationID;
                console.log(duplicate);
                store.addNotification({
                  title: "Error Occurred!",
                  message: `Location ID exist previously`,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              } else if (error.response.data.keyPattern.locationName) {
                store.addNotification({
                  title: "Error Occurred!",
                  message: "Location Name exist.",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              }
            } else {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            }
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };

  const uploadTicketComment = async (commentMessage) => {
    await axios
      .post("/ticketComments/new", {
        commentedByID: employee.employeeID,
        commentedByName: employee.name,
        commentMessage: commentMessage,
        ticketID: selectedTicketDetails.ticketID,
      })
      .then((response) => {
       window.location.reload()
        store.addNotification({
          title: "Ticket Comment added successfully!",
          message: `Your comment - ${commentMessage}`,
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        setComment("");
        history.push("/viewTicket");
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 500
          ) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Internal Server Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        } else if (error.request) {
          store.addNotification({
            title: "Error Occurred!",
            message: "Network Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          if (error.message) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else if (error.data) {
            store.addNotification({
              title: "Error Occurred!",
              message: error.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error Occurred!",
              message: "Something went wrong",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          }
        }
      });
  };
  useEffect(() => {
    setEditTicket(selectedTicketDetails);
  }, []);

  useEffect(() => {
    displayComponents(images);
  }, []);
  const displayComponents = (imagesUrl) => {
    setImagesComponents(
      imagesUrl.map((image, index) => {
        return (
          index != 0 && (
            <div className="viewTicket__smallPicture" key={index}>
              <img src={image} />
            </div>
          )
        );
      })
    );
  };

  const seeLeft = () => {
    let imagesArray = images;
    imagesArray.unshift(imagesArray.pop());
    console.log(imagesArray);
    setImages(imagesArray);
    displayComponents(imagesArray);
  };
  const seeRight = () => {
    let imagesArray = images;
    imagesArray.unshift(imagesArray.pop());
    console.log(imagesArray);
    setImages(imagesArray);
    displayComponents(imagesArray);
  };

  return (
    <div className="viewTicket">
      {/* Header */}
      <DashboardHeader />
      {console.log("selectedTicketDetails=>", selectedTicketDetails)}
      <div className="viewTicket__mainBody">
        {/* Notifications */}
        <ReactNotification />

        {/* Sidebar */}
        <Sidebar />
        <div className="viewTicket__container">
          <div className="viewTicket__nav">
            <div className="row" style={{ width: "68%" }}>
              <div className="col-md-2">
                <div className="viewTicket__back" onClick={backNavigation}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>Back</span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="viewTicket__ticketID">
                  <img src={repairImage} />
                  <span>{selectedTicketDetails.ticketID}</span>
                </div>
              </div>
              <div className="col-md-5">
                <div style={{ textAlign: "right" }}>
                  <div className="row">
                    {employee.isAdmin && <div className="col-md-6 col-sm-12 col-xs-12">
                      {" "}
                      <button
                        className="mobBottom"
                        style={{ backgroundColor: "#C4C4C4", color: "#000" }}
                        onClick={() => {
                          history.push({
                            pathname: "/updateTicket",
                            state: { data: editTicket },
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          className="allTicket__icon"
                          icon={faEdit}
                          style={{ color: "#C31A1A" }}
                        />
                        Edit
                      </button>
                    </div>}
                    {employee.isAdmin && <div className="col-md-4 col-sm-12 col-xs-12">
                      <button
                        style={{ backgroundColor: "#C4C4C4", color: "#000" }}
                        onClick={() => {
                          setDeleteTicket(true);
                        }}
                        className="alignDeleteButton"
                      >
                        <FontAwesomeIcon
                          className="allTicket__icon"
                          icon={faTrashAlt}
                          style={{ color: "#C31A1A" }}
                        />
                        Delete
                      </button>
                    </div>}
                    {/* modal start here  */}
                    <Modal
                      className="delete__modal"
                      isOpen={deleteTicket}
                      closeTimeoutMS={20}
                      shouldCloseOnOverlayClick={true}
                    >
                      <div style={{ width: "100%" }}>
                        <h4>do you want to delete this Tickets?</h4>
                        <div className="row rowWidth">
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            {" "}
                            <button onClick={() => setDeleteTicket(false)}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <button
                              onClick={() => {
                                deleteTicketID(selectedTicketDetails.ticketID);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* modal end here */}
                  </div>
                </div>
              </div>
            </div>
            {/* Ticket Details Breadcrumb */}
          </div>

          <div className="viewTicket__sections">
            {/* Middle Section */}
            <div className="viewTicket__data">
              {/* Ticket Subject */}
              <h1>{selectedTicketDetails.faultDefinition}</h1>

              <div className="viewTicket__ticket">
                <div className="viewTicket__metaData">
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${selectedTicketDetails.requestedBy.employeeName}`}
                  />
                  <span>{selectedTicketDetails.requestedBy.employeeName}</span>
                  <span>
                    raised this ticket on{" "}
                    {dateFormat(selectedTicketDetails.createdAt)} at{" "}
                    {timeFormat(selectedTicketDetails.createdAt)}
                  </span>
                </div>
                <p>Description</p>
                <p className="viewTicket__description">
                  {selectedTicketDetails.faultDescription}
                </p>
              </div>

              {/* Comments Heading */}
              <p>Comments</p>
              <span className="viewTicket__subject">Provide Resolution</span>
              <div className="viewTicket__editor">
                {/* <Editor
                                    updateComment={updateComment}
                                /> */}
                <textarea
                  className="addTicket__textarea"
                  placeholder="Add Comment"
                  style={{ width: "100%", minHeight: 180 }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="viewTicket__buttons">
                <button onClick={() => uploadTicketComment(comment)}>
                  Post Comment
                </button>
              </div>

              <div className="viewTicket__ticketComment">
              <TicketComment
  ticketID={selectedTicketDetails.ticketID}
  store={store}
/>
              </div>
            </div>

            {/* Right Section Ticket MetaData */}
            <div className="viewTicket__details">
              <div className="viewTicket__ticketStatus">
              {status ? <span
                  className={`viewTicket__status ${status?.toLowerCase()}`}
                >
                  {status}
                </span>  : <span
                  className={`viewTicket__status ${selectedTicketDetails.status?.toLowerCase()}`}
                >
                  {selectedTicketDetails.status}
                </span> }
                
                <Select
                  options={statusList}
                  placeholder="Mark as"
                  className="viewTicket__select"
                  onChange={(e) => {
                    setStatus(e.label);
                  }}
                />
                {employee.isAdmin && <button
                  onClick={() => {
                    saveStatus();
                  }}
                >
                  Save
                </button>}
              </div>

              <div className="viewTicket__infoGroup">
                <label>assignee</label>
                <span>
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${selectedTicketDetails.assignTo.employeeName
                      }&background=${randomHexColor().substring(
                        1
                      )}&color=${randomHexColor().substring(1)}`}
                  />
                  {selectedTicketDetails.assignTo.employeeName}
                </span>
              </div>
              <div className="viewTicket__infoGroup">
                <label>requested by</label>
                <span>
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${selectedTicketDetails.requestedBy.employeeName
                      }&background=${randomHexColor().substring(
                        1
                      )}&color=${randomHexColor().substring(1)}`}
                  />
                  {selectedTicketDetails.requestedBy.employeeName}
                </span>
              </div>
              <div className="viewTicket__infoGroup">
                <label>machine</label>
                <span>
                  {selectedTicketDetails.machine.machineName}
                </span>
              </div>
              <div className="viewTicket__infoGroup">
                <label>priority</label>
                <span>
                  <FontAwesomeIcon
                    icon={
                      selectedTicketDetails.priority.toLowerCase() === "low"
                        ? faArrowDown
                        : faArrowUp
                    }
                    className={`viewMachine__priority ${selectedTicketDetails.priority.toLowerCase()}`}
                  />
                  {selectedTicketDetails.priority}
                </span>
              </div>
              <div className="viewTicket__infoGroup">
                <label>location</label>
                <span>
                  <img
                    src={assemblyLogo}
                    alt={selectedTicketDetails.location.locationName}
                  />
                  {console.log(selectedTicketDetails)}
                  {selectedTicketDetails.location.locationName}
                </span>
              </div>
              <div className="viewTicket__infoGroup">
                <label>ticket type</label>
                {selectedTicketDetails.issueType === "Repair"
                  ? issueList[0].label
                  : selectedTicketDetails.issueType === "Replace"
                    ? issueList[1].label
                    : selectedTicketDetails.issueType === "General"
                      ? issueList[2].label
                      : null}
              </div>
              <div className="viewTicket__infoGroup">
                <label>created on</label>
                <span>
                  {dateFormat(selectedTicketDetails.createdAt)}
                  {", "}
                  {timeFormat(selectedTicketDetails.createdAt)}
                </span>
              </div>

              {/* Images Part */}
              <p>Images provided by Requester</p>
              <div className="viewTicket__bigPicture">
                {/* <img src={images[0]} /> */}
                <Image width={200} src={images[0]} />
              </div>
              <div className="viewTicket__pictures">
                <FontAwesomeIcon icon={faChevronLeft} onClick={seeLeft} />
                {ImagesComponents}
                <FontAwesomeIcon icon={faChevronRight} onClick={seeRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTicket;