import axios from './axios'

export const getAssigneeList = async (setAssigneeList, store, locationID) => {
    await axios
        // TODO: Link will be changed

        .get(`employees/maintenance-team/${locationID}`)
        .then(response => response.data)
        .then(data => {

            const aList = []

            data.forEach(a => {
                const singleAssignee = {
                    value: a.employeeID,
                    label: a.name
                }

                aList.push(singleAssignee)
            })

            setAssigneeList(aList)
        }).catch(error => {
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 404 ||
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.response.data.error,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Internal Server Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            } else if (error.request) {
                store.addNotification({
                    title: "Error Occurred!",
                    message: 'Network Error',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else {
                if (error.message) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else if (error.data) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: error.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Something went wrong',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                }
            }
        })

}