export const TimeFormatTask = d => {
    const date = new Date(d)
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    hours = hours < 10 ? '0' + hours : hours

    let strTime = hours + ':' + minutes + ':' + seconds
    return strTime
}