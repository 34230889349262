import React, { useEffect, useState } from 'react'
import '../Styles/AddSchedule.css'
import DashboardHeader from '../Components/DashboardHeader'
import Sidebar from '../Components/Sidebar'
import Select from "react-select"
import { useStateValue } from '../Functionalities/StateProvider'
import AddTaskForm from '../Components/AddTaskForm'
import axios from '../Functionalities/axios'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useHistory } from 'react-router-dom'
import { getAssigneeList } from '../Functionalities/getMaintenancePersons'
import { getMachinesListByLocation } from '../Functionalities/getMachine'
import { useLocation } from "react-router-dom";
import { DateFormatNumeric } from '../Functionalities/dateFormatNumeric'
import { timeFormat } from '../Functionalities/timeFormat'
import Loader from "react-loader-spinner";

import UpdateTaskForm from '../Components/UpdateTaskForm'
import AddTaskFormOnSchedule from '../Components/AddTaskFormOnSchedule'

// myherokuapphawkins
function UpdateSchedule() {
    const location = useLocation();
    const data = location.state.data;

    const [state, dispatch] = useStateValue()
    const [taskList, setTaskList] = useState([])
    const [editTaskList, setEditTaskList] = useState([])
    const [editTaskListStatus, setEditTaskListStatus] = useState(true)
    const [loader,setLoader] = useState(false)


    // Dynamically fetching from server according to Location
    const [machineList, setMachineList] = useState([])
    const [assigneeList, setAssigneeList] = useState([])

    // Storing Task IDs on saving
    const [taskIDs, setTaskIDs] = useState([])
    const [editTaskIDs, setEditTaskIDs] = useState(data.taskID)


    const history = useHistory()

    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = localStorage.getItem('selected_location').split(',')

    // Input fields for Schedule Form
    const [scheduleDate, setScheduleDate] = useState(data.date)
    // console.log('data', data)
    const [scheduleID, setScheduleID] = useState(data.scheduleID)
    const [repeatAmount, setRepeatAmount] = useState(data.repeatAmount)
    const [repeatCycle, setRepeatCycle] = useState(data.repeatCycle)
    const [repeatInterval, setRepeatInterval] = useState(data.repeatInterval)
    const [machine, setMachine] = useState(data.machine)
    const [assignedTo, setAssignedTo] = useState(data.assignedTo)
    const [scheduleSummary, setScheduleSummary] = useState(data.scheduleSummary)
    const [taskStatus, setTaskStatus] = useState(true)
    // Dynamically getting Machines List
    useEffect(() => {
        getMachinesListByLocation(setMachineList, store, selectedLocationDetails[0])
        if (taskStatus) {
            data.taskID.map((item, key) => {
                getTaskList(item)

            })


            //     <AddTaskForm
            //     key={taskList.length}
            //     index={taskList.length}
            //     removeTask={removeTask}
            //     store={store}
            //     setTaskIDs={setTaskIDs}
            // />

            setTaskStatus(false)
        }


    }, [])

    // Fetching Maintenance Personnel and Maintenance Manager List
    useEffect(() => {
        getAssigneeList(setAssigneeList, store, selectedLocationDetails[0])
    }, [])

    const periodList = [
        {
            value: 1,
            label: 'Days'
        },
        {
            value: 2,
            label: 'Weeks'
        },
        {
            value: 3,
            label: 'Months'
        },
        {
            value: 4,
            label: 'Years'
        }
    ]

    const addTask = e => {
        e.preventDefault()
        dispatch({
            type: 'taskListUpdate',
            taskListUpdate: state.taskListUpdate.concat(
                <AddTaskFormOnSchedule
                    key={state.taskListUpdate.length - 1}
                    index={state.taskListUpdate.length}
                    removeTask={removeTask}
                    store={store}
                    setTaskIDs={setEditTaskIDs}
                />
            )
        })
        // setTaskList(taskList.concat(
        //     <AddTaskForm
        //         key={taskList.length}
        //         index={taskList.length}
        //         removeTask={removeTask}
        //         store={store}
        //         setEditTaskIDs={setEditTaskIDs}
        //     />
        // ))
    }

    const removeTask = index => {
        // console.log('TaskList Before -> ', taskList.length);
        let newTaskList = taskList
        newTaskList.splice(index, 1)
        // console.log(newTaskList);
        // console.log('TaskList After -> ', newTaskList.length);
        // console.log(index)
        setTaskList(newTaskList)
    }
    const removeEditTask = index => {
        // console.log('TaskList Before -> ', taskList.length);
        let newTaskList = editTaskList
        newTaskList.splice(index, 1)
        // console.log(newTaskList);
        // console.log('TaskList After -> ', newTaskList.length);
        // console.log(index)
        setEditTaskList(newTaskList)
    }

    const updateSchedule = async () => {
        setLoader(true)
        console.log(
            scheduleDate,'scheduleDate',
    machine,'machine',
    assignedTo,'assignedTo',
    scheduleSummary,'scheduleSummary',
    repeatAmount,'repeatAmount',
    repeatCycle,'repeatCycle',
    editTaskIDs,'editTaskIDs'
        )
        if (
            scheduleDate !== '' &&
            // scheduleID !== '' &&
            machine !== {} &&
            assignedTo !== {} &&
            scheduleSummary !== '' &&
            repeatAmount >= 0 &&
            repeatCycle >= 0 &&
            editTaskIDs.length > 0
        ) {
            await axios
                .put(`schedules/${data.scheduleID}`, {
                    // "scheduleID": scheduleID,
                    "date": new Date(scheduleDate).toISOString(),
                    "taskID": editTaskIDs,
                    "repeatAmount": Number(1),
                    "repeatCycle": Number(1),
                    "repeatInterval": repeatInterval,
                    // "requestedBy": {
                    //     "requestedByID": state.employee.employeeID,
                    //     "requestedByName": state.employee.name
                    // },
                    // "location": {
                    //     "locationID": selectedLocationDetails[0],
                    //     "locationName": selectedLocationDetails[1]
                    // },
                    "machine": machine,
                    "assignedTo": assignedTo,
                    "status": "Open",
                    "scheduleSummary": scheduleSummary
                }).then(response => response.data)
                .then(data => {
                    setLoader(false)
                    store.addNotification({
                        title: "Ticket Created!",
                        message: `Your Schedule ID is ${data.scheduleID}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })

                    dispatch({
                        type: 'SINGLE_SCHEDULE',
                        schedule: data
                    })

                    history.push('/allSchedule')
                }).then(() => {
                    setScheduleID('')
                    setScheduleSummary('')
                    setRepeatAmount(0)
                    setRepeatCycle(0)
                    setTaskList([])
                }).catch(error => {
                    setLoader(false)

                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else {
            if (scheduleDate === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select a Date.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (scheduleID === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter Schedule ID.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (machine === {}) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select a Machine.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (assignedTo === {}) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, select some to assign Schedule.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (scheduleSummary === '') {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter Schedule summary.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (repeatAmount < 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter correct number repeat amount.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (repeatCycle < 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, enter correct number of repeat cycle.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } else if (taskIDs.length === 0) {
                store.addNotification({
                    title: "Empty Field!",
                    message: 'Please, upload a task first.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            }
        }
    }
    // Fetching Tasks by ID
    const getTaskList = async (taskID) => {
        // console.log(taskID, 'taskID')
        await axios
            .get(`/tasks/${taskID}`)
            .then(response => response.data)
            .then(data => {
                //     console.log('daataa',data)
                //   setTaskList(taskList.concat(
                //         <UpdateTaskForm
                //             key={editTaskList.length}
                //             index={editTaskList.length}
                //             removeTask={removeEditTask}
                //             store={store}
                //             setEditTaskIDs={setEditTaskIDs}
                //             editTaskListItem={data}
                //         />))

                setEditTaskList(previousEditTaskList => [...previousEditTaskList, data].sort())

            })
            .catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
    var reverseDate = null
    var scheduleDateExm = DateFormatNumeric(data.date).replace(/\/+/g,"-")
    return (
        <div className='addSchedule'>
            {console.log(editTaskList, 'editTaskList')}
            {console.log(data.taskID, 'data.taskID')}
            {console.log(taskList, 'taskList')}
            {console.log(editTaskListStatus, 'editTaskListStatus')}
            {/* {console.log('editTaskList.lenght', editTaskList.length)} */}
            {/* Header */}
            {/* reverse date here*/}
            {reverseDate = scheduleDateExm.split("-").reverse().join("-")}
            <DashboardHeader />

            <div className="addSchedule__mainBody">

                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />

                <div className="addSchedule__container">
                    <div className="addSchedule__containerPath">
                        {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / Edit Schedule
                    </div>
                    <h1>Edit Schedule</h1>
                    <div className="addSchedule__form">

                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'><label>Date</label></div>
                                <div className='col-md-5 col-xs-12 col-sm-12'>
                                {console.log("reverseDate",reverseDate)}
                                <input
                                    type="date"
                                    value={''+reverseDate}
                                    // value={'2021-09-01'}

                                    className="heightOfInputBox"
                                    onChange={e => setScheduleDate(e.target.value)}
                                /></div>
                                <div className='col-md-3 col-xs-12 col-sm-12'>
                                 {/* <label className='addSchedule__ticketNo'>Schedule ID</label> */}
                                 </div>
                                <div className='col-md-1 col-xs-12 col-sm-12'> 
                                {/* <input type="text" value={scheduleID} onChange={e => setScheduleID(e.target.value)} /> */}
                                </div>


                            </div>




                        </div>
                        <div className="addSchedule__input">
                            {/* <div className="row rowWidth">
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}><label>Schedule Period</label></div>
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}><label>Repeat in</label></div>
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}> <input
                                    type="text"
                                    size={5}
                                    placeholder='1'
                                    value={repeatAmount}
                                    onChange={e => setRepeatAmount(e.target.value)}
                                    disabled
                                /></div>
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}> <Select
                                    options={periodList}
                                    placeholder='Days'
                                    onChange={e => setRepeatInterval(e.label.toLowerCase())}
                                /></div>
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}><label>Repeat Cycle</label></div>
                                <div className='col-md-2 col-xs-12 col-sm-12' style={{ paddingLeft: 0 }}><input
                                    type="text"
                                    placeholder='1'
                                    value={repeatCycle}
                                    onChange={e => setRepeatCycle(e.target.value)}
                                    disabled
                                /></div>


                            </div> */}






                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'><label>Requested By</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'><input
                                    type="text"
                                    className="heightOfInputBox"
                                    placeholder={state.employee.name}
                                    disabled
                                /></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'><label>Location</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'> <input type="text" className="heightOfInputBox" placeholder={selectedLocationDetails[1]} disabled /></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'> <label>Machine / Part</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'><Select
                                    options={machineList}
                                    // placeholder='Select'
                                    className='addSchedule__select'
                                    // defaultValue={machine}
                                    placeholder={machine.machineName}
                                    onChange={
                                        e => setMachine({
                                            "machineID": e.value,
                                            "machineName": e.label
                                        })
                                    }
                                /></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'><label>Assignee To</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'><Select
                                    options={assigneeList}
                                    // placeholder='Select'
                                    placeholder={assignedTo.assignedToName}

                                    className='addSchedule__select'
                                    // defaultValue={assignedTo.assignedToName === {} ? assignedTo.assignedToName : 'Select Assignee'}
                                    onChange={
                                        e => setAssignedTo({
                                            "assignedToID": e.value,
                                            "assignedToName": e.label
                                        })
                                    }
                                /></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'><label>Schedule Summary</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'><textarea
                                    type="text"
                                    placeholder='Add Summary'
                                    className="heightOfInputBox"
                                    style={{ width: "100%", minHeight: 180 }}
                                    value={scheduleSummary}
                                    onChange={e => setScheduleSummary(e.target.value)}
                                /></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'> <label>Activity Details</label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'><div className="addSchedule__addTask">
                                    <button onClick={addTask}>Add Task</button>

                                    {editTaskList.map((item, key) => {
                                        return (<UpdateTaskForm
                                            key={editTaskList.length}
                                            index={editTaskList.length}
                                            removeTask={removeEditTask}
                                            store={store}
                                            setEditTaskIDs={setEditTaskIDs}
                                            editTaskListItem={item}
                                        />)
                                    })}
                                    {
                                        state.taskListUpdate.map((task, index) => (
                                            task
                                        ))
                                    }
                                </div></div>

                            </div>


                        </div>
                        <div className="addSchedule__input">
                            <div className="row rowWidth">
                                <div className='col-md-3 col-xs-12 col-sm-12'> <label></label></div>
                                <div className='col-md-9 col-xs-12 col-sm-12'>
                                {loader ?   <Loader
        type="Puff"
        color="#C71800"
        height={50}
        width={50}
        // timeout={3000} //3 secs
      />  :   <button
                                    className="addSchedule__btns"
                                    onClick={() => { updateSchedule() }}
                                >
                                    Save Schedule
                                </button> }
                                
                                   
                                    <button
                                        className="addSchedule__btns"
                                        onClick={
                                            () => {
                                                setScheduleID('')
                                                setScheduleSummary('')
                                                setRepeatAmount(0)
                                                setRepeatCycle(0)
                                                setAssignedTo({})
                                            }
                                        }
                                    >
                                        Cancel
                                    </button></div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateSchedule