import React, { useEffect, useState } from "react"
import '../Styles/SelectLocation.css'
import Header from "../Components/Header"
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Choice from "../Components/Choice";
import { useStateValue } from "../Functionalities/StateProvider";
import axios from "../Functionalities/axios";
import { Link } from "react-router-dom"
import Loading from "../Components/Loading"

function SelectLocation() {

    const [state, dispatch] = useStateValue()
    const [locations, setLocations] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // Getting previously selected Factory for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')

    useEffect(() => {

        // Showing Loading Component till fetching data
        setIsLoading(true)
console.log('location get function')
        // Fetching data from Selected Factory
        if (state.selectedFactoryDetails !== {}) {
            console.log('selectedFactoryDetails',selectedFactoryDetails[0])
            axios.get(`locations/${selectedFactoryDetails[0]}`)
                .then(response => {
                    console.log('data of location',response)
                    setLocations(state.employee.location)
                    dispatch({
                        type: 'ALL_LOCATION',
                        allLocation: response.data.locations,
                    })
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                                    error.response.status === 401 ||
                                    error.response.status === 404 ||
                                    error.response.status === 403 ||
                                    error.response.status === 500
                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data.error,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        } else {
            let l = []
            state.location.forEach(singleLocation => {
                l.push({
                    locationID: singleLocation[0],
                    locationName: singleLocation[1]
                })            
            })
            setLocations(l)
            setIsLoading(false)            
        }
    }, [])

    return (
        <div className='selectLocation'>
            {
                !isLoading ? (
                    <>
                        {/* Full Header comes from the Header Component */}
                        <Header />

                        {/* Body section of the page*/}
                        <div className="selectLocation__content">

                            {/*Upper nav part */}
                            <Link to='/factory'>
                                <div className="selectLocation__nav">
                                    <ChevronLeftRoundedIcon />
                                    <span>Back</span>
                                    <span className="selectLocation__selectedFactory">
                                        Factory - {selectedFactoryDetails[1].toUpperCase()}
                                    </span>
                                </div>
                            </Link>

                            {/* Location Heading */}
                            <h2>select location</h2>

                            {/*  Notifications */}
                            <ReactNotification />

                            {/* Locations list */}
                            <div className="selectLocation__choices">
                            {console.log('location list get->>',locations)}
                                {
                                    locations.map((location) => (
                                        <Choice
                                            choiceID={location.locationID}
                                            choiceName={location.locationName}
                                            key={location.locationID}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )
            }
        </div>
    )
}

export default SelectLocation