import React from 'react'
import '../Styles/Loading.css'

function Loading() {

    // Loading Component
    return (
        <div className='loading'>
            <div className="loadingio-spinner-gear-3re1bfls2o"><div className="ldio-5i7rihken7u">
                <div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div></div>
        </div>
    )
}

export default Loading