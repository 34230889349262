import React, {useEffect} from "react"
import '../Styles/SelectFactory.css'
import Header from "../Components/Header"
import {Link} from 'react-router-dom'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import Choice from "../Components/Choice";
import { useStateValue } from "../Functionalities/StateProvider"

function SelectFactory() {

    const [state] = useStateValue()

    return (
        <div className='selectFactory'>

            {/*Full Header comes from the Header Component */}
            <Header />

            {/* Body of the page */}
            <div className="selectFactory__content">

                {/* Upper nav section */}
                <Link to='/'>
                    <div className="selectFactory__nav">
                        <ChevronLeftRoundedIcon />
                        <span>Back</span>
                    </div>
                </Link>

                {/* Factory Details Header */}
                <h2>Select Factory</h2>

                {/* Here comes the list of Factories */}
                <div className="selectFactory__choices">
                    {
                        state.factory.map(f => (
                            <Choice
                                key={f[0]}
                                choiceID={f[0]}
                                choiceName={f[1]}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectFactory