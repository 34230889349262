import React, { useEffect, useState } from "react";
import logo from "../Assets/logo.svg";
import "../Styles/Login.css";
import Modal from "react-modal";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useStateValue } from "../Functionalities/StateProvider";
import axios from "../Functionalities/axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

Modal.setAppElement("#root");

function ResetPassword() {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [empIDForgot, setEmpIDForgot] = useState("");
  const [emailIDForgot, setEmailIDForgot] = useState("");

  const history = useHistory(); // Browser Pagination
  const [{}, dispatch] = useStateValue(); //Context API

  const resetPassword = async () => {
    console.log("empID,emailIDForgot", empIDForgot, emailIDForgot);
    // console.log("params.id",params.id)
    if (newPassword && confirmPassword) {
      if (newPassword != confirmPassword) {
        store.addNotification({
          title: "Error Occurred!",
          message: "Password must be same",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      } else {
        try {
          await axios
            .patch(
              `https://hawkins-backend.herokuapp.com/api/employees/reset-password/${params.id}`,
              {
                newPassword: newPassword,
                confirmNewPassword: confirmPassword,
              }
            )
            .then((response) => {
              store.addNotification({
                title: "Success",
                message: "Password have reset",
                type: "success",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
              console.log(response);
              history.push("/");
            })
            .catch((error) => {
              store.addNotification({
                title: "Error Occurred!",
                message: error.response.data.error,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
            });
        } catch (error) {
          alert(error);
        }
      }
    } else {
      store.addNotification({
        title: "Error Occurred!7",
        message: "Password can't be empty!",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <div className="login">
      {/* Notification */}
      <ReactNotification />

      {/* Page and Application Details*/}
      <img src={logo} alt="" />
      <h3>incident management application</h3>
      <h1>Reset Password</h1>
      {/* <h1>{params.id}</h1> */}

      {/* Form for login */}
      <div>
        <div className="login__input">
          <label>New Password</label>
          <input
            value={newPassword}
            size="35"
            type="password"
            placeholder="********"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="login__input">
          <label>Confirm Password</label>
          <input
            size="35"
            className="login__pass"
            type="password"
            placeholder="********"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        {/* Back to form */}
        <div className="login__input">
          <button onClick={resetPassword}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
