
import React, { useEffect, useState } from "react"
import '../Styles/ViewLocation.css'
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import axios from '../Functionalities/axios'
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";
import { useStateValue } from "../Functionalities/StateProvider";
import { useHistory } from "react-router";
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Modal from 'react-modal'

function ViewLocation() {

    const [employees, setEmployees] = useState([])
    const [state, dispatch] = useStateValue()
    const history = useHistory()
    const [editLocation, setEditLocation] = useState(null)
    // Getting previously selected Location for Data Persistent
    const selectedFactoryDetails = localStorage.getItem('selected_factory').split(',')
    const selectedLocationDetails = JSON.parse(localStorage.getItem('single-location'))
    const [locationSelected, setLocationSelected] = useState(null)
    const [maintenanceType, setMaintenanceType] = useState(null)
    const [authorizationStatusSelected, setAuthorizationStatusSelected] = useState(null)
    const [dateTime, setDateTime] = useState(null)
    const [locations, setLocations] = useState(null)
    // Getting account of employees selected from employee list
    const [isChecked, setIsChecked] = useState([])
    const [deleteLocations, setDeleteLocations] = useState(false)
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(1) // For Pagination
    const [previousPageShow, setPreviousPageShow] = useState(false) // For showing Previous Page Button
    const [nextPageShow, setNextPageShow] = useState(false) // For showing Next Page Button
    const limit = 10 // For Pagination

    // Sorting Options
    const sortOptions = [
        {
            value: 1,
            label: 'Employee ID',
        },
        {
            value: 2,
            label: 'Department',
        },
        {
            value: 3,
            label: 'Name',
        }
    ]
    // Maintenance Type
    const maintenanceTypeList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Maintenance Personnel',
            label: 'Maintenance Personnel'
        },
        {
            value: 'Maintenance Manager',
            label: 'Maintenance Manager'
        }
    ]
    const authorizationStatus = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Pending',
            label: 'Pending'
        },
        {
            value: 'Verified',
            label: 'Verified'
        },
        {
            value: 'Cancelled',
            label: 'Cancelled'
        },
        {
            value: 'Changes Needed',
            label: 'Changes Needed'
        },
        {
            value: 'Disabled',
            label: 'Disabled'
        }

    ]
    // Timeline List
    const timelineList = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Today',
            label: 'Today'
        },
        {
            value: 'This Week',
            label: 'This Week'
        },
        {
            value: 'This Month',
            label: 'This Month'
        },
        {
            value: 'Last 3 Months',
            label: 'Last 3 Months'
        },
        {
            value: 'This Year',
            label: 'This Year'
        }
    ]
    const deleteLocation = async (id) => {
        await axios
            .delete(`locations/${id}`)
            .then(data => {
                //    alert("Employee Deleted Successfully")
                history.goBack();
                // getAllLocation();

            }).catch(error => {
                alert(error)
            })

    }  // Fetching Data

    useEffect(() => {

        // Showing Loading Component till fetching data
        console.log('location get function')
        // Fetching data from Selected Factory
        if (state.selectedFactoryDetails !== {}) {
            console.log('selectedFactoryDetails', selectedFactoryDetails[0])
            axios.get(`locations/${selectedFactoryDetails[0]}`)
                .then(response => {
                    console.log('data of location', response.data.locations)
                    // setLocations(response.data.locations)

                    let l = []
                    l.push({
                        label: 'All',
                        value: 'All'
                    })
                    response.data.locations.forEach(singleLocation => {
                        console.log("singleLocation", singleLocation)
                        l.push({
                            value: singleLocation.locationID,
                            label: singleLocation.locationName
                        })
                    })
                    setLocations(l)
                }).catch(error => {
                    if (error.response) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 404 ||
                            error.response.status === 403 ||
                            error.response.status === 500

                        ) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Internal Server Error',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    } else if (error.request) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Network Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        if (error.message) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else if (error.data) {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: 'Something went wrong',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                })
        }
    }, [])
    useEffect(() => {
        setEditLocation(selectedLocationDetails)
        axios
            .get(`/employees/location/${selectedLocationDetails.locationID}`)
            .then(response => response.data)
            .then(data => {
                setEmployees(data)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }, [])
    // search function 
    const searchFunc = async () => {
        let haveLocation = locationSelected;
        let haveMaintenanceType = maintenanceType;
        let haveAuthorizationStatusSelected = authorizationStatusSelected;
        let haveDateTime = dateTime;


        if (locationSelected) {
            console.log('if', locationSelected)
            if (locationSelected.label === 'All') {
                haveLocation = null
            }
            else {

                haveLocation = locationSelected.value
            }
        }


        if (maintenanceType) {
            console.log('if', maintenanceType)
            if (maintenanceType.label === 'All') {
                haveMaintenanceType = null
            }
            else {

                haveMaintenanceType = maintenanceType.label
            }
        }


        if (authorizationStatusSelected) {
            console.log('if', authorizationStatusSelected)
            if (authorizationStatusSelected.label === 'All') {
                haveAuthorizationStatusSelected = null
            }
            else {

                haveAuthorizationStatusSelected = authorizationStatusSelected.label
            }
        }

        if (dateTime) {
            console.log('if', dateTime)
            if (dateTime.label === 'All') {
                haveDateTime = null
            }
            else {

                haveDateTime = dateTime.label
            }
        }

        console.log(
            "search key", search,
            "haveLocation", haveLocation,
            "haveMaintenanceType", haveMaintenanceType,
            "haveAuthorizationStatusSelected", haveAuthorizationStatusSelected,
            "haveDateTime", haveDateTime
        )
        await axios
            .get(`employees/factory/${selectedFactoryDetails[0]}?page=${page}&limit=${limit}&text=${search}&location_id=${haveLocation}&maintenance_type=${haveMaintenanceType}&status=${haveAuthorizationStatusSelected}&date_filter=${haveDateTime}`)
            .then(response => {
                console.log('data.get all All Employees search', response.data)
                console.log('search result of employees', response.data.employees)
                setEmployees(response.data.employees)
                // if (response.next) setNextPageShow(true)
                // if (response.previous) setPreviousPageShow(true)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500

                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
    return (
        <div className='viewLocation'>

            {/* Header */}
            <DashboardHeader />
            <div className="viewLocation__mainBody">

                {/* Notification */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />
                <div className="viewLocation__container">

                    {/* Heading */}
                    <h1>location info</h1>
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <div className="viewLocation__buttons">
                            {state.employee.isAdmin && <div className="viewLocation__leftBtns">
                                <button onClick={() => {
                                    console.log(editLocation, 'editLocation')
                                    history.push({
                                        pathname: '/updateLocation',
                                        state: { data: editLocation }
                                    })
                                }}>
                                    <FontAwesomeIcon className='viewLocation__icon' icon={faEdit} style={{ color: '#c31a1a' }} />
                                    edit
                                </button>
                                <button onClick={() => setDeleteLocations(true)}>
                                    <FontAwesomeIcon className='viewLocation__icon' icon={faTrashAlt} />
                                    delete
                                </button>
                                <Modal
                                    className='delete__modal'
                                    isOpen={deleteLocations}
                                    closeTimeoutMS={20}
                                    shouldCloseOnOverlayClick={true}
                                >
                                    <div style={{ width: '100%' }}>
                                        <h4>do you want to delete this Location?</h4>
                                        <div className="row rowWidth">
                                            <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={() => setDeleteLocations(false)}>Cancel</button></div>
                                            <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={() => {
                                                deleteLocation(selectedLocationDetails.locationID);
                                            }}>Delete</button></div>

                                        </div>
                                    </div>

                                </Modal>
                            </div>}

                        </div>
                    </div>

                    {/* 5 boxes of Tickets Statistics */}
                    <div className="viewLocation__boxes">
                        <div className="row rowWithOfViewLocation" >
                            <div className="col-md-3 col-sm-6 col-lg-2">   <div className="viewLocation__box viewLocation__box1">
                                <div className="viewLocation__infoDetails">
                                    <span>location</span>
                                    <span>{selectedLocationDetails.locationName}</span>
                                </div>
                                <div className="viewLocation__infoDetails">
                                    <span>location id</span>
                                    <span>{selectedLocationDetails.locationID}</span>
                                </div>
                                <div className="viewLocation__infoDetails">
                                    <span>factory</span>
                                    <span>{selectedFactoryDetails[1]}</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"> <div className="viewLocation__box viewLocation__box2">
                                <div className="viewLocation__infoHeading">
                                    <h3>
                                        total tickets
                                    </h3>
                                    <h2>{selectedLocationDetails.totalTicketsCount}</h2>
                                </div>
                                <div className="viewLocation__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"> <div className="viewLocation__box viewLocation__box3">
                                <div className="viewLocation__infoHeading">
                                    <h3>
                                        Total Employees
                                    </h3>
                                    <h2>{selectedLocationDetails.totalEmployeesCount}</h2>
                                </div>
                                <div className="viewLocation__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"><div className="viewLocation__box viewLocation__box4">
                                <div className="viewLocation__infoHeading">
                                    <h3>
                                        total schedules
                                    </h3>
                                    <h2>{selectedLocationDetails.totalSchedulesCount}</h2>
                                </div>
                                <div className="viewLocation__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>
                            <div className="col-md-3 col-sm-6 col-lg-2"> <div className="viewLocation__box viewLocation__box5">
                                <div className="viewLocation__infoHeading">
                                    <h3>
                                        Total Machines
                                    </h3>
                                    <h2>{selectedLocationDetails.totalMachinesCount}</h2>
                                </div>
                                <div className="viewLocation__infoFooter">
                                    <span>View all</span>
                                    <span>export</span>
                                </div>
                            </div></div>

                        </div>





                    </div>

                    {/* Heading */}
                    <h1>All Employees</h1>

                    {/* Input Fields */}
                    {/* <div className="viewLocation__inputFields">
                        <div className="row rowWithOfViewLocation">
                            <div className="col-md-7"><input
                                type="text"
                                size={100}
                                style={{ marginBottom: '2%' }}
                                className="rowWithOfViewLocation heightOfInputBox"
                                placeholder='Search'
                            /></div>
                            <div className="col-md-4"> <Select
                                options={sortOptions}
                                placeholder='Sort By'
                                className='viewLocation__select'
                            /></div>
                            <div className="col-md-1">
                                <button style={{ marginTop: 0, marginBottom: 10 }} className="heightOfInputBox"
                                 onClick={()=>searchFunc()}
                                >Search</button>
                            </div>
                        </div>
                    </div> */}
                    {/* Input Fields */}
                    <div className="allEmployee__inputFields">

                        <div className="row rowWidthAllLocation">
                            <div className="col-md-3 col-xs-12 col-sm-12"> <input
                                type="text"
                                className="widthOfSearch heightOfInputBox"
                                size={100}
                                placeholder='Search'
                                onChange={e => setSearch(e.target.value)}
                            /> </div>
                            <div className="col-md-2 col-xs-12 col-sm-12">
                                {console.log(" all locations", locations)}
                                <Select
                                    options={locations}
                                    placeholder='Location'
                                    className='allEmployee__select '
                                    onChange={e => setLocationSelected(e)}

                                />
                            </div><div className="col-md-2 col-xs-12 col-sm-12"><Select
                                options={maintenanceTypeList}
                                placeholder='Maintenance Type'
                                className='allEmployee__select '
                                onChange={e => setMaintenanceType(e)}

                            /> </div><div className="col-md-2 col-xs-12 col-sm-12"><Select
                                options={authorizationStatus}
                                placeholder='Authorization Status'
                                className='allEmployee__select '
                                onChange={e => setAuthorizationStatusSelected(e)}

                            /> </div><div className="col-md-2 col-xs-12 col-sm-12"><Select
                                options={timelineList}
                                placeholder='Date'
                                className='allEmployee__select '
                                onChange={e => setDateTime(e)}

                            /> </div>

                            <div className="col-md-1 col-sm-12 col-xs-12">   <button className="heightOfInputBox" style={{ marginBottom: 10 }} onClick={() => searchFunc()}>Search</button></div>


                        </div>


                    </div>

                    {/* Buttons */}
                    <div className="viewLocation__buttons">
                        {state.employee.isAdmin && <div className="viewLocation__leftBtns">
                            <button>
                                <FontAwesomeIcon className='viewLocation__icon' icon={faEdit} />
                                edit
                            </button>
                            <button >
                                <FontAwesomeIcon className='viewLocation__icon' icon={faTrashAlt} />
                                delete
                            </button>
                        </div>}
                        <div className="viewLocation__rightBtns">
                            <button>export</button>
                        </div>
                    </div>

                    {/* Table */}
                    <div className='viewLocation__table'>

                        {/* Table Heading */}
                        <div className="viewLocation__tableHeading">
                            <span>
                                <input
                                    type="checkbox"
                                    className="heightOfInputBox"
                                    onChange={e => {
                                        setIsChecked(previousValue => {
                                            previousValue = new Array(employees.length)
                                            previousValue.fill(e.target.checked)
                                            return previousValue
                                        })
                                    }}
                                />
                            </span>
                            <span>Display Picture</span>
                            <span>Name</span>
                            <span>Employee ID</span>
                            <span>Phone</span>
                            <span>App Authorization</span>
                        </div>

                        {/* Table Data */}
                        {
                            employees?.map((employee, index) => (
                                <div key={employee._id} className={`viewLocation__tableData ${isChecked[index] && 'selected'}`}>
                                    <span>
                                        <input
                                            type="checkbox"
                                            className="heightOfInputBox"
                                            onChange={e => {
                                                setIsChecked(isChecked => {
                                                    let c = [...isChecked]
                                                    c[index] = e.target.checked
                                                    return c
                                                })
                                            }}
                                            checked={isChecked[index]}
                                        />
                                    </span>
                                    <Avatar />
                                    <span
                                        onClick={e => {
                                            e.preventDefault()

                                            dispatch({
                                                type: 'SINGLE_EMPLOYEE',
                                                employee: employee
                                            })
                                            history.push('/viewEmployee')
                                        }}
                                    >
                                        {employee.name}
                                    </span>
                                    <span>{employee.employeeID}</span>
                                    <span>{employee.phone}</span>
                                    <span className={employee.status.toLowerCase()}>
                                        {employee.status}
                                    </span>
                                </div>
                            ))
                        }
                    </div>

                    {/* Pagination Section */}
                    <div className="viewLocation__pages">
                        {
                            previousPageShow && (
                                <>
                                    <button
                                        onClick={() => {
                                            if (page > 0) setPage(page - 1)
                                        }}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (page > 0) setPage(page - 1)
                                        }}
                                    >
                                        {page - 1}
                                    </button>
                                </>
                            )
                        }
                        <button className='select'>
                            {page}
                        </button>
                        {
                            nextPageShow && (
                                <>
                                    <button
                                        onClick={() => {
                                            setPage(page + 1)
                                        }}
                                    >
                                        {page + 1}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setPage(page + 1)
                                        }}
                                    >
                                        Next
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewLocation