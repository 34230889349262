import { Avatar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import '../Styles/TaskComment.css'
import axios from '../Functionalities/axios'
import { dateFormat } from '../Functionalities/dateFormat'
import { timeFormat } from '../Functionalities/timeFormat'
import { useStateValue } from '../Functionalities/StateProvider'
import Modal from 'react-modal'
import { Schedule } from '@material-ui/icons'
import { useHistory } from 'react-router'
import UpdateEditor from './UpdateEditor'

function TaskComment({ taskObjectID, store, postComment }) {
    const [comment, setComment] = useState('')
    const [state, dispatch] = useStateValue()
    const [taskComments, setTaskComments] = useState([])
    const [commentModal, setCommentModal] = useState(false)// for modal to delete single task
    const [editStatus, setEditStatus] = useState(false)
    const history = useHistory()
    // Getting all Task Comments
    useEffect(() => {
        getTaskComments()
    }, [])

    useEffect(() => {
        if (postComment === 'Commented') {
            setTimeout(() => {
                getTaskComments()
            }, 3000)
        }
    }, [postComment])
    const updateComment = message => {
        setComment(message)
    }
    // Fetching all task Comments
    const getTaskComments = async () => {
        await axios
            .get(`taskComments/${taskObjectID}`)
            .then(response => response.data)
            .then(data => {
                setTaskComments(data)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })

    }

    const updateEditComment = id => {
        // e.preventDefault()
console.log('id of task',id,comment)
        axios
            .patch(`taskComments/${id}`, {
                "commentMessage": comment
            }).then(response => {
                store.addNotification({
                    title: "Task Comment Updated!",
                    message: `${comment} - comment is updated.`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
                getTaskComments();
// history.push('/viewTicket');
                setComment('')
                // setLocationID('')
            })
            .catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 409) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Location Name exist.',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.response.status === 500) {
                        if (error.response.data.name === 'MongoError') {
                            if (error.response.data.keyPattern.locationID) {
                                const duplicate = error.response.data.keyPattern.locationID
                                console.log(duplicate);
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: `Location ID exist previously`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            } else if (error.response.data.keyPattern.locationName) {
                                store.addNotification({
                                    title: "Error Occurred!",
                                    message: 'Location Name exist.',
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 2000,
                                        onScreen: true
                                    }
                                })
                            }
                        } else {
                            store.addNotification({
                                title: "Error Occurred!",
                                message: error.response.data,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                    onScreen: true
                                }
                            })
                        }
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
    const deleteTaskComment =(id)=>{

        axios
        .delete(`taskComments/${id}`)
        .then(response => response.data)
        .then(data => {
        //    console.log('data of delete employee',data)
        //    alert("Employee Deleted Successfully")
        //    history.goBack();
        getTaskComments()
        }).catch(error => {
            alert(error)
         })
         setCommentModal(false)
    }

    return (
        <div className='taskComment'>
            {
                taskComments?.map(taskComment => (
                    <div key={taskComment._id} className="taskComment__list">

                        {/* User Details */}
                        <div className="taskComment__user">
                            <Avatar src={`https://ui-avatars.com/api/?name=${state.employee.name}`}  />
                            <h5>{taskComment.commentedByName}</h5>
                            <p>{dateFormat(taskComment.createdAt)}, {timeFormat(taskComment.createdAt)}</p>
                        </div>

                        {/* Comment Message */}
                        <div className="taskComment__comment">
                            <p>
                                <div dangerouslySetInnerHTML={{__html: taskComment.commentMessage}} />
                            </p>
                        </div>
                        {editStatus ?  <UpdateEditor
                                  updateComment={updateComment} commentMessage={taskComment.commentMessage}
                                /> : null }

                        {/* Comment Functionalities */}
                        <div className="taskComment__function">
                        {editStatus ?   <button onClick={()=>{
                            console.log(taskComment._id)
                                setEditStatus(false)
                                updateEditComment(taskComment._id)
                            }}>
                                Save
                            </button> :   <button onClick={()=>{
                                setEditStatus(true)
                            }}>
                                Edit
                            </button> }
                            <button onClick={()=>{
                                setCommentModal(true)
                            }}>
                                Delete
                            </button>
                                {/* modal start here  */}
<Modal
                            className='delete__modal'
                            isOpen={commentModal}
                            closeTimeoutMS={20}
                            shouldCloseOnOverlayClick={true}
                        >
                        <div style={{width:'100%'}}>
                            <h4>do you want to delete this comment?</h4>
                            <div className="row rowWidth">
                                <div className="col-md-6 col-sm-12 col-xs-12"> <button onClick={()=> setCommentModal(false)}>Cancel</button></div>
                                <div className="col-md-6 col-sm-12 col-xs-12"><button onClick={()=>{
                                deleteTaskComment(taskComment._id);
                            }}>Delete</button></div>

                            </div>
                        </div>
                          
                        </Modal>
{/* modal end here */}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TaskComment
