import axios from './axios'

export const getLocationsListByFactory = (setLocationList, store, factoryID) => {
    console.log('getLocationsListByFactory start', factoryID)
    if (factoryID !== undefined) {
        axios
            .get(`locations/${factoryID}`)
            .then(response => {
                console.log('response of get location',response)
                let lList = []
                console.log("location data",response.data.locations)
                response.data.locations.map(location => {
                    const singleLocation = {
                        value: location.locationID,
                        label: location.locationName
                    }

                    lList.push(singleLocation)
                })

                setLocationList(lList)
            }).catch(error => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
    }
}

export const getLocationsByFactory = (setLocations, factoryID, store, limit, page, setPreviousPageShow, setNextPageShow,setLoader) => {
    axios
            .get(`locations/${factoryID}?limit=${limit}&page=${page}`)
            .then(response => {
                setLoader(false)
                console.log('location list',response)
                setLocations(response.data.locations)
                if (response.data.next) setNextPageShow(true)
                if (response.data.previous) setPreviousPageShow(true)
            })
            .catch(error => {
                setLoader(false)
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Internal Server Error',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: 'Network Error',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    })
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: 'Something went wrong',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        })
                    }
                }
            })
}