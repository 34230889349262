import React, { useEffect, useState } from "react";
import "../Styles/AllMachine.css";
import DashboardHeader from "../Components/DashboardHeader";
import Sidebar from "../Components/Sidebar";
import { useStateValue } from "../Functionalities/StateProvider";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "../Functionalities/axios";
import { useHistory } from "react-router";
import ExportExcelFile from "../Components/ExportExcelFile";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { getMachinesByLocation } from "../Functionalities/getMachine";
import Modal from "react-modal";
import Loader from "react-loader-spinner";

function AllMachine() {
    const history = useHistory();
    const [state, dispatch] = useStateValue();
    const [machines, setMachines] = useState([]);

    // Getting previously selected Factory and Location for Data Persistent
    const selectedFactoryDetails = localStorage
        .getItem("selected_factory")
        .split(",");
    const selectedLocationDetails = localStorage
        .getItem("selected_location")
        .split(",");

    // Getting account of machines selected from machine list
    const [isChecked, setIsChecked] = useState([]);

    const [page, setPage] = useState(1); // For Pagination
    const [previousPageShow, setPreviousPageShow] = useState(false); // For showing Previous Page Button
    const [nextPageShow, setNextPageShow] = useState(false); // For showing Next Page Button
    const limit = 10; // For Pagination
    const [deleteMachine, setDeleteMachine] = useState(false);
    const [editBttuon, setEditButton] = useState(true);
    const [editMachine, setEditMachine] = useState(null);
    const [dateMachine, setDateMachine] = useState(null);
    const [searchMachine, setSearchMachine] = useState(null);
    const [search, setSearch] = useState(null);
  const [loader,setLoader] = useState(true)

    // Sorting type List
    const sortList = [
        {
            value: 1,
            label: "Location Name",
        },
        {
            value: 2,
            label: "Machine Name",
        },
    ];
    // Timeline List
    const dateMachineList = [
        {
            value: "All",
            label: "All",
        },
        {
            value: "today",
            label: "Today",
        },
        {
            value: "this-week",
            label: "This Week",
        },
        {
            value: "this-month",
            label: "This Month",
        },
        {
            value: "last-three-months",
            label: "Last 3 Months",
        },
        {
            value: "this-year",
            label: "This Year",
        },
    ];

    // Setting to default
    useEffect(() => {
        setPreviousPageShow(false);
        setNextPageShow(false);
    }, [page]);

    // Fetching Machines List
    useEffect(() => {
        getMachinesByLocation(
            setMachines,
            store,
            selectedLocationDetails[0],
            limit,
            page,
            setPreviousPageShow,
            setNextPageShow,
            setLoader
        );
    }, [page]);

    // search function
    const searchFunc = async () => {
        let haveDateTime = dateMachine;

        // if(dateMachine){
        //     console.log('if',dateMachine)
        //     haveDateTime = dateMachine.label
        // }
        if (dateMachine) {
            console.log("if", dateMachine);
            if (dateMachine.label === "All") {
                haveDateTime = null;
            } else {
                haveDateTime = dateMachine.value;
            }
        }
        console.log(
            "search",
            searchMachine,
            "haveDateTime",
            haveDateTime,
            "selectedLocationDetails[0]",
            selectedLocationDetails[0]
        );

        await axios
            .get(
                `machines/${selectedLocationDetails[0]}?search=${search}&date_filter=${haveDateTime}`
            )
            .then((response) => {
                console.log("data get all machine search", response.data);
                setMachines(response.data.machines);
                if (response.data.next) setNextPageShow(true);
                if (response.data.previous) setPreviousPageShow(true);
            })
            .catch((error) => {
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404 ||
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.response.data.error,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: "Internal Server Error",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    }
                } else if (error.request) {
                    store.addNotification({
                        title: "Error Occurred!",
                        message: "Network Error",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                            duration: 2000,
                            onScreen: true,
                        },
                    });
                } else {
                    if (error.message) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else if (error.data) {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: error.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    } else {
                        store.addNotification({
                            title: "Error Occurred!",
                            message: "Something went wrong",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: 2000,
                                onScreen: true,
                            },
                        });
                    }
                }
            });
    };
    const deleteAllMachineArray = () => {
        console.log(isChecked, "isChecked");
        console.log(machines, "machines");
        machines.map((item, key) => {
            return isChecked.map((checkk, checkKey) => {
                return checkKey == key && checkk == true
                    ? deleteMachines(item.machineID)
                    : null;
            });
        });
        getAllMachine();
    };
    const deleteMachines = async (id) => {
        console.log("id", id);
        await axios
            .delete(`machines/${id}`)
            .then((response) => response.data)
            .then((data) => {
                console.log("data of delete machine", data);
                //    alert("Employee Deleted Successfully")
                //    history.goBack();
                getAllMachine();
            })
            .catch((error) => {
                alert(error);
            });
    };

    const getAllMachine = async () => {
        console.log("getAllMachine");
        setIsChecked([]);
        setDeleteMachine(false);
        await getMachinesByLocation(
            setMachines,
            store,
            selectedLocationDetails[0],
            limit,
            page,
            setPreviousPageShow,
            setNextPageShow,
            setLoader
        );
        history.push("/allMachine");
    };

    const reset = () => {
        setDateMachine(dateMachineList[0]);
        setSearch("");
        getAllMachine();
    };
    useEffect(() => {
        reset();
    }, []);
    console.log(machines);
    return (
        <div className="allMachine">
            {/* Header */}
            <DashboardHeader />
            <div className="allMachine__mainBody">
                {/* Notifications */}
                <ReactNotification />

                {/* Sidebar */}
                <Sidebar />
                <div className="allMachine__container">
                    {/* Navigation Breadcrumb */}
                    <div className="allMachine__containerPath">
                        {selectedFactoryDetails[1]} / {selectedLocationDetails[1]} / All
                        Machines
                    </div>

                    {/* Heading */}
                    <h1>All Machines</h1>

                    {/* Input Fields */}
                    <div className="allMachine__inputFields">
                        <div className="row rowWidth">
                            <div className="col-md-7 col-sm-12 col-xs-12 ">
                                <input
                                    className="widthOfSearch heightOfInputBox"
                                    size={100}
                                    type="text"
                                    placeholder="Search Machine"
                                    style={{ marginBottom: 10 }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <Select
                                    options={dateMachineList}
                                    placeholder="Date"
                                    className="allLocationDate__select marginTopCol"
                                    value={dateMachine}
                                    defaultValue={dateMachineList[0]}
                                    onChange={(e) => setDateMachine(e)}
                                />
                            </div>
                            <div className="col-md-1">
                                <button
                                    style={{ marginTop: 0, marginBottom: 10 }}
                                    className="heightOfInputBox"
                                    onClick={() => searchFunc()}
                                >
                                    Search
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                    style={{ marginTop: 0, marginBottom: 10 }}
                                    className="heightOfInputBox"
                                    onClick={() => reset()}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="allMachine__buttons">
                        <div className="allMachine__leftBtns">
                            {state.employee.isAdmin && <div className="row">
                                {editBttuon ? (
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        {" "}
                                        <button
                                            className="marginBottomButtonEdit"
                                            onClick={() => {
                                                console.log(editMachine, "editMachine");
                                                history.push({
                                                    pathname: "/updateMachine",
                                                    state: { data: editMachine },
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="allMachine__icon"
                                                icon={faEdit}
                                            />
                                            edit
                                        </button>
                                    </div>
                                ) : null}

                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <button
                                        onClick={() => {
                                            setDeleteMachine(true);
                                        }}
                                        className="alignDeleteButton"
                                    >
                                        <FontAwesomeIcon
                                            className="allMachine__icon"
                                            icon={faTrashAlt}
                                        />
                                        delete
                                    </button>
                                </div>
                                {/* modal start here  */}
                                <Modal
                                    className="delete__modal"
                                    isOpen={deleteMachine}
                                    closeTimeoutMS={20}
                                    shouldCloseOnOverlayClick={true}
                                >
                                    <div style={{ width: "100%" }}>
                                        <h4>do you want to delete this Machine?</h4>
                                        <div className="row rowWidth">
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                {" "}
                                                <button onClick={() => setDeleteMachine(false)}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <button
                                                    onClick={() => {
                                                        deleteAllMachineArray();
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                {/* modal end here */}
                            </div>}
                        </div>
                        <div className="allMachine__rightBtns">
                            <ExportExcelFile csvData={machines} fileName="Machines" />
                        </div>
                    </div>
                    {loader ? <div style={{width:'100%',padding:'14% 48%' ,justifyContent:'center',alignContent:'center'}}>
        <Loader
        type="Puff"
        color="#C71800"
        height={'100%'}
        width={"100%"}
        // timeout={3000} //3 secs
      /> 
      </div>
        :   
        <div className="allMachine__table">
                        {/* Table Heading */}
                        <div className="allMachine__tableHeading">
                            <span>
                                <input
                                    type="checkbox"
                                    className="heightOfInputBox"
                                    onChange={(e) => {
                                        setIsChecked((previousValue) => {
                                            previousValue = new Array(machines.length);
                                            previousValue.fill(e.target.checked);
                                            return previousValue;
                                        });
                                    }}
                                />
                            </span>
                            <span>Machine ID</span>
                            <span>Machine Name</span>
                            <span>Total Tickets</span>
                            <span>Open Tickets</span>
                            <span>Total Schedules</span>
                            <span>Open Schedules</span>
                        </div>

                        {/* Table Data */}
                        {machines?.map((machine, index) => (
                            <div
                                key={machine._id}
                                className={`allMachine__tableData ${isChecked[index] && "selected"
                                    }`}
                            >
                                <span>
                                    <input
                                        type="checkbox"
                                        className="heightOfInputBox"
                                        onChange={(e) => {
                                            setEditMachine(machine);
                                            setIsChecked((isChecked) => {
                                                let c = [...isChecked];
                                                c[index] = e.target.checked;
                                                return c;
                                            });
                                        }}
                                        checked={isChecked[index]}
                                    />
                                </span>
                                <span>{machine.machineID}</span>
                                <span
                                    onClick={(e) => {
                                        e.preventDefault();

                                        dispatch({
                                            type: "SINGLE_MACHINE",
                                            machine: machine,
                                        });
                                        history.push("/viewMachine");
                                    }}
                                >
                                    {machine.machineName}
                                </span>
                                <span>{machine.totalTicketsCount}</span>
                                <span>{machine.openTicketsCount}</span>
                                <span>{machine.totalSchedulesCount}</span>
                                <span>{machine.openSchedulesCount}</span>
                            </div>
                        ))}
                    </div>
                    }
                    {/* Table */}
                   

                    {/* Pagination Section */}
                    <div className="allMachine__pages">
                        {previousPageShow && (
                            <>
                                <button
                                    onClick={() => {
                                        if (page > 0) setPage(page - 1);
                                    }}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => {
                                        if (page > 0) setPage(page - 1);
                                    }}
                                >
                                    {page - 1}
                                </button>
                            </>
                        )}
                        <button className="select">{page}</button>
                        {nextPageShow && (
                            <>
                                <button
                                    onClick={() => {
                                        setPage(page + 1);
                                    }}
                                >
                                    {page + 1}
                                </button>
                                <button
                                    onClick={() => {
                                        setPage(page + 1);
                                    }}
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllMachine;